import Script from 'next/script'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const ReCaptchaScript: React.FC = () => (
  <Script
    strategy='lazyOnload'
    src={`https://www.google.com/recaptcha/api.js?render=${publicRuntimeConfig.recaptchaPublicKey}`}
  />
)

export default ReCaptchaScript
