import callContentful from './base.fetchers'
import { IPopUpProps } from '@/interfaces/popUp'
import { popUpDataModify } from '@/utils'

type BaseThumbnailQuery = (args: {
  where?: string
  isPreviewMode?: boolean
  locale?: string
  limit?: number
}) => string

const getBasePopUpQuery: BaseThumbnailQuery = ({
  where,
  isPreviewMode,
  locale,
  limit = 1,
}) => {
  return `
    popUp: popUpCollection(
      preview: ${isPreviewMode}
      locale: "${locale}"
      ${where ? `where: ${where}` : ''}
      limit: ${limit}
    ) {
      items {
        sys {
          id
          publishedAt
        }
        title
        form {
          ... on Form {
            hubspotFormId
            title
            subCopy
            eventLabel
            buttonText
            buttonEventLabel
            linkedInAdsId
            zapierHookId
            thankYouTitle
            thankYouMessage {
              json
            }
            formFieldList: formFieldListCollection(preview: ${isPreviewMode}, limit: 10) {
              items {
                ... on FormField {
                  name
                  label {
                    json
                  }
                  placeholder
                  isMandatory
                  type
                  isNonCompanyEmailDomainBlocked
                  width
                  mandatoryCheckErrorMessage
                  emailDomainBlockedCheckErrorMessage
                  customErrorMessage
                }
              }
            }
          }
        }
        buttonText
        image {
          url
          description
        }
        newUserTimer
        returnUserTimer
        directoryUrls
        exactUrls
      }
    }
  `
}

const getPopups = async(
  isPreviewMode: boolean,
  locale: string,
): Promise<IPopUpProps[]> => {
  const query = `{ ${getBasePopUpQuery({
    isPreviewMode: isPreviewMode,
    locale: locale,
    limit: 50
  })} }`

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.popUp?.items

  return popUpDataModify(data)
} 

export { getPopups }
