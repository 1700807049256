// TODO-Typo: NOT EDIT YET, no font system
import styled from 'styled-components'
import { Button } from '@/components'

export const SubscribeForm = styled.form`
  * {
    color: #fff;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-height: 140px;
  }
`

export const EmailInput = styled.input<{ isInvalid?: boolean }>`
  display: block;
  border: none;
  border-bottom: 1px solid;
  width: 100%;
  background: transparent;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  line-height: ${({ theme }) => theme.lineHeight[19]};

  ${(props) => !props.isInvalid ? `
    border-color: rgba(255, 255, 255, 0.5);
  ` : `
    border-color: #EA4747;
  `}

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: ${({ theme }) => theme.lineHeight[19]};
    color: #fff;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 3px;
`

export const Checkbox = styled.div`
  flex: 0 0 10px;
  max-width: 10px;
  height: 10px;
  border: 1px solid #fff;
  margin-right: 8px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: ${({ theme }) => theme.fontSizes[8]};
  }
`

export const CheckboxLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[11]};
  user-select: none;
  line-height: ${({ theme }) => theme.lineHeight[15]};
`

export const SubscribeButton = styled(Button)`
  margin-top: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 16px;
  }
`

export const ErrorFeedback = styled.div`
  margin-top: 2px;
  height: 20px;
  line-height: ${({ theme }) => theme.lineHeight[20]};
  color: #EA4747;
  font-size: ${({ theme }) => theme.fontSizes[10]};
`

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const successMessageIconSize = 37

export const SuccessMessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${successMessageIconSize}px;
  height: ${successMessageIconSize}px;
  border-radius: ${successMessageIconSize / 2}px;
  background: #21D378;
  margin-right: 12px;

  img {
    width: 17.65px;
    height: 12.54px;
  }
`
