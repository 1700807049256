import { CategorySlugKeys } from './category'
import { IRichTextDocument, ITopicTag } from './common'
import { ISEOFields } from './head'

export interface ArticleProps {
  sys: { id: string }
  title: string
  articleSlug?: string
  articleLang?: CategorySlugKeys
  topics?: ITopicTag[]
  tags?: ITopicTag[]
  date: string
  heroImage: {
    url: string
    description: string
  }
  thumbnailCopy?: string
  content?: IRichTextDocument
  seo?: ISEOFields
  relatedArticles?: ArticleProps[]
}

export interface IPmArticle {
  sys: { id: string, publishedAt?: string }
  title?: string
  description?: string
  articleType?: string
  slug?: string
  date?: string
  startDate?: string
  image?: {
    contentType?: string
    url: string
    description: string
  }
  seo?: {
    description?: string
  }
}

export enum PmArticleBooleanType {
  showOnPpInsights = 'showOnPpInsights',
  showOnPpResource = 'showOnPpResource',
}
