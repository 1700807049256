import styled from 'styled-components'
import PageContentContainer from '@/components/Container'
import { lightTheme } from '@/constants'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.sectionTheme?.background || lightTheme.background};
`

export const Separator = styled(PageContentContainer)`
  height: 3px;
  background-color: #CCD1D8;
`
