import styled from 'styled-components'

export const NotFoundMessage = styled.div<{ isCarrier?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes[15]};
  line-height: ${({ theme }) => theme.lineHeight[20]};
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ isCarrier }) => !isCarrier && 'min-height: 175px;'}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 50px;

  & > p {
    margin: 0;
  }

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brightBlue};

    :hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.blue};
    }
  
    :visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[24]};
    line-height: ${({ theme }) => theme.lineHeight[33]};
    text-align: center;
    padding: 50px 124px;
  }
`

export const HelpLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brightBlue};

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue};
  }

  :visited {
    color: ${({ theme }) => theme.colors.blue};
  }
`
