import { SecondaryTitleContainer } from './SecondaryTitle.styles'

const SecondaryTitle: React.FC = ({ children }) => {
  if (!children) {
    return null
  }

  return <SecondaryTitleContainer>{children}</SecondaryTitleContainer>
}

export default SecondaryTitle
