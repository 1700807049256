import styled from 'styled-components'

export const Layout = styled.div`
  width: 100%;
`

export const HeaderWrapper = styled.header<{ hideHeaderFooter: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1000;
`

export const MainWrapper = styled.main`
  overflow: hidden;
  position: relative;
`

export const FooterWrapper = styled.footer`
  overflow: hidden;
`
