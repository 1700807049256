// TODO: DO NOT EDIT YET, not sure which font system
import { ItemList } from './FooterItem/FooterItem.styles'
import { PanelWrapper } from './../../Accordion/Panel/Panel.styles'
import ContactComponent from './Contact'
import styled from 'styled-components'

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.darkBlue};
  padding-top: 30px;
  padding-bottom: 30px;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 600px) and (max-width: 1188px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`

export const FooterSection = styled.div`
  display: flex;
  padding-bottom: 33px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  ${PanelWrapper}:last-child ${ItemList} a:last-child{
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 42px;
    margin-bottom: 10px;
  }
`

export const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  opacity: .5;
  margin-bottom: 37px;

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    margin-bottom: 30px;
  }
`

export const FooterCopyRightWrapper = styled.div`
  opacity: 0.7;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 8px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: auto;
    gap: 8px 24px;
  }
`

export const FooterCopyRight = styled.span`
  flex-basis: 100%;
  margin: 4px 0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0;

    flex-basis: auto;
  }
`

export const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.orange}
  }
`

export const Contact = styled(ContactComponent)`
  margin-bottom: 8px;
  
  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    margin-bottom: 35px;
  }
`
