import {
  documentToReactComponents,
  Options as RenderDocumentOptions,
} from '@contentful/rich-text-react-renderer'

// Component
import HubspotForm from '@/components/Form/HubspotForm'
import {
  LeadFormWrapper,
  Title,
  ThankYouCard,
  ThankYouCardContent,
  ThankYouIcon,
  ThankYouTitle,
  ThankYouMessage,
  SubmitButton,
  SubCopy,
  HubspotFormWrapper,
} from './LeadForm.styles'
import { FormField } from './components'

// Type
import { IFormProps } from '@/interfaces/form'

// Hook
import useLeadForm from './useLeadForm.hook'

// Util
import { excludeEmptyItem } from '@/utils'
import { ReCaptchaScript } from '@/components'

const LeadForm: React.FC<IFormProps> = ({
  title,
  hubspotFormId,
  subCopy,
  eventLabel,
  formFieldList,
  buttonText,
  buttonEventLabel,
  zapierHookId = '',
  thankYouTitle,
  thankYouMessage,
  linkedInAdsId,
  isPopUp,
}) => {
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    isSubmitted,
    isSubmitting,
  } = useLeadForm({
    zapierHookId,
    eventLabel,
    linkedInAdsId,
  })

  const documentToReactComponentsOptions: RenderDocumentOptions = {
    renderText: (text) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: any) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
  }
  const formattedThankYouMessage = documentToReactComponents(
    thankYouMessage?.json,
    documentToReactComponentsOptions
  )

  if (hubspotFormId) {
    return (
      <HubspotFormWrapper>
        {!isPopUp && <div id='form' />}
        <HubspotForm formId={hubspotFormId} />
      </HubspotFormWrapper>
    )
  }

  return (
    <LeadFormWrapper onSubmit={handleSubmit} isSubmitted={isSubmitted}>
      {!isPopUp && <div id='form' />}
      <ReCaptchaScript />
      {!isSubmitted ? (
        <>
          {title && <Title>{title}</Title>}
          {subCopy && isPopUp && <SubCopy>{subCopy}</SubCopy>}
          {excludeEmptyItem(formFieldList)?.map(
            (field, index) => (
              <FormField
                key={index}
                index={index}
                register={register}
                formState={formState}
                watch={watch}
                setValue={setValue}
                {...field}
              />
            )
          )}
          <SubmitButton
            disabled={isSubmitting}
            type='submit'
            eventLabel={buttonEventLabel}
          >
            {isSubmitting ? 'Submitting...' : buttonText}
          </SubmitButton>
        </>
      ) : (
        <ThankYouCard>
          <ThankYouCardContent>
            <ThankYouIcon src='/images/form-check.svg' />
            <ThankYouTitle>{thankYouTitle}</ThankYouTitle>
            <ThankYouMessage>{formattedThankYouMessage}</ThankYouMessage>
          </ThankYouCardContent>
        </ThankYouCard>
      )}
    </LeadFormWrapper>
  )
}

export default LeadForm
