import { CustomEventName } from '@/constants'
import { resetGTMDataLayer, pushEvent } from '..'

export const GTMButtonClick = (buttonLabel: string): void => {
  resetGTMDataLayer()
  const details = { buttonLabel }
  pushEvent(CustomEventName.CustomButtonClick, details)
}

export interface GA4ButtonClickParams {
  buttonLabel: string
  buttonText?: string
  buttonDestination?: string
}
export const GA4ButtonClick = ({
  buttonLabel,
  buttonDestination,
  buttonText,
}: GA4ButtonClickParams): void => {
  resetGTMDataLayer()
  const details = {
    buttonLabel, buttonText, buttonDestination 
  }
  pushEvent(CustomEventName.ClickLeadsButton, details)
}
