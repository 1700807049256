import styled from 'styled-components'

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.a`
  margin-bottom: 16px;
  color: #FFF;
  line-height: ${({ theme }) => theme.lineHeight[19]};
  white-space: pre-wrap;

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
  
  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    opacity: .7;
  }
`
