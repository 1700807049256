import styled from 'styled-components'
import { defaultSectionTheme } from '../../../constants'
import { FilledButtonAsLink } from '../Button.styles'

const buttonSize = 37

export const ButtonLink = styled(FilledButtonAsLink)`
  padding: 0;
  min-width: unset;
  height: ${buttonSize}px !important;
  width: ${buttonSize}px;
  border: none;
  border-radius: 50% !important;
  background: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.background
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.background
        : defaultSectionTheme.primaryButton?.background};

  :not([disabled]):hover {
    background: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.hoverBackground
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.hoverBackground
        : defaultSectionTheme.primaryButton?.hoverBackground};
    color: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.hoverText
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.hoverText
        : defaultSectionTheme.primaryButton?.hoverText};
  }

  & > span {
    min-width: unset;
  }

  & > span > svg,
  & > svg {
    fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.text
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.text
        : defaultSectionTheme.primaryButton?.text};

    & path {
      fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.text
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.text
        : defaultSectionTheme.primaryButton?.text};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.hoverText
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.hoverText
        : defaultSectionTheme.primaryButton?.hoverText};
  
      & path {
        fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton?.hoverText
      : theme.sectionTheme
        ? theme.sectionTheme.primaryButton?.hoverText
        : defaultSectionTheme.primaryButton?.hoverText};
      }
    }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
