import { IBaseResourcePage, ResourcePropName } from '@/interfaces/resource.interfaces'
import { EventType } from '@/interfaces/eventsSection'

export const MAIN_RESOURCE_PAGE: IBaseResourcePage = {
  name: 'All',
  slug: 'resources',
  type: EventType.RESOURCES,
}

export const SUB_RESOURCE_PAGES: Array<IBaseResourcePage & { propName: ResourcePropName }> = [
  {
    name: 'Success Stories',
    slug: 'case-studies',
    propName: 'successStories',
    type: EventType.SUCCESS_STORIES,
  },
  {
    name: 'E-books & Guides',
    slug: 'ebooks',
    propName: 'ebooks',
    type: EventType.EBOOKS,
  },
  {
    name: 'Reports',
    slug: 'reports',
    propName: 'reports',
    type: EventType.REPORTS,
  },
]

export const ALL_RESOURCE_PAGES: Array<IBaseResourcePage & { propName?: ResourcePropName }> = [
  MAIN_RESOURCE_PAGE,
  ...SUB_RESOURCE_PAGES,
]

export const INITIAL_MAIN_RESOURCE_LIST_COUNT = 4
export const INITIAL_SUB_RESOURCE_LIST_COUNT = 10
export const LOAD_MORE_COUNT = 3
