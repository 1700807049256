import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import LeadForm from '@/components/Form/templates/LeadForm'
import {
  BannerContainer,
  BannerLeftWrapper,
  BannerRightWrapper,
} from '../LeadsPage.styles'
import LeadsTopWrapper from './LeadsTopWrapper'
import LeadsBottomWrapper from './LeadsBottomWrapper'

const MobileWrapper: React.FC<LeadsWrapperProps> = (props) => {
  const { leadsForm } = props

  return (
    <BannerContainer>
      <BannerLeftWrapper>
        <LeadsTopWrapper {...props} />

        {/* FORM MOBILE */}
        {
          leadsForm &&
          <BannerRightWrapper>
            <LeadForm {...leadsForm} />
          </BannerRightWrapper>
        }

        <LeadsBottomWrapper {...props} />
      </BannerLeftWrapper>
    </BannerContainer>
  )
}

export default MobileWrapper
