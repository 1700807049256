import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 116px;
  flex: 0 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 186px;
    border-radius: 8px 8px 0 0;
  }
`

export const TitleHolder = styled.div`
  margin: 0;
  height: auto;
`

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
  min-height: 1px;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 233px;
  max-width: 362px;
  background-color: ${({ theme }) => theme.colors.white};

  // Put breakpoint lg here if want tablet to have constant width
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 8px;
    overflow: hidden;
    width: 362px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 50px 30px 30px 30px;
  }
`
