import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

// TODO: move margin: 0 auto 32px auto; to the parent component (should not use margin in common component)
export const PrimaryTitleContainer = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})<{ noMarginBottom?: boolean }>`
  max-width: 315px;
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text };
  text-align: center;
  margin: 0 auto 32px auto;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    max-width: 900px;
  }

  ${({ noMarginBottom }) => noMarginBottom && `
    margin-bottom: 0 !important;
  `}
`

export const HiddenHeading = styled.h1.attrs({
  'aria-hidden': true,
})`
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
`
