import styled from 'styled-components'
import { defaultSectionTheme } from '@/constants'
import { VideoContainer } from '../../../RichText/RichText.styles'

const getHeight = (height: string | number) => {
  if (typeof height === 'number') {
    return `${height}px`
  }

  return height
}

export const CustomVideoContainer = styled(VideoContainer)<{
  customHeight?: Record<string, string | number>
}>`
  flex: 1;
  margin: 0 !important;
  background-color: ${({ theme }) =>
    theme.sectionTheme?.contrastThemeBackground ||
    defaultSectionTheme.contrastThemeBackground};

  video {
    width: 100%;
    height: ${({ customHeight }) => getHeight(customHeight?.mobile || '100%')};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    video {
      height: ${({ customHeight }) => getHeight(customHeight?.desktop || '100%')};
    }
  }
`
