import ContentTypeKeys from '@/interfaces/contentType'
import { IPress } from '@/interfaces/press'
import { capitalize } from '@/utils'
import { pressDataModify } from '@/utils/graphqlDataModify.utils'
import callContentful from './contentfulAPI/base.fetchers'

enum CarrierType {
  CarrierList = 'Carrier List',
  PressList = 'Press List',
}

export const getCarriers = (): any => {
  const fetchUrl = 'https://data.parcelperform.com/api/v1/master-data/carrier/public/'
  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return fetch(fetchUrl, fetchOptions).then(response => response ? response?.json() : null).then(res => res?.data)
}

export const getPressList = async ( isPreviewMode: boolean, locale: string ): Promise<IPress[]> => {

  const query = `
    {
      pressCollection(
        locale: "${locale}"
      ) {
        items
        {
          sys {
            id
          }
          articleTitle
          authorName
          publicationLogo {
            url
            description
          }
          publicationName
          link
          date
          region
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.pressCollection?.items

  return pressDataModify(data)
}

export const checkAndGetCarrierListingSectionData = async ( 
  sectionList: any[], 
  locale: string,
  isPreviewMode = false, 
): Promise<any[]> => {
  const carrierListingSection = sectionList?.filter((section: any) => section && capitalize(section?.__typename) === ContentTypeKeys.CarrierListing )[0]

  const isPressType = carrierListingSection?.type === CarrierType.PressList

  if (isPressType) {
    carrierListingSection.dataList = await getPressList(isPreviewMode, locale)
  } else if (carrierListingSection) {
    carrierListingSection.dataList = await getCarriers()
  }

  return sectionList
}
