import { useState } from 'react'
import getConfig from 'next/config'
import { getCookieBannerClosed, setCookieBannerClosed } from '@/utils'
import {
  CloseButton,
  CloseButtonContainer,
  CookieBannerContainer,
  CookieLink 
} from './CookieBanner.styles'

interface CookieBannerProps {
  currentDomain: string
}

const CookieBanner: React.FC<CookieBannerProps> = ({ currentDomain }) => {
  const { publicRuntimeConfig } = getConfig()
  const [isClosed, setCloseState] = useState(false)

  const closeCookieBanner = () => {
    setCookieBannerClosed()
    if (getCookieBannerClosed()) setCloseState(true)
  }
  
  if (isClosed) return <></>

  if (currentDomain === publicRuntimeConfig.webDomainDE) {
    return (
      <CookieBannerContainer id='pp-cookie-banner'>
        <div>
          Wir verwenden Cookies, um die Nutzererfahrung auf unserer Website zu verbessern und Ihnen relevante Werbeanzeigen zu präsentieren.
          <br/>
          Erfahren Sie mehr über unsere <CookieLink target='_blank' href="https://www.parcelperform.de/privacy">Cookie-Richtlinie</CookieLink>. Durch die Nutzung dieser Website erklären Sie sich mit der Verwendung von Cookies einverstanden.
        </div>
        <CloseButtonContainer id='pp-cookie-banner-close-btn' onClick={closeCookieBanner}>
          <CloseButton />
        </CloseButtonContainer>
      </CookieBannerContainer>
    )
  }

  return (
    <CookieBannerContainer id='pp-cookie-banner'>
      <div>
        We use cookies to improve your experience on our site and to show you relevant advertising.
        <br/>
        Learn more about our <CookieLink target='_blank' href="https://www.parcelperform.com/privacy">cookie policy</CookieLink>. By continuing to use this site, you consent to our use of cookies.
      </div>
      <CloseButtonContainer id='pp-cookie-banner-close-btn' onClick={closeCookieBanner}>
        <CloseButton />
      </CloseButtonContainer>
    </CookieBannerContainer>
  )
}

export default CookieBanner
