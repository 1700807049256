import { IGeo } from '@/interfaces/geo'

const isCali = (geoInfo: IGeo):boolean => {
  if (geoInfo?.region !== 'California') return false
  return true
}

const isEU = (geoInfo: IGeo):boolean => {
  if (geoInfo?.timezone?.split('/')[0] !== 'Europe') return false
  return true
}

const fetchGeoAPI = async (): Promise<any> => {
  let response = await fetch('https://ipinfo.io?token=0c5ab8e02ca2a1')
  response = await response.json()
  return response
}

export { 
  isCali,
  isEU,
  fetchGeoAPI,
}
