import React from 'react'
import Link from 'next/link'
import { TagArrow, TagWrapper } from './Tag.styles'

interface TagInterface {
  arrowDirection?: 'up' | 'down'
  onClick?: () => void
  href?: string
}

const Tag: React.FC<TagInterface> = ({
  arrowDirection,
  children,
  href,
  onClick
}) => {

  if (!href) {
    return (
      <TagWrapper onClick={onClick}>
        {children}
        {arrowDirection && <TagArrow direction={arrowDirection} />}
      </TagWrapper>
    )
  }

  return (
    <Link
      href={href}
      passHref
      onClick={onClick}
    >
      <TagWrapper>
        {children}
        {arrowDirection && <TagArrow direction={arrowDirection} />}
      </TagWrapper>
    </Link>
  )
}

export default Tag
