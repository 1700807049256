import React from 'react'
import {
  FormState,
  UseFormSetValue,
  UseFormWatch,
  UseFormRegister,
  RegisterOptions,
} from 'react-hook-form'
import { EMAIL_PATTERN } from '@pperform/pp-constants/validation'
import { Document } from '@contentful/rich-text-types'

// Component
import {
  Input,
  TextArea,
  Checkbox,
} from '../fieldComponents'
import { FormFieldWrapper } from './FormField.styles'

// Type
import {
  FieldType,
  InputWidth,
} from '@/interfaces/form'

// Constant
import {
  DEFAULT_REQUIRED_MESSAGE,
  DEFAULT_INVALID_EMAIL_MESSAGE,
  DEFAULT_EMAIL_DOMAIN_BLOCKED_MESSAGE,
} from '@/constants/form.constants'
import { checkIsFromEmailDomainBlocked } from '@/utils'

interface IFormFieldProps {
  index: number
  value?: string
  name?: string
  label?: {
    json: Document
  }
  placeholder?: string
  isMandatory?: boolean
  isNonCompanyEmailDomainBlocked?: boolean
  type?: FieldType
  width?: InputWidth
  mandatoryCheckErrorMessage?: string
  emailDomainBlockedCheckErrorMessage?: string
  customErrorMessage?: string
  formState: FormState<any>
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  register: UseFormRegister<any>
}

const FormField: React.FC<IFormFieldProps> = (props) => {
  const {
    index,
    value,
    name = '',
    label,
    placeholder,
    isMandatory,
    isNonCompanyEmailDomainBlocked,
    type,
    width,
    register,
    formState,
    watch,
    setValue,
    mandatoryCheckErrorMessage,
    emailDomainBlockedCheckErrorMessage,
    customErrorMessage,
  } = props
  const errorMessage = name && formState.errors[name]?.message
  
  const registerOptions: RegisterOptions<any> = {
    required: isMandatory && (mandatoryCheckErrorMessage || DEFAULT_REQUIRED_MESSAGE),
  }

  const registerField = () => register(name || `field=${index}`, registerOptions)

  const textInputProps = {
    value,
    name,
    label,
    placeholder,
    isMandatory,
    errorMessage,
  }

  const renderFormField = () => {
    switch (type) {
      case 'Email': {
        registerOptions.pattern = {
          value: EMAIL_PATTERN,
          message: customErrorMessage || DEFAULT_INVALID_EMAIL_MESSAGE,
        }

        registerOptions.validate = isNonCompanyEmailDomainBlocked ? ((value: string) =>  !checkIsFromEmailDomainBlocked(value) || emailDomainBlockedCheckErrorMessage || DEFAULT_EMAIL_DOMAIN_BLOCKED_MESSAGE) : undefined

        return <Input {...textInputProps} {...registerField()} />
      }

      case 'Input': {
        return <Input {...textInputProps} {...registerField()} />
      }

      case 'TextArea': {
        return <TextArea {...textInputProps} {...registerField()} />
      }

      case 'Checkbox': {
        const isChecked = !!watch(name)

        if (isMandatory) {
          registerOptions.validate = (value: any) => value === true || mandatoryCheckErrorMessage || DEFAULT_REQUIRED_MESSAGE
        }

        registerField()

        return (
          <Checkbox
            isChecked={isChecked}
            label={label}
            handleClick={() => setValue(name, !isChecked)}
            errorMessage={errorMessage as string}
          />
        )
      }

      default: {
        return null
      }
    }
  }

  return <FormFieldWrapper width={width || InputWidth.Full}>{renderFormField()}</FormFieldWrapper>
}

export default FormField
