import styled from 'styled-components'

const topicBottomGap = 8

export const TopicListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${topicBottomGap}px;
`

export const Topic = styled.div`
  padding: 2px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  margin-bottom: ${topicBottomGap}px;
  background: ${({ theme }) => theme.colors.hawkesBlue};
  color: ${({ theme }) => theme.colors.grayBlue};
  border-radius: 50px;
`
