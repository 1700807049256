import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import { Title, CustomRichTextContainer } from '../LeadsPage.styles'
import RichText from '@/components/RichText'

const LeadsTopWrapper: React.FC<LeadsWrapperProps> = (props) => {
  const {
    title, leadSubCopy, theme 
  } = props

  return (
    <>
      <Title $sectionTheme={theme}>{title}</Title>
      {
        leadSubCopy &&
          <CustomRichTextContainer className='custom__richtext'>
            <RichText richText={leadSubCopy} />
          </CustomRichTextContainer>
      }
    </>
  )
}

export default LeadsTopWrapper
