import {
  Title,
  IconsWrapper,
  Icon,
} from './SocialSharing.styles'

import FacebookIcon from '../../public/images/social-icons/facebook.svg'
import TwitterIcon from '../../public/images/social-icons/twitter.svg'
import LinkedinIcon from '../../public/images/social-icons/linkedin.svg'
import { socialSharingButtonPushEvent } from '@/utils/GTM/GTMSocialSharing'

interface ISocialSharingProps {
  title?: string
  url: string
  text?: string
}

const SocialSharing: React.FC<ISocialSharingProps> = ({
  title,
  url,
  text,
}) => {
  return (
    <>
      {title && <Title>{title}</Title>}
      <IconsWrapper>
        <Icon
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          onClick={() => socialSharingButtonPushEvent('facebook')}
        >
          <FacebookIcon />
        </Icon>
        <Icon
          href={`https://twitter.com/share?url=${url}${
            text ? `&text=${text}` : ''
          }`}
          onClick={() => socialSharingButtonPushEvent('twitter')}
        >
          <TwitterIcon />
        </Icon>
        <Icon
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
          onClick={() => socialSharingButtonPushEvent('linkedin')}
        >
          <LinkedinIcon />
        </Icon>
      </IconsWrapper>
    </>
  )
}

export default SocialSharing
