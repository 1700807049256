import Image from 'next/image'
import { CustomerCardInterface } from '@/interfaces/CustomerCard'
import { AssetRenderer } from '@/components'
import { useWindowSize } from '@/hooks'
import {
  CustomerCardWrapper,
  CustomerCardContent,
  Testimonial,
  CustomerLogo,
  Customer,
  CustomerName,
  CustomerTitle,
  CustomerImage,
  Quote,
  QuotationMark,
  ButtonLink,
  ImageWrapper,
  Circle,
} from './CustomerCard.styles'
import ArrowRight from '../../public/images/icons/arrow-right.svg'

const CustomerCard: React.FC<CustomerCardInterface> = (props) => {
  const {
    customerLogo,
    imageAlignment,
    image,
    testimonialQuote,
    customerImage,
    customerName,
    customerTitle,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
  } = props
  const { width } = useWindowSize()
  const isMobile = width <= 599
  // condition below is to .slice() for older data
  const quoteEllipsis = testimonialQuote && testimonialQuote.length > 290 ? '...' : ''

  return (
    <CustomerCardWrapper alignment={imageAlignment}>
      {image && (
        <ImageWrapper>
          <AssetRenderer
            imageProps={{
              width: 486,
              height: 486,
              placeholder: 'blur',
              blurDataURL:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcVQ8AAdkBKwhkHIIAAAAASUVORK5CYII=',
              style: {
                objectFit: 'cover',
              },
            }}
            asset={image}
          />
          <Circle alignment={imageAlignment}/>
        </ImageWrapper>
      )}
      <CustomerCardContent>
        <Testimonial>
          <Customer>
            {customerImage && (
              <CustomerImage>
                <Image
                  src={customerImage.url}
                  alt={customerImage.description || 'Customer image'}
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </CustomerImage>
            )}
            <div style={{ width: '83%' }}>
              <CustomerName>{customerName}</CustomerName>
              <CustomerTitle>{customerTitle}</CustomerTitle>
            </div>
          </Customer>
          {testimonialQuote && (
            <Quote>
              <QuotationMark>&quot;</QuotationMark>
              {testimonialQuote.slice(0, 283) + quoteEllipsis}
              <QuotationMark>&quot;</QuotationMark>
            </Quote>
          )}
          {customerLogo && (
            <CustomerLogo
              src={customerLogo.url}
              alt={customerLogo.description}
            />
          )}
        </Testimonial>
        {buttonLink && (
          <ButtonLink
            variant={isMobile ? 'link' : 'primary'}
            href={buttonLink}
            eventLabel={buttonEventLabel}
            openInNewTab={openInNewTab}
            icon={isMobile && ArrowRight}
          >
            {buttonText || 'Read Success Story'}
          </ButtonLink>
        )}
      </CustomerCardContent>
    </CustomerCardWrapper>
  )
}

export default CustomerCard
