import { Document } from '@contentful/rich-text-types'
import { IQuote } from '@/interfaces/quote'
import { ITable } from './table'

export enum ImageContentType {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  WEBP = 'image/webp',
  GIF = 'image/gif',
}

export enum VideoContentType {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  OGG = 'video/ogg',
  QUICKTIME = 'video/quicktime',
}

export enum LottieContentType {
  DotLottie = 'application/zip',
}

export interface Asset {
  sys?: { id: string }
  url: string
  title?: string
  description?: string
  contentType?: ImageContentType | VideoContentType | LottieContentType
}

interface AssetLink {
  sys: { id: string }
  contentType: string
  url: string
  height: number
  width: number
  description: string
}

export interface IRichTextDocument {
  json: Document
  links: {
    assets: {
      block: AssetLink[]
    }
    entries: {
      block: Array<RTQuote | ITable>
    }
  }
}

export interface RTQuote extends IQuote {
  sys: { id: string }
  __typename?: string
}

export interface RTTable extends ITable {
  sys: { id: string }
  __typename?: string
}

export interface ITopicTag {
  sys: { id: string }
  title: string
  pageSlug: string
}
