import styled from 'styled-components'
import { ThumbnailsWrapper } from '../Category/Category.styles'
import {
  Title, 
  CreatedDate,
  ThumbnailContentContainer,
  SubCopy,
  ThumbnailTopicName,
} from '../Thumbnail/Thumbnail.styles'
import { Wrapper } from '../WrapperContentItems/components/SectionSeparator/SectionSeparator.style'
import { ContentItemsContainer } from '../WrapperContentItems/components/WrapperContentItemsContainer/WrapperContentItemsContainer.styled'

export const EventsSectionWrapper = styled.div`
  ${ThumbnailContentContainer} {
    height: 264px;
  }

  ${ThumbnailsWrapper} {
    padding-top: 30px;
    padding-bottom: 0;
  }

  ${Title} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3 !important;
    overflow: hidden;
    margin-bottom: auto;
  }

  ${SubCopy} {
    margin-bottom: 10px;
  }

  ${CreatedDate} {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  ${Wrapper} {
    padding-bottom: 50px;
  }

  & + & ${ContentItemsContainer} {
    padding-top: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${ThumbnailContentContainer} {
      height: 330px;
    }

    ${ThumbnailsWrapper} {
      padding-top: 60px;
    }

    ${ThumbnailTopicName} {
      margin: 0 10px 14px 0;
    }

    ${SubCopy} {
      margin-bottom: 14px;
    }

    ${Wrapper} {
      padding-bottom: 100px;
    }
  }
`
