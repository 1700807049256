import styled from 'styled-components'

import { InputWidth } from '@/interfaces/form'

export const FormFieldWrapper = styled.div.withConfig({
  shouldForwardProp: (propName, defaultValidatorFn) => propName.toString() !== 'width' && defaultValidatorFn(propName),
})<{ width: InputWidth }>`
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 100%;
  max-width: 100%;

  ${({ theme, width }) => {
    const flexBasis = width === InputWidth.Full ? '100%' : '50%'

    return `
      ${theme.mediaBreakpointUp.md} {
        flex-basis: ${flexBasis};
        max-width: ${flexBasis};
      }
    `
  }}
`
