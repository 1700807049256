import  { RefObject, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useClickOutsideElement(ref: RefObject<HTMLDivElement>, callback: any) {

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useClickOutsideMultiElement(refs: RefObject<HTMLDivElement>[], callback: any) {

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      const isOutside = refs.every((ref) => ref.current && !ref.current.contains(e.target as HTMLElement))
      if (isOutside)
        callback()
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs, callback])
}

export  { useClickOutsideElement, useClickOutsideMultiElement }
