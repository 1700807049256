import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { Container, Typography } from '@/components'
import Button from '../Button'
import { styledText } from '../Typography/Typography.styles'

export const ErrorPageWrapper = styled.div`
  padding: 50px 0;
  background: ${({ theme }) => theme.colors.surface01};
  height: calc(100vh - 70px);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: calc(100vh - 91px);
  }
`

export const ErrorPageName = styled.div`
  width: 315px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[65]};
  line-height: ${({ theme }) => theme.lineHeight[75]};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 505px;
    font-size: ${({ theme }) => theme.fontSizes[80]};
    line-height: ${({ theme }) => theme.lineHeight[85]};
  }
`

export const ErrorPageTitle = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})`
  width: 315px;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 20px;
  white-space: pre-line;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    width: 505px;
  }
`

export const ErrorPageDescription = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  width: 315px;
  color: ${({ theme }) => theme.colors.darkGray};
  white-space: pre-line;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 505px;
  }
`

export const ErrorPageSubPoint = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[15]};
  line-height: ${({ theme }) => theme.lineHeight[25]};
  color: ${({ theme }) => theme.colors.darkGray};
  margin-top: 10px;
  margin-bottom: 15px;
  transform: translateX(27.5px); //20px from left margin + 7.5px from border width of arrow
  width: calc(100% - 27.5px);

  :before {
    content: '';
    border-style: solid;
    border-width: 7.5px 0 7.5px 7.5px;
    border-color: transparent transparent transparent ${({ theme }) => theme.colors.darkBlue};
    position: absolute;
    left: -27.5px; //20px from left margin + 7.5px from border width of arrow
    transform: translateY(25%); 
    display: inline-block;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[18]};
    line-height: ${({ theme }) => theme.lineHeight[35]};

    :before {
      transform: translateY(60%);
    }
  }
`

export const ErrorPageButton = styled(Button)`
  margin-top: 20px;
`

export const ErrorPageContainer = styled(Container)`
  position: relative;
  z-index: 1;
`
