import {
  useEffect,
  useState,
} from 'react'

/*
 * ----- USAGE - useTitleHeight -----
 * S1. Create a list of refs to pass in as arguments for useTitleHeight
 * S2. Add the "ref" (singular) to each targeted item in the list of items
 * S3. For inner component, receive "ref" and "height" to add to the respective Title and TitleHolder
 *
 * @Example of S1
 * const refs = useRef<RefObject<HTMLElement>[]>(Array.from({ length: itemList.length }, () => React.createRef()))
 * const height = useTitleHeight(refs.current)
 *
 * @Example of S2
 * <Item key="item-01" ref={ref.current[index]} height={height} />
 *
 * @Example of S3
 * <ItemTitleHolder height={height}>
 *   <ItemTitle ref={ref} />
 * </ItemTitleHolder>
*/

/**
 * Calculate the titles' max-height from a list of items inside a particular wrapper.
 * @see Refer to the file containing this custom hook for information on usage.
 * @param {RefObject<T extends HTMLElement>[]} titleRefs ref that contains a list of refs
 * @returns {number} Returns the value of height for a title
 */

function useTitleHeight<T extends HTMLElement>(titleRefs: React.RefObject<T>[]): number {
  const [height, setHeight] = useState(1)

  useEffect(() => {
    const getTitleMaxHeight = () => {
      const maxHeight = Math.max(...titleRefs.map(titleRef => titleRef?.current?.clientHeight || 0), 0)
      if (maxHeight >= height) {
        setHeight(maxHeight)
      }
    }
    // call first time to change initial MIN_HEIGHT if there is a 2-line title
    getTitleMaxHeight()
    window.addEventListener('load', getTitleMaxHeight) //to patch the error when getTitleMaxHeight not updating immediately
    window.addEventListener('resize', getTitleMaxHeight)
    return () => window.removeEventListener('resize', getTitleMaxHeight)
  }, [])

  return height
}

export default useTitleHeight
