import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { modifyUrlQuery, renderHTML } from '@/utils'
import { Asset } from '@/interfaces/common'
import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import {
  ProductImage,
  SliderWrapper,
  Arrow,
  NextArrowContainer,
  PrevArrowContainer,
  HighlightedSubCopy,
  CustomerLogosWrapper,
  CustomerLogo,
  TinyCopy,
} from '../LeadsPage.styles'

const LeadsBottomWrapper: React.FC<LeadsWrapperProps> = (props) => {
  const {
    highlightedSubCopy,
    productImages,
    customerLogos,
    tinyCopy,
  } = props

  const productImagesList = productImages?.filter(Boolean)
  const customerLogosList = customerLogos?.filter(Boolean)

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true, //default speed is 3000
    nextArrow: (
      <NextArrowContainer>
        <Arrow />
      </NextArrowContainer>
    ),
    prevArrow: (
      <PrevArrowContainer>
        <Arrow />
      </PrevArrowContainer>
    ),
  }

  return (
    <>
      {/* PRODUCT */}
      {
        productImagesList && productImagesList.length !== 0 &&
        <SliderWrapper productImagesLength={productImagesList.length}>
          <Slider {...settings}>
            {
              productImagesList.map((product: Asset) => {
                const src = productImagesList.length === 1
                  ? modifyUrlQuery(
                    product.url || '',
                    (prevQuery) => ({ ...prevQuery, w: 505 }),
                  )
                  : product.url

                return (
                  <ProductImage
                    key={product.sys?.id}
                    src={src}
                    alt={product.description}
                  />
                )
              })
            }
          </Slider>
        </SliderWrapper>
      }

      {highlightedSubCopy && <HighlightedSubCopy>{highlightedSubCopy}</HighlightedSubCopy>}

      {/* CUSTOMER LOGOS */}
      {
        customerLogosList?.length ? (
          <CustomerLogosWrapper>
            {
              customerLogosList?.map((customerLogo: Asset) => (
                <CustomerLogo
                  key={customerLogo.sys?.id}
                  src={customerLogo.url}
                  alt={customerLogo.description}
                />
              ))
            }
          </CustomerLogosWrapper>
        ) : null
      }

      {tinyCopy && <TinyCopy>{renderHTML(tinyCopy + '')}</TinyCopy>}
    </>
  )
}

export default LeadsBottomWrapper
