// TODO-Typo: DO NOT EDIT ErrorMessage, belongs to Form font system (not mapped yet)
// TODO-Typo: DO NOT EDIT ThankYouText, not belong in any font system
import styled, { css, keyframes } from 'styled-components'
import { TextStyles } from '@/constants'
import { styledText } from '@/components/Typography/Typography.styles'

const disappearingAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0px;
    visibility: hidden;
  }
`

export const Wrapper = styled.div<{
  hasError: boolean
  shouldApplyAnimation: boolean
}>`
  background: #CFD9E4;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  height: ${({ hasError }) => hasError ? '75px' : '58px'};
  display: flex;
  overflow: hidden;

  ${({ shouldApplyAnimation }) => shouldApplyAnimation && css`
    animation: ${disappearingAnimation} 0.3s ease-out;
    animation-fill-mode: forwards;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    align-items: center;
    height: 60px;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`

export const InnerContent = styled.div<{ isSubmitted: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 9px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ${({ isSubmitted }) => isSubmitted && `
    justify-content: center;
  `}
`

export const Title = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    margin-right: 24px;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    width: 472px;
    text-align: right;
  }
`

export const FormWrapper = styled.form<{ hasError: boolean }>`
  height: 40px;
  display: flex;
  position: relative;

  ${({ hasError }) => hasError && `
    flex-wrap: wrap;
  `}
`

export const ErrorMessage = styled.div`
  display: none;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeight[16]};

  ${({ children }) => children && `
    display: block;
    flex-basis: 100%;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    position: absolute;
    left: calc(100% + 14px);
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    height: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
  }
`

export const EmailInput = styled.input<{
  hasError: boolean
  isForMobile?: boolean
}>`
  ${styledText[TextStyles['Large Paragraph Text']]}
  width: 228px;
  height: 100%;
  border: 1px solid #9c9c9c;
  border-right: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 19px;
  padding-right: 19px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #AFB7C0;
  }

  ${({ theme, hasError }) => hasError && `
    border-color: ${theme.colors.orange};
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 276px;
  }

  ${({ isForMobile, theme }) => isForMobile ? `
    display: inline-block;

    ${theme.mediaBreakpointUp.md} {
      display: none;
    }
  ` : `
    display: none;

    ${theme.mediaBreakpointUp.md} {
      display: inline-block;
    }
  `}
`

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  height: 100%;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.orange};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: none;
  cursor: pointer;

  svg {
    color: white;
    font-size: ${({ theme }) => theme.fontSizes[24]};
  }

  :hover {
    background: ${({ theme }) => theme.colors.darkOrange};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.lightGray3};
    cursor: not-allowed;
  }
`

export const ThankYouCard = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`

export const CheckIcon = styled.img`
  flex: 0 0 28px;
  height: 28px;
`

export const ThankYouText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[22]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 14px;
  white-space: nowrap;
`

export const CloseButton = styled.img<{ hasError: boolean }>`
  cursor: pointer;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  ${({ hasError }) => hasError && `
    top: calc(50% + 8.5px);
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    top: 50%;
  }
`
