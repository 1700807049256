import { ReactNode } from "react";
import { useTheme } from "styled-components";

// Constant
import { lightTheme } from "@/constants";

// Util
import { renderHTML } from "@/utils";

const useHighlightString = (
  string: string | undefined,
  element?: string
): ReactNode => {
  const { sectionTheme, pageTheme } = useTheme();

  if (!string) {
    return string;
  }

  const highlightedColor: string =
    pageTheme?.main ||
    sectionTheme?.highlightedText ||
    lightTheme.highlightedText;

  const newString = string
    ?.replace(/{{/g, `<span style='color: ${highlightedColor}'>`)
    ?.replace(/}}/g, "</span>");

  return renderHTML(newString || "", element);
};

export default useHighlightString;
