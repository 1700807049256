import * as Sentry from '@sentry/nextjs'

type SendSentryErrorArg = (error: any, extraData?: Record<string, any>) => void

export const sendSentryError: SendSentryErrorArg = (error, extraData) => {
  if (Sentry) {
    Sentry.withScope((scope) => {
      if (extraData && typeof extraData === 'object')
        Object.keys(extraData).forEach((key) =>
          scope.setExtra(key, JSON.stringify(extraData[key]))
        )
      Sentry.captureException(error)
    })
  }
}
