import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import styled, { css } from 'styled-components'
import {
  RichTextContainer,
  Image,
  ImageDesc,
  ImageContainer
} from '@/components/RichText/RichText.styles'
import { styledText } from '../Typography/Typography.styles'

export const SocialSharingWrapper = styled.div`
  margin-top: 6px;
`

export const TopSocialSharingWrapper = styled(SocialSharingWrapper)`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 168px;
`

export const BelowSocialSharingWrapper = styled(SocialSharingWrapper)`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 60px;
  }
`

export const Wrapper = styled.div`
  padding: 50px 0;
  background: ${({ theme }) => theme.colors.surface01};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 60px 0;
  }
`

export const TagListAndSocialSharingWrapper = styled.div<{ hasTagList?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  > div:first-child {
    flex-basis: 100%;
  }

  ${({ hasTagList }) => hasTagList && css`
    ${SocialSharingWrapper} {
      margin-top: 14px;
    }
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: unset;

    > div:first-child {
      flex-basis: unset;
    }

    ${({ hasTagList }) => hasTagList && css`
      ${SocialSharingWrapper} {
        margin-top: inherit;
        bottom: 8px;
      }
    `}
  }
`

export const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ItemListWithSpacing = styled(ItemList)`
  margin-top: 14px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 7px;
  }
`

export const TopicName = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  height: 28px;
  padding: 4px 12px;
  margin: 0 10px 10px 0;
  background: ${({ theme })=> theme.colors.hawkesBlue};
  border-radius: 50px;
  color: ${({ theme })=> theme.colors.grayBlue};

  &:hover {
    background: ${({ theme }) => theme.colors.steelBlue};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Button']]}
    height: 29px;
    padding: 2px 10px;
    margin: 0 12px 12px 0;
  }
`

export const Title = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})`
  margin: 6px 0 14px;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 8px 0 16px;
  }
`

export const CreatedDate = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})<{ hasTagList?: boolean }>`
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: 18px;
    line-height: 24px;

    ${props => !props.hasTagList && css`
      position: relative;
      top: 100%;
      transform: translateY(-100%);
    `}
  }
`

export const TagTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[22]};
  margin-bottom: 14px;
  color: ${({ theme }) => theme.colors.darkGray};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[18]};
    line-height: ${({ theme }) => theme.lineHeight[32]};
    margin-bottom: 16px;
  }
`

export const Tag = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin: 0 12px 8px 0;
  color: ${({ theme }) => theme.colors.chetwodeBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 8px 8px 0;
  }
`

export const HeroImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-top: 40px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 10px;
    margin-top: 52px;
    margin-bottom: 40px;
  }
`

const defaultUlIndent = 28

export const ArticleContent = styled(RichTextContainer)`
  margin-bottom: 40px;
  margin-top: 40px;
  max-width: 750px;
  width: 100%;

  &.custom__richtext {
    p, a, li {
      ${styledText[TextStyles['Large Paragraph Text']]}
    }
  }

  &.custom__richtext pre {
    height: 24px;
  }

  &.custom__richtext h2 a,
  &.custom__richtext h3 a,
  &.custom__richtext h4 a {
    font-weight: inherit;
    font-size: inherit;
  }

  &.custom__richtext ${Image} {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  &.custom__richtext ${ImageDesc} {
    display: block;
    margin-top: 10px !important;
    ${styledText[TextStyles['Caption Text']]}

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-top: 16px !important;
    }
  }

  &.custom__richtext ${ImageContainer} {
    margin: 36px 0;
    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin: 40px 0;
    }
  }

  &.custom__richtext ul {
    margin-left: 6px;
    & > li {
      margin-top: 25px;
      & ::marker {
        font-size: ${({ theme }) => theme.fontSizes[21]}; // TODO: Change this to ajust size of marker
      }
    }
  }


  // Update OL markers
  &.custom__richtext ol {
    padding-left: 6px;
    p {
      padding-left: ${defaultUlIndent}px !important;
    }
    > li > :first-child {
      display: inline-flex;
    }

    & > li {
      margin-top: 25px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 80px;
    margin: auto;
    margin-top: 52px;
    padding-left: 0;
    padding-right: 0;

    &.custom__richtext ol, ul {
      & > li {
        margin-top: ${defaultUlIndent}px;
      }
    }

    &.custom__richtext ul {
      margin-left: ${defaultUlIndent / 2}px;
    }

    &.custom__richtext ol {
      padding-left: ${defaultUlIndent / 2}px;
    }
  }

`
