import { createGlobalStyle } from 'styled-components'
import { Nunito_Sans, Montserrat } from 'next/font/google'

// Global CSS
import 'normalize.css/normalize.css'

export const nunitoSans = Nunito_Sans({
  weight: ['400', '600', '700', '900'],
  subsets: ['latin'],
  adjustFontFallback: false,
  fallback: ['sans-serif'],
  preload: true,
})

export const montserrat = Montserrat({
  weight: ['600', '700'],
  subsets: ['latin'],
  adjustFontFallback: false,
  fallback: ['sans-serif'],
  preload: true,
})

const GlobalStyles = createGlobalStyle<{ liveChatMarginBottom: number }>`
  body {
    * {
      box-sizing: border-box;
      font-family: ${nunitoSans.style.fontFamily};
    }

    a {
      text-decoration: none;
    }

    // Override Styling of Hubspot
    #hubspot-messages-iframe-container {
      bottom: ${props => props?.liveChatMarginBottom}px !important;
      transition: bottom .4s ease-out;
    }

    // Override Styling of Recaptcha
    .grecaptcha-badge {
      z-index: 1;
      bottom: ${props => props?.liveChatMarginBottom}px !important; 
      display: none !important;
    }
  }
`

export default GlobalStyles
