import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'

export const CarrierLogo = styled.img`
  width: 65px;
  height: 65px;
  object-fit: contain;
  border-radius: 8px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 128px;
    height: 128px;
  }
`

export const CarrierName = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  height: 30px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
  display: flex;
  align-items: flex-end;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 40px;
  }
`

export const CarrierInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CarrierTrackingLink = styled.a`
  position: relative;
  width: 65px;
  height: 65px;
  :after {
    content: 'Track now';
    position: absolute;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    font-size: ${({ theme }) => theme.fontSizes[10]};
    line-height: ${({ theme }) => theme.lineHeight[20]};
    color: ${({ theme }) => theme.colors.orange};
    opacity: 0;
    transition: all .3s;
  }

  :hover :after {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 128px;
    height: 128px;

    :hover :after {
      font-size: ${({ theme }) => theme.fontSizes[16]};
      line-height: ${({ theme }) => theme.lineHeight[22]};
      cursor: pointer;
    }
  }
`
