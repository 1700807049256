import { tagGenerator } from '@/utils'
import { CategoryTypeKeys } from '@/interfaces/category'
import {
  TitleContainer,
  CategoryTitle,
  TagTitle,
} from './Title.styles'

const Title: React.FC<{ type: CategoryTypeKeys, children: string }> = (props) => {
  const { children, type } = props

  if (!children) return null

  if (type === CategoryTypeKeys.Topic) {
    return <CategoryTitle>{children}</CategoryTitle>
  }

  return (
    <TitleContainer>Posts about <TagTitle>{tagGenerator(children)}</TagTitle></TitleContainer>
  )
}

export default Title
