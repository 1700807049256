import { useRef } from 'react'
import { DotLottiePlayer, DotLottieCommonPlayer } from '@dotlottie/react-player'

interface DotLottieProps {
  src: string
  className?: string
}

const DotLottie: React.FC<DotLottieProps> = ({ src, className }) => {
  const ref = useRef<DotLottieCommonPlayer>(null)

  return (
    <DotLottiePlayer
      className={className}
      ref={ref}
      src={src}
      autoplay
      loop
    >
    </DotLottiePlayer>
  )
}

export default DotLottie
