import styled from 'styled-components'
import { TextStyles, theme } from '@/constants'
import { GridBackgroundColorKeys } from '@/interfaces/grid'
import Button from '@/components/Button'
import Typography from '@/components/Typography'

const GridColor: any = {
  firstSquare: {
    [GridBackgroundColorKeys.Orange]: { text: theme.colors.white, background: theme.colors.orange },
    [GridBackgroundColorKeys.DarkBlue]: { text: theme.colors.white, background: theme.colors.darkBlue },
  }, 
  others: {
    [GridBackgroundColorKeys.White]: { text: theme.colors.darkBlue, background: theme.colors.white },
    [GridBackgroundColorKeys.DarkBlue]: { text: theme.colors.white, background: theme.colors.darkBlue },
  }, 
}

export const GridTitle = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 2'],
})`
  max-width: 310px;
  margin-bottom: 10px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 20px;
  }
`

export const GridDescription = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Large Paragraph Text'],
})`
  text-align: center;
  min-height: 80px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 310px;
  }
`

export const GridImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center center;
`

export const GridButton = styled(Button)`
  margin-top: 30px;
  line-height: ${({ theme }) => theme.lineHeight[24]};
`

export const GridItemWrapper = styled.div<{ backgroundColor?: GridBackgroundColorKeys, isImageBlock?: boolean }>`
  color: ${({ backgroundColor }) => backgroundColor && GridColor.others[backgroundColor]?.text ||
      GridColor.others[GridBackgroundColorKeys.White].text};
  background: ${({ backgroundColor }) => backgroundColor && GridColor.others[backgroundColor]?.background ||
    GridColor.others[GridBackgroundColorKeys.White].background};
  width: 315px;
  height: 315px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${(props) => props.isImageBlock ? 0  : '67px 25px 0'};
  word-break: break-word;

  :first-child {
    background: ${({ backgroundColor }) => backgroundColor && GridColor.firstSquare[backgroundColor]?.background ||
      GridColor.firstSquare[GridBackgroundColorKeys.Orange].background};
    color: ${({ backgroundColor }) => backgroundColor && GridColor.firstSquare[backgroundColor]?.text ||
      GridColor.firstSquare[GridBackgroundColorKeys.Orange].text};
    ${GridTitle} {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: ${({ theme }) => theme.fontSizes[45]};
      line-height: ${({ theme }) => theme.lineHeight[49]};
      margin-bottom: unset;
      text-align: left;

      ${({ theme }) => theme.mediaBreakpointUp.md} {
        line-height: ${({ theme }) => theme.lineHeight[40]};
      }
    }

    ${GridDescription}, ${GridButton} {
      display: none;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 382.67px;
    height: 382.67px;
    padding: ${(props) => props.isImageBlock ? 0  : '100px 50px 0'};
  }
`
