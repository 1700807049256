import { Fragment } from 'react'
import {
  BLOCKS,
  MARKS,
  INLINES
} from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options as RenderDocumentOptions,
} from '@contentful/rich-text-react-renderer'
import {
  Button,
  Anchor,
  Video,
  Typography,
} from '@/components'
import { ImageContentType, IRichTextDocument } from '@/interfaces/common'
import {
  BoldText,
  Image,
  ImageDesc,
  ImageContainer,
  VideoContainer
} from './RichText.styles'
import { useEffect, useState } from 'react'
import { RichTextEntries } from '@/interfaces/contentType'
import { RTEmbeddedEntriesMapping, TextStyles } from '@/constants'
import {
  capitalize,
  isImage,
  isVideo,
} from '@/utils'

const YOUTUBE_DOMAIN = 'youtube.com'

const RichText: React.FC<{ richText: IRichTextDocument }> = (props) => {
  const { richText } = props

  const documentToReactComponentsOptions: RenderDocumentOptions = {
    renderText: (text) => {
      if (text[text.length-1] === '\x0a' && text !== '\n') {
        return <>
          <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
          <pre />
        </>
      }

      if (text[text.length-1] === '\x0a' && text === '\n') {
        return <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
      }

      if (text === '\n') {
        return <pre />
      }

      return <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
    },
    renderMark: {
      [MARKS.BOLD]: (text: any) => <BoldText>{text}</BoldText>
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (_, children) => (
        <Typography variant={TextStyles['Heading 2']}>{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <Typography variant={TextStyles['Heading 3']}>{children}</Typography>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <Typography variant={TextStyles['Heading 4']}>{children}</Typography>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        if (data.uri.indexOf(YOUTUBE_DOMAIN) !== -1) {
          // rel: related videos are videos on the channel
          // modestbranding: hide Youtube's logo

          // Get the youtube id from URL
          // eslint-disable-next-line no-useless-escape
          const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
          const embedId = data.uri.match(regex)[1]

          return (<VideoContainer>
            <iframe
              width='500'
              height='294'
              src={`https://www.youtube.com/embed/${embedId}?&rel=0&modestbranding=1`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </VideoContainer>
          )
        }
        return (
          <Button
            href={data.uri}
            variant='hyperlink'
            openInNewTab
          >
            {children}
          </Button>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetMap = new Map()

        if (richText) {
          for (const asset of richText.links.assets.block) {
            if (asset?.sys) {
              assetMap.set(asset.sys.id, asset)
            }
          }
        }

        const asset = assetMap.get(node.data.target.sys.id)

        switch (true) {
          case !asset || (!isImage(asset) && !isVideo(asset)):
            return

          case isVideo(asset):
            return <Video asset={asset} />

          case [
            ImageContentType.PNG,
            ImageContentType.JPG,
            ImageContentType.JPEG,
            ImageContentType.SVG,
            ImageContentType.WEBP,
            ImageContentType.GIF,
          ].includes(asset.contentType): {
            // get string contains inside the first href='' or href=""
            const regex = /href="(.*?)"|href='(.*?)'/g
            const regexMatchedArr = regex.exec(asset.title)
            const imageHref = regexMatchedArr && (regexMatchedArr[1] || regexMatchedArr[2])
 
            // If found href, then return anchor tag, else return original image
            const Wrapper = imageHref ? Anchor : Fragment

            return (
              <Wrapper href={imageHref as string}>
                <ImageContainer>
                  <Image
                    src={asset.url}
                    height={asset.height}
                    width={asset.width}
                    alt={asset.description}
                  />
                  {asset.description && <ImageDesc>{asset.description}</ImageDesc>}
                </ImageContainer>
              </Wrapper>
            )
          }

          default:
            return
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entryMap = new Map()
        if (richText) {
          for (const entry of richText.links.entries.block) {
            if (entry?.sys) {
              entryMap.set(entry.sys.id, entry)
            }
          }
        }
        const entry = entryMap.get(node.data.target.sys.id)
        if (!entry) return
        const typename = capitalize(entry.__typename) as RichTextEntries
        if (Object.values(RichTextEntries).includes(typename)) {
          const RichTextEmbeddedEntry = RTEmbeddedEntriesMapping[typename]
          if (!RichTextEmbeddedEntry) return null
          return <RichTextEmbeddedEntry {...entry} />
        }
        return
      },
    },
  }

  const [elements, setElements] = useState(null)

  useEffect(() => {
    const _elm: any = richText && documentToReactComponents(
      richText.json,
      documentToReactComponentsOptions
    )
    setElements(_elm)
  }, [richText.json])

  return (
    <>
      {elements}
    </>
  )
}

export default RichText
