import {
  useEffect,
  useMemo,
  useState,
} from 'react'

// Hook
import { usePrevious } from '../../../../../hooks'

const useDataTransition = ({
  allHeroImages,
}: {
  allHeroImages: {
    title?: string
    image?: {
      url: string
      description?: string
    }
  }[]
}): {
  currentSubCopy?: string
  currentHeroImage?: {
    url: string
    description?: string
  }
  isAnimationStopped: boolean
  isFirstItem: boolean
} => {
  const [currentHeroImageIndex, setCurrentHeroImageIndex] = useState(allHeroImages?.length > 0 ? 0 : -1)
  const [isAnimationStopped, setIsAnimationStopped] = useState(false)

  useEffect(() => {
    if (currentHeroImageIndex === -1 || allHeroImages?.length === 1) {
      return
    }

    let interval: any

    if (!interval) {
      interval = setInterval(() => {
        if (isAnimationStopped) {
          return
        }

        setCurrentHeroImageIndex((currentHeroImageIndex) => (currentHeroImageIndex + 1) % allHeroImages.length)
      }, 3000)
    }

    return () => clearInterval(interval)
  }, [isAnimationStopped])

  const previousHeroImageIndex = usePrevious(currentHeroImageIndex)

  const isFirstItem = useMemo(() => currentHeroImageIndex === 0, [currentHeroImageIndex])

  useEffect(() => {
    if (previousHeroImageIndex === allHeroImages?.length - 1) {
      setIsAnimationStopped(true)
    }
  }, [previousHeroImageIndex])

  return {
    currentSubCopy: allHeroImages?.[currentHeroImageIndex]?.title,
    currentHeroImage: allHeroImages?.[currentHeroImageIndex]?.image,
    isAnimationStopped,
    isFirstItem
  }
}

export default useDataTransition
