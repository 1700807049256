// TODO-Typo: We still have non-updated code in the project (no font system applied) => Next step, find all individual `fontSizes[...]` and update to current font system (need Designer)
import styled from 'styled-components'
import { ITypography } from '@/interfaces/typography.interfaces'
import { theme, TextStyles } from '@/constants'
import { getTextLevel } from '@/utils'
import { montserrat } from '../GlobalStyles'

const montserratFontFamily = `
  font-family: ${montserrat.style.fontFamily};
`

export const styledText = {
  //#region Hero Styles
  [TextStyles['Large Display Font']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[48]};
    line-height: ${theme.lineHeight[60]};
    letter-spacing: -0.52px;
    font-weight: ${theme.fontWeight.bold};
  `,
  [TextStyles['Subheading']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeight[30]};
    letter-spacing: -0.1px;
    font-weight: ${theme.fontWeight.semiBold};
  `,
  //#endregion
  //#region Heading Styles
  [TextStyles['Heading 1']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[36]};
    line-height: ${theme.lineHeight[46]};
    letter-spacing: -0.25px;
    font-weight: ${theme.fontWeight.bold};
  `,
  [TextStyles['Heading 2']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[24]};
    line-height: ${theme.lineHeight[32]};
    letter-spacing: -0.25px;
    font-weight: ${theme.fontWeight.semiBold};
  `,
  [TextStyles['Heading 3']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeight[26]};
    letter-spacing: -0.2px;
    font-weight: ${theme.fontWeight.semiBold};
  `,
  [TextStyles['Heading 4']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[20]};
    letter-spacing: -0.2px;
    font-weight: ${theme.fontWeight.semiBold};
  `,
  [TextStyles['Heading 5']]: `
    ${montserratFontFamily}
    font-style: normal;
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[18]};
    letter-spacing: -0.1px;
    font-weight: ${theme.fontWeight.semiBold};
  `,
  //#endregion
  //#region Paragraph Styles
  [TextStyles['Large Paragraph Text']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[24]};
    font-weight: ${theme.fontWeight.regular};
  `,
  [TextStyles['Large Paragraph Bold']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[24]};
    font-weight: ${theme.fontWeight.bold};
  `,
  [TextStyles['Small Paragraph Text']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[22]};
    font-weight: ${theme.fontWeight.regular};
  `,
  [TextStyles['Small Paragraph Bold']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[22]};
    font-weight: ${theme.fontWeight.bold};
  `,
  [TextStyles['Overline']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[12]};
    line-height: ${theme.lineHeight[18]};
    letter-spacing: 0.36px;
    font-weight: ${theme.fontWeight.bold};
    text-transform: uppercase;
  `,
  [TextStyles['Caption Text']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[12]};
    line-height: ${theme.lineHeight[18]};
    font-weight: ${theme.fontWeight.regular};
  `,
  //#endregion
  //#region Button Styles
  [TextStyles['Button']]: `
    font-style: normal;
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[24]};
    font-weight: ${theme.fontWeight.bold};
  `,
  //#endregion
}

export const StyledTypography = styled.p.withConfig({
  shouldForwardProp: (propName, defaultValidatorFn) => propName.toString() !== 'type' && defaultValidatorFn(propName),
}).attrs<ITypography>(props => ({
  as: props.type || getTextLevel(props.variant)
}))<ITypography>`
  margin: 0;
  ${props => styledText[props.variant]}

  * {
    font-family: inherit;
  }
`
