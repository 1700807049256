import { useContext } from 'react'
import { FooterContext } from '../Footer'
import {
  ContactWrapper,
  ContactHeading,
  ContactLocation,
  ContactItem,
  ContactList,
  LocationPinIcon,
  ContactAddress,
} from './Contact.styles'

interface FooterContactInterface {
  className?: string
}

const FooterContact: React.FC<FooterContactInterface> = ({ className }) => {
  const footerContext = useContext(FooterContext)
  
  return (
    <ContactWrapper className={className}>
      {footerContext?.officesTitle && <ContactHeading>{footerContext.officesTitle}</ContactHeading>}
      <ContactList>
        {
          footerContext?.officeLocations?.items
            ?.map((office, index) => {
              return office && (
                <ContactItem key={index} itemListLength={footerContext?.officeLocations?.items?.length}>
                  <ContactLocation>
                    <LocationPinIcon /> {office.title}
                  </ContactLocation>
                  <ContactAddress>
                    {office.desc}
                  </ContactAddress>
                </ContactItem>
              )
            })
        }
      </ContactList>
    </ContactWrapper>
  )
}

export default FooterContact
