import React from 'react'
import dayjs from 'dayjs'
import { GlossaryProps } from '@/interfaces/glossary.interfaces'
import {
  BackToList,
  CreatedDate,
  SocialSharingWrapper,
  SubHeaderWrapper,
  TagListWrapper,
  Title,
} from './Header.styles'
import TagList from '../TagList'
import SocialSharing from '@/components/SocialSharing'
import { useCurrentURL } from '@/hooks'
import TopicList from '../TopicList'

interface SubHeaderProps {
  title: GlossaryProps['title']
  date: GlossaryProps['date']
  tagList: GlossaryProps['tags']
  topicList: GlossaryProps['topics']
}

const Header: React.FC<SubHeaderProps> = ({
  title,
  date,
  tagList,
  topicList,
}) => {
  const currentURL = useCurrentURL()
  const hasTagList = !!tagList?.length

  return (
    <>
      <BackToList href='/glossary'>Back to Glossary List</BackToList>

      {!!topicList?.length && <TopicList list={topicList} />}

      <Title>{title}</Title>

      <SubHeaderWrapper hasTagList={hasTagList}>
        <div>
          {date && (
            <CreatedDate hasTagList={hasTagList}>
              {dayjs(date).format('MMM DD, YYYY')}
            </CreatedDate>
          )}
          {hasTagList && (
            <TagListWrapper>
              <TagList list={tagList} />
            </TagListWrapper>
          )}
        </div>
        <SocialSharingWrapper>
          <SocialSharing
            url={currentURL}
            text={title}
          />
        </SocialSharingWrapper>
      </SubHeaderWrapper>
    </>
  )
}

export default Header
