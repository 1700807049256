import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'

export const SecondaryTitleContainer = styled(Typography).attrs({
  type: 'h5',
  variant: TextStyles['Overline'],
})`
  max-width: 315px;
  color: ${({ theme }) => theme.colors.orange};
  text-align: center;
  margin: 0 auto 8px auto;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 900px;
  }
`
