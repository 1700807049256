type ModifyUrlQuery = (
  url: string,
  modifier: (previousQuery: Record<string, string | number>) => Record<string, string | number>,
) => string

export const modifyUrlQuery: ModifyUrlQuery = (url, modifier) => {
  const hasQuery = url.includes('?')
  const previousQuery: Record<string, string | number> = {}

  if (hasQuery) {
    const queryString = url.split('?').pop() as string

    queryString.split('&')?.forEach((item) => {
      const [key, value] = item.split('=')

      previousQuery[key] = value
    })
  }

  const newQuery = modifier(previousQuery as any)
  const originalPath = url.split('?').shift()
  const newQueryString = Object.entries(newQuery).map(([key, value]) => `${key}=${value}`).join('&')

  return originalPath + '?' + newQueryString
}
