// Constants
import { theme } from '@/constants'

// Interfaces
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { WrapperInterface } from '@/interfaces/wrapper.interfaces'
import { ContentItemInterface, ContentItemUITemplateKeys } from '@/interfaces/contentItem'

// Utils
import { getSectionTheme } from '@/utils'

// Components
import LocalThemeProvider from '@/components/LocalThemeProvider'
import { Button, ContentItem } from '@/components'

// Styles
import { WrapperContentItemsContainer } from '../WrapperContentItems/components'
import {
  WrapperContainer,
  InfoContainer,
  StatContainer,
  Title,
} from './Wrapper.styles'

//* Note: Wrapper only takes StatisticsAndIcon for now
const Wrapper: React.FC<WrapperInterface> = (props) => {
  const {
    title,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    itemList,
  } = props

  return (
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(SectionThemeKey.Light) }}>
      <WrapperContentItemsContainer style={{ background: theme.colors.white }}>
        <WrapperContainer>
          <InfoContainer>
            <Title>{title}</Title>
            {buttonLink && buttonText && (
              <Button
                href={buttonLink}
                openInNewTab={openInNewTab}
                eventLabel={buttonEventLabel}
              >
                {buttonText}
              </Button>
            )}
          </InfoContainer>
          <StatContainer hasBelowThreeItems={itemList.length < 3}>
            {itemList?.map((item: ContentItemInterface) =>
              item?.uiTemplate === ContentItemUITemplateKeys.StatisticsAndIcon
                ? <ContentItem data={item} key={item.sys?.id} />
                : null
            )}
          </StatContainer>
        </WrapperContainer>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default Wrapper
