import {
  useState,
  useEffect,
  useMemo,
} from 'react'
import { debounce } from '@/utils'
import { ListingType } from '@/interfaces/carriers'

export enum paginationKeys {
  FIRST_PAGE = 'FIRST_PAGE',
  LAST_PAGE = 'LAST_PAGE',
  PREV_PAGE = 'PREV_PAGE',
  NEXT_PAGE = 'NEXT_PAGE',
}

enum PAGE_SIZE {
  CARRIER = 15,
  PRESS = 12,
  PRESS_MOBILE = 6,
}
enum Location {
  ALL_COUNTRIES = 'All countries',
  ALL_REGIONS = 'All regions'
}

interface CarriersData {
  carriersData: any[] | undefined
  isFirstLoad: boolean
  totalPages: number
  total: number
  currentAmount: number
  isCarrier: boolean
  onFilter: (country: string) => void
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangePage: (key: paginationKeys) => void
}

const getTotalPages = (total: number, pageSize: number) => (total > pageSize ? Math.ceil(total / pageSize) : 1)

const useCarriers = ({ dataList, type }: { dataList?: any[], type?: string }): CarriersData => {
  const isCarrier = type === ListingType.CARRIER
  
  const [carriersData] = useState(dataList || [])
  const [currentCarrierList, setCurrentCarrierList] = useState(dataList)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(isCarrier ? PAGE_SIZE.CARRIER : PAGE_SIZE.PRESS)
  const [filter, setFilter] = useState(type === ListingType.CARRIER ? `${Location.ALL_COUNTRIES}` : `${Location.ALL_REGIONS}`)
  const [search, setSearch] = useState('')

  const totalCarriers = useMemo(() => currentCarrierList?.length || 0, [currentCarrierList, pageSize])
  const totalPages = useMemo(() => getTotalPages(totalCarriers, pageSize), [currentCarrierList, pageSize])

  const generatePagination: any = (data: any) => {
    if(!data?.length) return null
  
    const total = getTotalPages(data.length, pageSize)
    const _cloneData = data.slice()
    const temp: {[page: number]: any[]} = {}
    for (let i = 0 ; i < total; i++) {
      temp[i] = _cloneData.slice(i * pageSize, (i * pageSize) + pageSize)
    }
    return temp
  }
  
  useEffect(() => {
    const isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 600
    if (isMobile && !isCarrier) {
      setPageSize(PAGE_SIZE.PRESS_MOBILE)
    }
  }, [])

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement
    setSearch(target.value.toLowerCase())
    setCurrentPageIndex(0)
  }, 400)

  const onChangePage = (key: paginationKeys ) => {
    switch(true) {
      case key === paginationKeys.FIRST_PAGE && currentPageIndex === 0:
      case key === paginationKeys.PREV_PAGE && currentPageIndex === 0:
      case key === paginationKeys.LAST_PAGE && currentPageIndex === totalPages - 1:
      case key === paginationKeys.NEXT_PAGE && currentPageIndex === totalPages - 1:
        return

      case key === paginationKeys.FIRST_PAGE && currentPageIndex > 0: 
        setCurrentPageIndex(0)
        break

      case key === paginationKeys.LAST_PAGE && currentPageIndex < totalPages - 1: 
        setCurrentPageIndex(totalPages - 1)
        break

      case key === paginationKeys.PREV_PAGE: 
        setCurrentPageIndex(currentPageIndex - 1)
        break

      case key === paginationKeys.NEXT_PAGE: 
        setCurrentPageIndex(currentPageIndex + 1)
        break

      default: 
        return
    }
  }

  const currentAmount = useMemo(() => {
    const itemPerPage = (currentPageIndex + 1) * pageSize 
    return itemPerPage > totalCarriers ? totalCarriers : itemPerPage
  }, [currentPageIndex, totalCarriers, pageSize])

  const onFilter = (country: string) => {
    setCurrentPageIndex(0)
    setFilter(country)
  }

  const filterCondition = (data: any) => {
    const filterCountryCondition = () => {
      switch (filter) {
        case Location.ALL_COUNTRIES:
          return true
  
        case 'United Kingdom':
          return data?.carrier_countries?.includes('Great Britain')
      
        default:
          return data?.carrier_countries?.includes(filter) 
      }
    }

    const filterRegionCondition = () => {
      switch (filter) {
        case Location.ALL_REGIONS:
          return true
      
        default:
          return data?.region?.includes(filter) 
      }
    }
  
    const searchCarrierCondition = !search ? true : data?.name?.toLowerCase()?.includes(search)

    const searchPressCondition = !search ? true : (data?.articleTitle?.toLowerCase()?.includes(search) || data?.publicationName?.toLowerCase()?.includes(search))

    return isCarrier ? (filterCountryCondition() && searchCarrierCondition) : (filterRegionCondition() && searchPressCondition)
  }

  useEffect(() => {
    if (carriersData) {
      const _newList = carriersData.filter(filterCondition)
      setCurrentCarrierList(_newList)
    }
  }, [filter, search])

  return { 
    carriersData: generatePagination(currentCarrierList)?.[currentPageIndex],
    isFirstLoad: !carriersData,
    totalPages,
    currentAmount,
    total: totalCarriers,
    isCarrier,
    onFilter,
    onSearch,
    onChangePage,
  }
}

export default useCarriers
