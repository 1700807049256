import getConfig from 'next/config'

const { serverRuntimeConfig } = getConfig()

export const postRecaptcha = (captchaCode: string): Promise<any> => {
  const fetchUrl = `https://www.google.com/recaptcha/api/siteverify?secret=${serverRuntimeConfig.recaptchaSecretKey}&response=${captchaCode}`
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  }

  return fetch(fetchUrl, fetchOptions)
    .then((response) => response ? response?.json() : null)
}

interface IPostSubmitFormArgs {
  data: {
    pageUrl?: string
    country?: string
    region?: string
  } & Record<string, any>
  zapierHookId: string
  captchaCode?: string
}

export const postSubmitForm = (args: IPostSubmitFormArgs): Promise<Response> => {
  const fetchUrl = '/api/form-submit'
  const fetchOptions: RequestInit = {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return fetch(fetchUrl, fetchOptions)
}
