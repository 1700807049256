import { createContext, useContext } from 'react'

interface IURLContext {
  protocol?: string
  host?: string
  origin?: string
}

const URLContext = createContext<IURLContext>({
  protocol: '',
  host: '',
  origin: '',
})

export const URLParsingProvider: React.FC<any> = ({ urlParsingData, children }) => {
  return <URLContext.Provider value={urlParsingData}>
    {children}
  </URLContext.Provider>
}

export const useParseCurrentURL: any = () => useContext(URLContext)
