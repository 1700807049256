import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { ThumbnailsWrapper, ThumbnailsItemListWrapper } from '../Category/Category.styles'
import { TopicName } from '../Article/Article.styles'
import {
  HeroImage,
  ThumbnailContentContainer,
  ThumbnailLinkContainer,
  CreatedDate,
  Title,
  Description,
} from '../Thumbnail/Thumbnail.styles'
import { styledText } from '../Typography/Typography.styles'

export const ResourceSubSectionWrapper = styled.div`
  ${ThumbnailsWrapper} {
    padding-top: 30px;
    padding-bottom: 0;
  }

  ${ThumbnailContentContainer} {
    height: 238px;

    > a {
      margin-top: auto;
    }

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      height: 290px;
    }
  }

  ${Title} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  ${ThumbnailsItemListWrapper} {
    gap: 30px 30px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      gap: 52px 31px;
    }

    > a:not(:first-child) {
      ${TopicName} {
        margin: 0 10px 10px 0;

        ${({ theme }) => theme.mediaBreakpointUp.md} {
          margin-bottom: 14px;
        }
      }
    }

    // Modify style for first child
    > a:first-child {
      ${CreatedDate} {
        color: ${({ theme }) => theme.colors.lightGray};
      }

      ${ThumbnailLinkContainer} {
        background: transparent;
      }

      ${TopicName} {
        ${({ theme }) => theme.mediaBreakpointUp.md} {
          margin-bottom: 16px;
        }
      }

      ${ThumbnailContentContainer} {
        padding: 10px 0 0 0;
      }

      ${HeroImage} {
        border-radius: 8px;
      }

      ${ThumbnailLinkContainer} {
        &:hover {
          box-shadow: none;
        }
      }

      ${Description} {
        color: ${({ theme }) => theme.colors.darkBlue};
      }

      ${({ theme }) => theme.mediaBreakpointUp.md} {
        grid-column: 1 / -1;

        ${ThumbnailLinkContainer} {
          display: flex;
          width: 100%;
        }

        ${ThumbnailContentContainer} {
          max-height: 335px;
          height: auto;
          padding: 0 0 23px 50px;
        }

        ${HeroImage} {
          height: 335px;
          flex: 0 0 558px;
        }

        ${Title} {
          ${styledText[TextStyles['Heading 2']]}
          margin-bottom: 10px;
        }
      }
    }
  }
`

export const SectionTitle = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text};
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    text-align: left;
  }
`
