import {
  Asset,
  ImageContentType,
  VideoContentType,
  LottieContentType,
} from '@/interfaces/common'

export const isVideo = (asset: Asset): boolean => {
  const { url, contentType } = asset
  if (!url || !contentType) return false

  return Object.values(VideoContentType)
    .includes(contentType as VideoContentType)
}

export const isImage = (asset: Asset): boolean => {
  const { url, contentType } = asset
  if (!url || !contentType) return false

  return Object.values(ImageContentType)
    .includes(contentType as ImageContentType)
}

export const isLottie = (asset: Asset): boolean => {
  const LOTTIE_FILE_EXTENSION = '.lottie'
  const { url, contentType } = asset
  if (!url || !contentType) return false

  return (
    url.endsWith(LOTTIE_FILE_EXTENSION) &&
    Object.values(LottieContentType).includes(contentType as LottieContentType)
  )
}
