import getConfig from 'next/config'
import { ContentTypeIds } from '@/constants'
import { sendSentryError } from '@/utils'
import { IContentfulRawDataSys } from '@/interfaces/redis.interfaces'

const { serverRuntimeConfig } = getConfig()
const contentful = require('contentful')

export const getContentfulUpperEntries = async (sys: IContentfulRawDataSys): Promise<Record<string, any>> => {
  const contentfulClient = contentful.createClient({
    space: serverRuntimeConfig.contentfulSpace,
    accessToken: serverRuntimeConfig.contentDeliveryApiAccessToken,
    environment: serverRuntimeConfig.contentfulEnvironment,
  })

  let data: any = null
  let errId: any = null
  try {
    const isAsset: boolean = sys.type === ContentTypeIds.Asset
    await contentfulClient
      .getEntries({
        [isAsset ? 'links_to_asset' : 'links_to_entry']: sys.id,
        include: 0,
      })
      .then((res: any) => {
        data = res
      })
      .catch((err: any) => {
        errId = err.sys?.id || 'UndefinedError'
      })

    if (errId) return { status: 400, error: errId }
    return { status: 200, data }
  } catch {
    return { status: 422, error: 'Unprocessable Request' }
  }
}

// Contentful Base Fetcher
const callContentful: any = async (query: any, isPreviewMode: boolean, isPmc = false) => {
  const ACCESS_TOKEN_PP = isPreviewMode 
    ? serverRuntimeConfig.contentPreviewApiAccessToken 
    : serverRuntimeConfig.contentDeliveryApiAccessToken

  const ACCESS_TOKEN_PMC = isPreviewMode
    ? serverRuntimeConfig.pmcContentPreviewApiAccessToken
    : serverRuntimeConfig.pmcContentDeliveryApiAccessToken

  const CONTENTFUL_SPACE = isPmc
    ? serverRuntimeConfig.pmcContentfulSpace
    : serverRuntimeConfig.contentfulSpace

  const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE}/environments/${serverRuntimeConfig.contentfulEnvironment}`

  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${isPmc ? ACCESS_TOKEN_PMC : ACCESS_TOKEN_PP}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  }

  try {
    const data = await fetch(fetchUrl, fetchOptions).then((response) =>
      response.json()
    )

    if (data.errors) {
      console.error(data.errors)
    }

    return data
  } catch (err) {
    sendSentryError(err, {
      query, ['preview-mode']: isPreviewMode
    })
    return { data: null }
  }
}

export const callPublicContentful = async (
  query: string,
  // TODO: Khanh - update fetcher to use in preview mode
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPreviewMode: boolean
): Promise<any> => {
  const fetchUrl = '/api/contentful'
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  }

  return fetch(fetchUrl, fetchOptions).then((response) => response.json())
}

export default callContentful
