import React from 'react'
import ReactHubspotForm from 'react-hubspot-form'
import { HUBSPOT_PORTAL_REGION, HUBSPOT_PORTAL_ID } from '@/constants/app.constants'

interface IHubspotFormProps {
  formId?: string
}

const HubspotForm: React.FC<IHubspotFormProps> = ({ formId }) => {
  return (
    <ReactHubspotForm
      region={HUBSPOT_PORTAL_REGION}
      portalId={HUBSPOT_PORTAL_ID}
      formId={formId}
    />
  )
}

export default HubspotForm
