import { ISEOFields } from './head'

export enum EventsSectionType {
  UPCOMING_EVENTS = 'Upcoming events',
  PAST_EVENTS = 'Past events',
  PRODUCT_CHATS = 'Product chats',
  SUCCESS_STORIES = 'Success Stories',
  EBOOKS = 'Ebooks',
  REPORTS = 'Reports'
}

export enum EventType {
  WEBINAR = 'Webinar',
  CONFERENCE = 'Conference',
  PRODUCT_CHATS = 'Product chats',
  RESOURCES = 'Resources',
  SUCCESS_STORIES = 'Success Stories',
  EBOOKS = 'Ebooks',
  REPORTS = 'Reports',
  PARCEL_MONITOR_REPORT = 'Parcel Monitor Report',
}

export interface IEventsSection {
  title: string
  type: EventsSectionType
  buttonText: string
  withSeparator: boolean
  showDate: boolean
  showLocation: boolean
}

export interface IEvent {
  title: string
  description: string
  type: EventType
  image: {
    url: string
    description: string
  }
  location?: string
  startDate: string
  endDate: string
  buttonText?: string
  buttonLink?: string
  seo?: ISEOFields
  eventSlug: string
  openInNewTab?: boolean
  greyLabel: Array<string>
}
