import { forwardRef } from 'react'
import { Document } from '@contentful/rich-text-types'

// Component
import {
  TextInput,
  TextInputWrapper,
} from './Input.styles'
import {
  Label,
  ErrorMessage,
} from '../..'

// Type
import { InputWidth } from '@/interfaces/form'

// Util
import { documentToReactComponentsWithBr } from '@/utils'

interface IInputProps {
  value?: string
  name?: string
  label?: {
    json: Document
  }
  width?: InputWidth
  placeholder?: string
  isMandatory?: boolean
  errorMessage?: string
}

const Input: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IInputProps & Record<string, any>
> = (props, ref) => {
  const {
    name,
    label,
    width,
    placeholder,
    isMandatory,
    errorMessage,
    ...restProps
  } = props

  return (
    <>
      {label && (
        <Label
          htmlFor={name}
          isMandatory={isMandatory}
        >
          {documentToReactComponentsWithBr(label.json)}
        </Label>
      )}
      <TextInputWrapper isInvalid={!!errorMessage} width={width}>
        <TextInput
          ref={ref}
          name={name}
          placeholder={placeholder}
          {...restProps}
        />
      </TextInputWrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  )
}

export default forwardRef(Input)
