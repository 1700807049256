import { Asset, IRichTextDocument } from './common'
import { GridBackgroundColorKeys } from './grid'

export enum ContentItemUITemplateKeys {
  IconAndCopy = 'Icon & copy',
  ImageAndCopy = 'Image & copy',
  SmallImageAndCopy = 'Small Image & copy',
  IconAndCopyInCard = 'Icon & copy in card',
  Statistics = 'Statistics',
  StatisticsAndIcon = 'Statistics & icon',
  Grid = 'Grid',
  DescriptiveBanner = 'Descriptive banner',
  NestedItem = 'Nested Item',
}

export enum ImageSize {
  Default = 'Default',
  Large = 'Large',
}

export type ContentItemInterface = {
  sys?: { id: string }
  uiTemplate?: ContentItemUITemplateKeys
  title?: string
  subTitle?: string
  description?: string
  seoDescription?: IRichTextDocument
  image?: Asset
  imageSize?: ImageSize
  buttonText?: string
  buttonLink?: string
  buttonEventLabel?: string
  openInNewTab?: boolean
  backgroundColor?: GridBackgroundColorKeys
}
