// TODO-Typo: Once typo is stable, split this file into /typography/head, /typography/paragraphy, /typography/heading
const fontWeight = {
  bold: 700,
  semiBold: 600,
  regular: 400,
}

const fontSizes = {
  8: '0.5rem',
  10: '0.625rem',
  11: '0.6875rem',
  12: '0.75rem',
  14: '0.875rem',
  15: '0.9375rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  21: '1.3125rem',
  24: '1.5rem',
  28: '1.75rem',
  30: '1.875rem',
  35: '2.1875rem',
  36: '2.25rem',
  40: '2.5rem',
  45: '2.8125rem',
  48: '3rem',
  50: '3.125rem',
  53: '3.3125rem',
  64: '4rem',
  65: '4.0625rem',
  80: '5rem',
}

const lineHeight = {
  14: '14px',
  15: '15px',
  16: '16px',
  18: '18px',
  19: '19px',
  20: '20px',
  22: '22px',
  24: '24px',
  25: '25px',
  26: '26px',
  28: '28px',
  29: '29px',
  30: '30px',
  32: '32px',
  33: '33px',
  35: '35px',
  36: '36px',
  40: '40px',
  46: '46px',
  49: '49px',
  60: '60px',
  64: '64px',
  75: '75px',
  85: '85px',
}

const typography = {
  fontWeight,
  fontSizes,
  lineHeight,
}

export default typography
