import styled from 'styled-components'
import { FilledButtonAsLink } from '../Button.styles'
import ArrowRight from '../../../public/images/icons/arrow-right.svg'
import { defaultSectionTheme } from '../../../constants'

export const ArrowRightIcon = styled(ArrowRight)`
  margin-left: 12px;
  min-width: 16px;
  height: 14px;

  fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.link.text
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.text
          : defaultSectionTheme.secondaryButton.link.text};

  & > path {
    transition: .4s;
  }
`

export const ButtonLink = styled(FilledButtonAsLink)`
  color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.link.text
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.text
          : defaultSectionTheme.secondaryButton.link.text};
  padding: 0;
  height: unset !important;
  border-radius: unset;

  & > span {
    justify-content: flex-start;
    width: fit-content;
    min-width: unset;
    flex: 1;
  }

  :not([disabled]):hover {
    color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.secondaryButton.link.hoverText
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.hoverText
          : defaultSectionTheme.secondaryButton.link.hoverText} !important;
    
    ${ArrowRightIcon} > path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.secondaryButton.link.hoverText
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.hoverText
          : defaultSectionTheme.secondaryButton.link.hoverText} !important;
    }
  }

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.link.text
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.text
          : defaultSectionTheme.secondaryButton.link.text};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.link.hoverText
        : theme.sectionTheme
          ? theme.sectionTheme.secondaryButton.link.hoverText
          : defaultSectionTheme.secondaryButton.link.hoverText} !important;
        }
      }
    }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
