import { CSSProperties } from 'react'
import Image, { ImageProps } from 'next/image'
import { Asset } from '@/interfaces/common'
import {
  isImage, isVideo, isLottie 
} from '@/utils'
import Video from './components/Video'
import DotLottie from './components/DotLottie'

type AssetRendererProps = {
  asset?: Asset
  className?: string
  ImageComponent?: React.FC<any>
  VideoComponent?: React.FC<any>
  videoProps?: {
    height?: {
      mobile?: number | string
      desktop?: number | string
    }
    style?: CSSProperties
  }
  imageProps?: {
    // for next/image
    width?: ImageProps['width']
    height?: ImageProps['height']
    quality?: ImageProps['quality']
    // for Product Mockup
    ref?: React.Ref<HTMLImageElement>
    onLoad?: () => void
    // for styled component
    [key: string]: any
  }
}

const AssetRenderer: React.FC<AssetRendererProps> = ({
  asset,
  className,
  ImageComponent = Image,
  VideoComponent = Video,
  videoProps,
  imageProps,
}) => {
  if (!asset) return null

  if (isImage(asset) && ImageComponent) {
    return <ImageComponent
      {...imageProps}
      src={asset.url as any}
      alt={asset.description || 'Image'}
    />
  }

  if (isVideo(asset) && VideoComponent) {
    return <VideoComponent
      {...videoProps}
      asset={asset}
    />
  }

  if (isLottie(asset)) {
    return <DotLottie
      src={asset.url}
      className={className}
    />
  }

  return null
}

export default AssetRenderer
