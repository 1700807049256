import React, { useEffect, useMemo } from 'react'

import Panel from '@/components/Accordion/Panel'
import Accordion from '@/components/Accordion'
import MenuItemList from '../../MenuItemList'
import SupportSection from '../../SupportSection'

import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { HeaderSectionType } from '@/interfaces/headerSectionType'
import { HeaderItemInterface } from '@/interfaces/headerItem'
import { excludeEmptyItem } from '@/utils'

import {
  MenuContainer,
  NavigationDropdownWrapper,
  SectionLineSeparator 
} from './NavigationDropdown.styles'
import { LoginButton } from '../../Header.styles'

const NavigationDropdown:React.FC<any> = ({ headerItems , isExpanded }) => {

  const supportInfo = useMemo(() => {
    return excludeEmptyItem(headerItems)?.reduce(
      (result, item : HeaderItemInterface) => {
        const firstSupport = item.sections?.find(
          (section : any) => section?.type === HeaderSectionType.Standalone
        )
        if(!result && firstSupport) { result = firstSupport }
        return result
      }, null)
  }, [])

  useEffect(() => {
    document.body.setAttribute('style',`overflow: ${isExpanded ? 'hidden' : 'unset'};`)
  }, [isExpanded])

  useEffect(() => {
    return () => {
      document.body.setAttribute('style','overflow: unset;')
    }
  }, [])

  return (
    <NavigationDropdownWrapper id='navigation-dropdown' isShow={isExpanded}>
      <Accordion parentId='navigation-dropdown' isCollapseAll={!isExpanded}>
        {excludeEmptyItem(headerItems)?.map((item: HeaderItemInterface) => {
          const collectionItems= item.sections
          return <Panel
            key={item.sys?.id}
            panelKey={item.sys?.id}
            title={item.title}
            link={item.link}
            openInNewTab={item.openInNewTab}
            panelTheme={SectionThemeKey.Light}>
            {!item.link && collectionItems.length > 0 && (
              <MenuContainer>
                {excludeEmptyItem(collectionItems)?.map((section: any) => {
                  return section.type === HeaderSectionType.Standalone ? null : (
                    <MenuItemList
                      key={section.sys?.id}
                      title={section.title}
                      menuItems={section.menuLinkItems}
                    />
                  )
                })}
              </MenuContainer>
            )}
          </Panel>
        })}
      </Accordion>
      <LoginButton href='/login'>Login</LoginButton>
      {supportInfo?.type === HeaderSectionType.Standalone && (
        <>
          <SectionLineSeparator/>
          <SupportSection
            title={supportInfo?.title}
            content={supportInfo?.content || ''}
            icon={supportInfo?.icon}
            actionButtonLink={supportInfo?.actionButtonLink}
            actionButtonTitle={supportInfo?.actionButtonTitle}
            buttonEventLabel={supportInfo?.buttonEventLabel}
          />
        </>
      )}
    </NavigationDropdownWrapper>
  )
}

export default NavigationDropdown
