import { useRouter } from 'next/router'

import { useParseCurrentURL } from './useParseUrl'

const useCurrentURL = (): string => {
  const { asPath } = useRouter()
  const { origin } = useParseCurrentURL()

  return `${origin}${asPath}`
}

export default useCurrentURL
