import COLOR_PALETTE from './colorPalette.constants'

const {
  orange,
  blue,
  teal,
  purple,
} = COLOR_PALETTE

const PAGE_THEME = {
  Checkout: {
    dark: blue[7],
    main: blue[5],
    disabled: blue[2],
    light: blue[1],
  },
  PostPurchase: {
    dark: orange[4],
    main: orange[3],
    disabled: orange[2],
    light: orange[1],
  },
  Returns: {
    dark: purple[4],
    main: purple[3],
    disabled: purple[2],
    light: purple[1],
  },
  Logistics: {
    dark: teal[4],
    main: teal[4],
    disabled: teal[2],
    light: teal[1],
  },
  CoPilot: {
    dark: blue[8],
    main: blue[6],
    disabled: blue[4],
    light: blue[3],
  },
}

export default PAGE_THEME
