import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { styledText } from '@/components/Typography/Typography.styles'

export const SearchInput = styled.input`
  padding: 8.65px 33px 8.65px 10px;
  position: relative;
  width: 315px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.darkGray};
  height: 40px;
  ${styledText[TextStyles['Large Paragraph Text']]}

  :focus-visible {
    outline: none;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 410px;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
    opacity: 50%;
  }
`

export const SearchIcon = styled.img`
  position: absolute;
  right: 12.5px;
  top: 50%;
  transform: translateY(-50%);
`
