import { ITypography } from '@/interfaces/typography.interfaces'
import { StyledTypography } from './Typography.styles'
import { forwardRef } from 'react'

// TODO: Add mobile, desktop logic. Style Heading 1 should have tag <h1>, styled Heading 2 should have tag <h2>
// Note: type can still be overidden by passing type prop - for unique cases where a <p> tag can be <span> or <div> for event/animation purposes
/**
 * @param variant constants/TextStyles
 * @returns {React.FC} Returns corresponding stylings and tag type
 */
const Typography: React.FC<ITypography> = forwardRef((props, ref) => {
  const { children, ...rest } = props

  return (
    <StyledTypography ref={ref} {...rest}>
      {children}
    </StyledTypography>
  )
})

export default Typography
