import { excludeEmptyItem } from '@/utils'
import {
  Container,
  SocialSharing,
  RichText,
} from '@/components'
import { useCurrentURL } from '@/hooks'
import { GlossaryProps } from '@/interfaces/glossary.interfaces'
import {
  Wrapper,
  Content,
  Footer,
} from './Glossary.styles'
import Header from './Header'
import TagList from './TagList'
import RelatedArticles from './RelatedArticles'

const Glossary: React.FC<GlossaryProps> = (props) => {
  const {
    title,
    topics,
    tags,
    date,
    content,
    relatedArticles,
  } = props
  const topicList = excludeEmptyItem(topics)
  const tagList = excludeEmptyItem(tags)
  const currentURL = useCurrentURL()

  return (
    <>
      <Wrapper>
        <Container>
          <Header
            title={title}
            date={date}
            tagList={tagList}
            topicList={topicList}
          />

          {content && (
            <Content className='custom__richtext'>
              <RichText richText={content} />
            </Content>
          )}

          {!!tagList?.length && <TagList list={tagList} />}

          <Footer>
            <SocialSharing
              title='Share this article'
              url={currentURL}
              text={title}
            />
          </Footer>
        </Container>
      </Wrapper>

      {relatedArticles?.length && (
        <RelatedArticles relatedArticles={relatedArticles} />
      )}
    </>
  )
}

export default Glossary
