import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const PmArticleContainer = styled.div`
  width: 315px;
  overflow: hidden;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlue};
  transition: box-shadow 0.4s;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 362px;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  height: 181px;
  border-radius: 4px;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 208px;
  }
`

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  margin-bottom: 10px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    margin-bottom: 14px;
  }
`

export const CreatedDate = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  color: ${({ theme }) => theme.colors.grayBlue};
`
