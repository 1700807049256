import React from 'react'
import {
  HeaderTitleArrow,
  HeaderItemText,
  HeaderTitle, 
  HeaderItemLink
} from './HeaderItemTitle.style'

interface HeaderItemTitleProps {
    link?: string
    title?: string
    openInNewTab?: boolean
}

const HeaderItemTitle: React.FC<HeaderItemTitleProps> = ({
  link, title, openInNewTab
}) => {

  return link 
    ? <HeaderItemLink href={link} target={openInNewTab ? '_blank' : '_self'}>
      <HeaderTitle>
        <HeaderItemText>{title}</HeaderItemText>
      </HeaderTitle>
    </HeaderItemLink> 
    : (
      <HeaderTitle>
        <HeaderItemText>{title}</HeaderItemText>
        <HeaderTitleArrow />
      </HeaderTitle>
    )
}

export default HeaderItemTitle
