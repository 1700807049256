// TODO-Typo: DO NOT EDIT, belongs to Form font system (not mapped yet)
import styled from 'styled-components'

export const TextAreaWrapper = styled.div``

export const TextAreaInput = styled.textarea<{ isInvalid: boolean }>`
  display: block;
  width: 100%;
  padding: 10px 10px 0 10px;
  height: 85px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[24]};

  ${({ isInvalid, theme }) => isInvalid && `
    border-color: ${theme.colors.red};
  `}

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray2};
  }
`
