export const isAbsoluteUrl = (url: string): boolean =>
  /^[a-z][a-z0-9+.-]*:/.test(url)

/** Check if a path should open in new tab
 * @param path
 * @returns boolean
 * @example
 * isOpenInNewTab('https://www.google.com') // true
 * isOpenInNewTab('/google') // false
*/
export const isOpenInNewTab = (path: string): boolean => {
  // if relative path then open in same tab
  if (!isAbsoluteUrl(path)) {
    return false
  }

  // if hostname same, then open in same tab
  // if hostname different, then open in new tab
  try {
    const { hostname } = new URL(path)
    return hostname !== window.location.hostname
  } catch {
    // if url is invalid or something went wrong, then open in new tab
    return true
  }
}
