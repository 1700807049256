import { IHeadFields } from '@/interfaces/head'
import { Locales } from '@/interfaces/locales'
import { NextSeo } from 'next-seo'

const DocumentHead: React.FC<IHeadFields> = ({ data, languageData }) => {
  const nonGermanData = languageData?.filter(({
    locale,
  }) => locale !== Locales.Germany)
  
  const languageAlternates = nonGermanData?.map(({
    locale,
    domain,
    pageSlug
  }) => ({
    hrefLang: locale === Locales.English ? 'x-default' : locale,
    href: 'https://' + domain + pageSlug,
  }))

  return (
    <NextSeo
      title={data?.title}
      defaultTitle='Parcel Perform'
      description={data?.description}
      noindex={data?.robots?.includes('noindex')}
      nofollow={data?.robots?.includes('nofollow')}
      canonical={data?.ogUrl}
      openGraph={{
        type: data?.ogType,
        url: data?.ogUrl,
        title: data?.ogTitle,
        description: data?.ogDescription,
        images: [
          {
            url: data?.ogImage?.url,
            alt: data?.ogImage?.description
          },
        ],
      }}
      twitter={{
        handle: '@ParcelPerform',
        site: '@ParcelPerform',
        cardType: 'summary_large_image',
      }}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/favicons/favicon-32x32.png',
          type:'image/png',
          sizes: '32x32',
        },
        {
          rel: 'icon',
          href: '/favicons/favicon-16x16.png',
          type:'image/png',
          sizes: '16x16',
        },
        {
          rel: 'apple-touch-icon',
          href: '/favicons/apple-touch-icon.png',
          sizes: '180x180',
        },
        {
          rel: 'mask-icon',
          href: '/favicons/safari-pinned-tab.svg',
          color: '#001B3A',
        },
        {
          rel: 'manifest',
          href: '/favicons/site.webmanifest',
        }
      ]}
      additionalMetaTags={[
        {
          name: 'msapplication-TileColor',
          content: '#f3f4f6',
        }, 
        {
          property: 'theme-color',
          content: '#ffffff',
        }
      ]}
      languageAlternates={languageAlternates}
    />
  )
}

export default DocumentHead
