import Image from 'next/image'
import { renderHTML } from '@/utils'
import { Title } from '../MenuItemList/MenuItemList.styles'
import { 
  SupportSection, 
  Content, 
  ImageWrapper,
  CustomTextButton,
} from './SupportSection.styles'

interface Icon {
  url: string
  description: string
}

interface SupportSectionComponentProps {
  title: string
  content: string
  icon: Icon
  actionButtonTitle?: string
  actionButtonLink?: string
  buttonEventLabel?: string
}

const SupportSectionComponent: React.FC<SupportSectionComponentProps> = ({
  title,
  content,
  icon,
  actionButtonTitle = 'Talk to a Specialist',
  actionButtonLink = '/contact-sales',
  buttonEventLabel = '',
}) => (
  <SupportSection>
    {icon && (
      <ImageWrapper>
        <Image
          src={icon.url}
          alt={icon.description}
          quality={100}
          fill
          sizes='100vw'
          style={{
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />
      </ImageWrapper>
    )}
    {title && <Title>{title}</Title>}

    {content && <Content>{renderHTML(content.replace(/(?:\r\n|\r|\n)/g, '<br />'))}</Content>}

    {
      actionButtonLink && actionButtonTitle &&
      <CustomTextButton
        href={actionButtonLink}
        eventLabel={buttonEventLabel}
        openInNewTab
      >
        {actionButtonTitle}
      </CustomTextButton>
    }
  </SupportSection>
)

export default SupportSectionComponent
