import { FiArrowRight } from 'react-icons/fi'

// Component
import { Container, ReCaptchaScript } from '@/components'
import {
  InnerContent,
  Wrapper,
  Title,
  FormWrapper,
  EmailInput,
  SubmitButton,
  CloseButton,
  ErrorMessage,
  ThankYouCard,
  CheckIcon,
  ThankYouText,
} from './StickySubscribeBanner.styles'

// Type
import { IFormProps } from '@/interfaces/form'

// Hook
import useSubscribeBanner from './useSubscribeBanner.hook'

const StickySubscribeBanner: React.FC<IFormProps> = ({
  title,
  zapierHookId,
  formFieldList,
  thankYouTitle,
  eventLabel,
  buttonEventLabel,
}) => {
  const {
    email,
    error,
    showSubscribeBanner,
    emailInputField,
    handleEmailChange,
    handleFormSubmit,
    handleButtonClick,
    handleCloseButtonClick,
    isSubmitted,
    isSubmitting
  } = useSubscribeBanner({
    zapierHookId,
    formFields: formFieldList,
    eventLabel,
    buttonEventLabel,
  })

  return (
    <Wrapper
      hasError={!!error}
      shouldApplyAnimation={!showSubscribeBanner}
    >
      <Container>
        <InnerContent isSubmitted={isSubmitted}>
          <ReCaptchaScript />
          {isSubmitted ? (
            <ThankYouCard>
              <CheckIcon src='/images/form-check.svg' alt='check icon' />
              <ThankYouText>{thankYouTitle}</ThankYouText>
            </ThankYouCard>
          ) : (
            <>
              {title && <Title>{title}</Title>}
              <FormWrapper
                onSubmit={handleFormSubmit}
                hasError={!!error}
              >
                <EmailInput
                  hasError={!!error}
                  value={email}
                  placeholder={emailInputField?.placeholder}
                  onChange={handleEmailChange}
                />
                <EmailInput
                  hasError={!!error}
                  value={email}
                  placeholder={title}
                  onChange={handleEmailChange}
                  isForMobile
                />
                <SubmitButton disabled={isSubmitting} onClick={handleButtonClick}>
                  <FiArrowRight />
                </SubmitButton>
                <ErrorMessage>{error}</ErrorMessage>
              </FormWrapper>
            </>
          )}
          <CloseButton
            hasError={!!error}
            src='/images/close-button.svg'
            alt='Close button'
            onClick={handleCloseButtonClick}
          />
        </InnerContent>
      </Container>
    </Wrapper>
  )
}

export default StickySubscribeBanner
