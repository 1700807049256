import styled from 'styled-components'

const tagBottomGap = 4

export const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${tagBottomGap}px;
`

export const Tag = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-right: 4px;
  margin-bottom: ${tagBottomGap}px;
  color: ${({ theme }) => theme.colors.chetwodeBlue};
`
