import Panel from '@/components/Accordion/Panel'
import Accordion from '@/components/Accordion'
import FooterItem from '../FooterItem'
import { DynamicLink } from '@/components'

import { useWindowSize } from '@/hooks'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'

import {
  FooterItemSectionWrapper, 
  FooterItemWrapper,
  FooterItemTitle
} from './FooterItemSection.styles'
import { Divider } from '../Footer.styles'

interface FooterItemSectionInterface {
  itemList: FooterItemInterface[]
}

interface FooterItemInterface {
  sys: { id: string }
  title: string
  sections: Record<string, unknown>[]
  link?: string
  openInNewTab?: boolean
}

const MOBILE_BREAKPOINT = 599

const FooterItemSection: React.FC<FooterItemSectionInterface> = ({ itemList }) => {
  const sectionsCollection = itemList?.filter((item: any) => item)
  const { width: windowWidth } = useWindowSize()

  // Desktop version
  const DesktopFooterSection = (
    <FooterItemSectionWrapper>
      {sectionsCollection?.map((item: FooterItemInterface) => {
        return (
          <FooterItemWrapper key={item.sys?.id}>
            {item?.link ? (
              <DynamicLink href={item.link} target={item?.openInNewTab ? '_blank' : '_self'}>
                <FooterItemTitle isLink={true}>{item.title}</FooterItemTitle>
              </DynamicLink>
            ) : (
              <FooterItemTitle>{item.title}</FooterItemTitle>
            )}
          
            {!item?.link && <FooterItem itemList={item.sections} />}
          </FooterItemWrapper>
        )
      })}
    </FooterItemSectionWrapper>
  )

  // Mobile version
  const MobileFooterSection = (
    <>
      <Divider/>
      <Accordion>
        {sectionsCollection?.map((item: FooterItemInterface) => {
          return (
            <Panel
              key={item.sys?.id}
              panelKey={item.sys?.id}
              title={item.title}
              link={item?.link}
              openInNewTab={item.openInNewTab}
              panelTheme={SectionThemeKey.Dark}
            >
              <FooterItemWrapper>
                <FooterItem itemList={item.sections} /> 
              </FooterItemWrapper>
            </Panel>
          )
        })}
      </Accordion>
    </>
  )

  return windowWidth > MOBILE_BREAKPOINT ? DesktopFooterSection : MobileFooterSection 
}

export default FooterItemSection
