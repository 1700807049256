import { TextStyles } from '@/constants'

export const truncateTextWithEllipsis = (text: string, maxChar: number): string => {
  if (text.length > maxChar) {
    return text.substring(0, maxChar) + '...'
  }
  return text
}

export const tagGenerator = (text: string): string => {
  return text
    // Remove nonsense symbols
    .replace(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.@#£\/]/g, '')
    // Capitalize first letter of each word
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    // Remove spaces
    .replace(/\s/g, '')
    // Add a hashtag symbol before the string
    .replace(/^/, '#')
}

export const capitalize = (s = ''): string => s && s[0].toUpperCase() + s.slice(1)

export const getTextLevel = (variant: string) => {
  switch (variant) {
    case TextStyles['Heading 1']: return 'h1'
    case TextStyles['Heading 2']: return 'h2'
    case TextStyles['Heading 3']: return 'h3'
    case TextStyles['Heading 4']: return 'h4'
    case TextStyles['Heading 5']: return 'h5'
    default: return 'p'
  }
}
