// TODO-Typo: NOT EDIT YET, no font system
import styled from 'styled-components'

export const FooterItemSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  margin-top: 30px;
  padding-top: 36px;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  flex: 1;

  &:before {
    content: '';
    opacity: .5;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: 0;
  }


  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    flex-direction: row;
    margin-top: 0;
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`

export const FooterItemWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  width: auto;
  flex: 1;

  a {
    color: inherit;  
  }

  &:not(:first-child) {
    margin-left: 16px;
  }

  @media (min-width: 1188px) {
    width: 144px;
  }
`

export const FooterItemTitle = styled.div<{ isLink?: boolean }>`
  font-weight: 900;
  line-height: ${({ theme }) => theme.lineHeight[19]};
  margin-bottom: 24px;
  text-transform: uppercase;

  ${({ isLink, theme }) => isLink && `
    :hover {
      color: ${theme.colors.orange};
    }
  `}
`
