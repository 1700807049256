import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
  display: flex;
  user-select: none;
  margin-top: 1.6px;
`

export const Icon = styled.div`
  flex: 0 0 13px;
  height: 13px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 3px;

  svg {
    font-size: ${({ theme }) => theme.fontSizes[8]};
  }
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[18]};

  a {
    color: ${({ theme }) => theme.colors.brightBlue};

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
      text-decoration: underline;
    }

    &:visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  > *:first-child {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }
`
