import styled from 'styled-components'
import { TextStyles } from '@/constants'
import {
  PanelTitle,
  PanelWrapper,
  PanelContent,
  LinkPanelTitle
} from '@/components/Accordion/Panel/Panel.styles'
import { styledText } from '@/components/Typography/Typography.styles'

const dropdownTopMargin = 70

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NavigationDropdownWrapper = styled.div<{ isShow?: boolean }>`
  position: absolute;
  left: 0;
  top: ${dropdownTopMargin}px;
  width: 100vw;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlue};
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.15);
  padding: 32px 24px;
  z-index: 1;
  display: ${props => props.isShow ? 'block' : 'none'};
  max-height: calc(100vh - ${dropdownTopMargin}px - 35px); // margin-bottom 35px
  overflow-y: auto;

  ${PanelWrapper} {
    ${PanelTitle} {
      ${styledText[TextStyles['Button']]}
      padding: 4px 0;
      text-transform: unset;
      align-items: center;
    }

    ${PanelContent} {
      padding-top: 16px;
    }

    :not(:last-child) ${PanelTitle} {
      margin-bottom: 0;
    }

    :not(:first-child) ${PanelTitle} {
      margin-top: 20px;
    }
  }

  ${LinkPanelTitle}:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`

export const SectionLineSeparator = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black05};
  margin: 20px 0;
`
