import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import ArrowDown from '../../public/images/icons/angle-down.svg'

export const TagWrapper = styled(Typography).attrs({
  type: 'span',
  variant: TextStyles['Large Paragraph Text'],
})`
  height: 28px;
  padding: 4px 12px;
  background: ${({ theme }) => theme.colors.hawkesBlue};
  border-radius: 50px;
  transition: all .2s ease;

  :hover {
    background: ${({ theme }) => theme.colors.steelBlue};
  }
`

export const TagArrow = styled(ArrowDown)<{ direction: 'up' | 'down' }>`
  margin-left: 8px;
  transition: .2s linear;

  path {
    transform: scale(.68);
    fill: ${({ theme }) => theme.colors.darkBlue}
  }

  ${({ direction }) => direction === 'up' &&`
    transform: rotate(180deg) translate(6px, 3px);
    transform-origin: center center;
  `}
`
