import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { Button, Typography } from '@/components'
import { richTextResetStyles } from '@/components/RichText/RichText.styles'
import { LeadsBackgroundContainer, LeadsContainer } from '../../components/LeadsWrapper.styles'
import { styledText } from '@/components/Typography/Typography.styles'

export const BannerContainer = styled(LeadsContainer)`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`

export const BannerBackgroundContainer = styled(LeadsBackgroundContainer)``

export const BannerLeftWrapper = styled.div<{ hasNoForm: boolean, alignment?: 'Left' | 'Center' }>`
  text-align: center;
  max-width: 315px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: ${({ hasNoForm, alignment }) => hasNoForm ? alignment === 'Center' ? '100%' : '750px' : '600px'};
    text-align:  ${({ hasNoForm, alignment }) => hasNoForm && alignment === 'Center' ? 'center' : 'left'};
    margin: ${({ hasNoForm, alignment }) => hasNoForm && alignment === 'Center' ? '0 auto' : '0 58px 0 0'};
  }
`

export const Title = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})<{ $sectionTheme?: SectionThemeKey }>`
  margin-bottom: 15px;
  color: ${({ theme, $sectionTheme }) =>
    $sectionTheme === SectionThemeKey.Dark
      ? theme.colors.white
      : theme.colors.darkBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    margin-bottom: 20px;
  }
`

export const SubCopyContainer = styled.div`
  ${richTextResetStyles}
  ${styledText[TextStyles['Large Paragraph Text']]}
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};

`

export const BannerButton = styled(Button)`
  margin-top: 30px;
  padding: 0 16px;
`
