const COLOR_PALETTE = {
  orange: {
    1: '#FFEFE5',
    2: '#FE590380',
    3: '#FE5903',
    4: '#C53F1F',
  },
  blue: {
    1: '#E5F0FF',
    2: '#1777FF80',
    3: '#EAEDF9',
    4: '#062ABC80',
    5: '#1777FF',
    6: '#062ABC',
    7: '#004B99',
    8: '#0C1A66',
  },
  teal: {
    1: '#E9FAFC',
    2: '#00C9D580',
    3: '#00C9D5',
    4: '#009AB5',
  },
  purple: {
    1: '#EDE5FF',
    2: '#6C36FF80',
    3: '#6C36FF',
    4: '#2F318E',
  },
}

export default COLOR_PALETTE
