import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 32px 0;
  background: ${({ theme }) => theme.colors.surface01};

  ${({ theme }) =>  theme.mediaBreakpointUp.md} {
    padding-top: 40px;
    padding-bottom: 60px;
  }
`

export const List = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 16px;
  justify-content: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: repeat(3, 362px);
    grid-column-gap: 24px;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
  margin-bottom: 30px;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
  }
`
