export enum errorCodes {
  ERROR_404 = '404',
  ERROR_500 = '500',
  ERROR_502 = '502',
  ERROR_503 = '503',
}

export enum QueryErrorCodes {
  ERROR_500 = '500',
  ERROR_422 = '422',
  ERROR_403 = '403',
  ERROR_404 = '404',
}

type QueryErrorMessage = {
  errorCode: QueryErrorCodes
  message: string
}

// TODO: update these inside the /api/query, and create utils for unique ones like 404 string
export const QueryErrorMessages: Record<QueryErrorCodes, QueryErrorMessage> = {
  [QueryErrorCodes.ERROR_500]: {
    errorCode: QueryErrorCodes.ERROR_500,
    message: 'Error: Unknown Server Error'
  },
  [QueryErrorCodes.ERROR_422]: {
    errorCode: QueryErrorCodes.ERROR_422,
    message: 'Unprocessable request: Something went wrong'
  },
  [QueryErrorCodes.ERROR_403]: {
    errorCode: QueryErrorCodes.ERROR_403,
    message: 'Unprocessable request: Access denied'
  },
  [QueryErrorCodes.ERROR_404]: {
    errorCode: QueryErrorCodes.ERROR_404,
    message: ': Not Found'
  },
}
