import { RefObject } from 'react'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { ContentItemInterface } from '@/interfaces/contentItem'
import { Button, AssetRenderer } from '@/components'
import { 
  Container,
  Image,
  ContentContainer,
  TitleHolder,
  Title,
  Description,
  LinkContainer,
  SubTitle,
} from './ImageAndCopy.styles'
import ArrowRight from '../../../../public/images/icons/arrow-right.svg'

interface ITitleProps {
  forwardedRef?: RefObject<HTMLDivElement>
  height?: number
}

const ImageAndCopy: React.FC<ContentItemInterface & ITitleProps> = (props) => {
  const {
    title,
    subTitle,
    description,
    image,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    forwardedRef,
  } = props
  const buttonText = props.buttonText?.trim() || ''
  const height = forwardedRef?.current?.clientHeight

  return (
    <Container hasSubTitle={!!subTitle}>
      <AssetRenderer
        asset={image}
        ImageComponent={Image}
        videoProps={{
          height: { mobile: 180, desktop: 400 },
        }}
      />
      <ContentContainer>
        {
          (title || subTitle) &&
          <TitleHolder minHeight={height}>
            <div ref={forwardedRef}>
              <Title>{title}</Title>
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </div>
          </TitleHolder>
        }
        <Description>{description}</Description>
        {
          buttonLink && (
            <LinkContainer>
              <Button
                variant='link'
                href={buttonLink}
                eventLabel={buttonEventLabel}
                openInNewTab={openInNewTab}
                icon={ArrowRight}
                buttonTheme={SectionThemeKey.Light}
              >
                {buttonText || 'Learn more'}
              </Button>
            </LinkContainer>
          )
        }
      </ContentContainer>
    </Container>
  )
}

export default ImageAndCopy
