enum ContentTypeKeys {
  WrapperContentItems = 'WrapperContentItems',
  Banner = 'Banner',
  ContentItem = 'ContentItem',
  CustomerCard = 'CustomerCard',
  ProductUseCase = 'ProductUseCase',
  Carriers = 'Carriers',
  LeadsWrapper = 'LeadsWrapper',
  Article = 'Article',
  Form = 'Form',
  Category = 'Category',
  CarrierListing = 'CarrierListing',
  Quote = 'Quote',
  ContentFeed = 'ContentFeed',
  EventsSection = 'EventsSection',
  Resources = 'Resources',
  Wrapper = 'Wrapper',
  Glossary = 'Glossary',
  Table = 'Table'
}

export enum RichTextEntries {
  Quote = 'Quote',
  Table = 'Table',
}

export default ContentTypeKeys
