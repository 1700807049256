// Type
import { BannerProps } from '@/interfaces/banner'

// Template
import { AboveTheFold } from './templates'

// Constant
import { UITemplatesBanner } from '@/constants'

const Banner: React.FC<BannerProps & {
  noPadding?: boolean
  noLocalTheme?: boolean
}> = (props) => {
  const { uiTemplate, alignment } = props

  if (alignment === 'Center') {
    return <AboveTheFold {...props} />
  }

  const DefaultUITemplate = AboveTheFold
  const UITemplateBanner = uiTemplate
    ? (UITemplatesBanner[uiTemplate] || DefaultUITemplate)
    : DefaultUITemplate

  return <UITemplateBanner {...props} />
}

export default Banner
