import styled from 'styled-components'

export const BlogHeaderNavigation = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) =>  theme.colors.white};
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 52px;
    top: 91px;
    background: ${({ theme }) =>  theme.colors.surface01};
  }
`

export const BlogHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InsightsLogo = styled.a`
  img {
    width: 87px;
    height: 31px; 
    display: block;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      width: 102px;
      height: 36px;
    }
  }
`
