import { getTokenInfo } from './oauth.fetchers'
import { sendPpFooterNewsLetterSubscription } from './customerContactFetchers.fetchers'
import {
  getFooter,
  getHeaderItem,
  getFullPageSlugs,
  getBlogHeaderItem,
  getFullPageSmallContent,
  getFullPageContent,
  getStickySubscribeBanner,
} from './contentfulAPI.fetchers'
import {
  getArticleContent,
  getAllArticlesRssInfo,
  getInsightsRssInfo,
  getArticleSmallContent,
  getArticleSlugs,
  getThumbnails,
  getRelatedArticleThumbnails,
  getPmArticles,
  getCategorySlugs,
  getCategorySmallContent,
  getCategoryContent,
  getPopups,
  getGlossaryContent,
  getRelatedArticles,
  getGlossarySmallContent,
  getGlossaryDirectoryPage,
  getGlossaryTerms,
  getGlossarySlugs,
} from './contentfulAPI'
import { 
  getCarriers, 
  getPressList, 
  checkAndGetCarrierListingSectionData 
} from './carriers.fetcher'
import { postRecaptcha, postSubmitForm } from './recaptcha.fetchers'
import { validateGoogleToken } from './platoLoginToken.fetcher'
import { 
  getEvents,
  getEventSlug,
  getEventContent,
  getEventSmallContent, 
  getAllResourceSlugs,
} from './events.fetcher'

export {
  getFooter,
  getTokenInfo,
  getHeaderItem,
  getFullPageSlugs,
  getBlogHeaderItem,
  getFullPageSmallContent, 
  getFullPageContent,
  getStickySubscribeBanner,
  sendPpFooterNewsLetterSubscription,
  getArticleContent,
  getAllArticlesRssInfo,
  getInsightsRssInfo,
  getArticleSmallContent,
  getArticleSlugs,
  getThumbnails,
  getRelatedArticleThumbnails,
  getPmArticles,
  getCategorySlugs,
  getCategorySmallContent,
  getCategoryContent,
  getCarriers,
  getPressList,
  checkAndGetCarrierListingSectionData,
  getPopups,
  getGlossaryDirectoryPage,
  getGlossaryTerms,
  getGlossarySlugs,
  postRecaptcha,
  postSubmitForm,
  validateGoogleToken,
  getEvents,
  getEventSlug,
  getEventContent,
  getEventSmallContent,
  getAllResourceSlugs,
  getGlossaryContent,
  getRelatedArticles,
  getGlossarySmallContent,
}
