import styled from 'styled-components'

const BaseInputWrapper = styled.div<{ isInvalid: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: 3px;
  overflow: hidden;

  ${({ isInvalid, theme }) => isInvalid && `
    border-color: ${theme.colors.red};
  `}
`

export default BaseInputWrapper
