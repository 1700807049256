import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'

export const TitleWrapper = styled.div<{ $isOpened: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 24px;

  > svg {
    margin-left: auto;
    transform: ${({ $isOpened }) => $isOpened && 'rotate(-180deg)'};
    transition: 0.5s ease-in-out;
  }
`

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 16px 24px;

  > ${TitleWrapper} + * {
    margin-top: 4px;
  }
`

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 5'],
})`
  color: ${({ theme }) => theme.sectionTheme.text};
`
