import { useRouter } from 'next/router'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import {
  GTMContactFormSubmission,
  GTMFormSubmission,
  GTMLinkedInAdsIdSubmission
} from '@/utils'

import { postSubmitForm } from '@/fetchers'
import { useGlobalState } from '@/hooks'

import { useReCaptcha } from '@/hooks'

const useLeadForm = ({
  zapierHookId,
  eventLabel,
  linkedInAdsId
}: {
  zapierHookId: string
  eventLabel: string | undefined
  linkedInAdsId: number | undefined
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { asPath } = useRouter()

  const {
    handleSubmit,
    reset,
    ...restUseFormReturn
  } = useForm()

  const {
    handleReCaptchaVerify,
  } = useReCaptcha()

  const {
    pageUrl,
    geoInfo,
    utmData,
  } = useGlobalState()

  const handleLeadFormSubmit: SubmitHandler<any> = async (data) => {
    setIsSubmitting(true)
    if (!zapierHookId) {
      return
    }

    const captchaCode = await handleReCaptchaVerify()

    if (!captchaCode) {
      console.error('reCAPTCHA verify failed')
      return
    }

    try {

      let submitData: any = {
        ...data,
        pageUrl,
        city: geoInfo?.city,
        country: geoInfo?.country,
        region: geoInfo?.timezone?.split('/')[0],
      }

      if (utmData) {
        submitData = {
          ...submitData,
          ...utmData,
        }
      }

      if (linkedInAdsId) {
        GTMLinkedInAdsIdSubmission(linkedInAdsId)
      }

      const response = await postSubmitForm({
        data: submitData,
        zapierHookId,
        captchaCode,
      })
      if (response?.ok) {
        setIsSubmitted(true)
        if (eventLabel) GTMFormSubmission(eventLabel)
      }
      
      const pageSlug = asPath.split('?')[0]
      if (['/contact', '/contact-sales'].includes(pageSlug)) {
        GTMContactFormSubmission(data)
      }
    } finally {
      setIsSubmitting(false)
      reset()
    }
  }

  return {
    handleSubmit: handleSubmit(handleLeadFormSubmit),
    isSubmitted,
    isSubmitting,
    ...restUseFormReturn,
  }
}

export default useLeadForm
