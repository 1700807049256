import { theme } from '.'

const colors = theme.colors

const lightTheme = {
  text: colors.darkBlue,
  subText: colors.darkGray,
  lightSubText: colors.lightGray,
  highlightedText: colors.orange,
  hoverText: colors.white,
  background: colors.surface01,
  contrastThemeBackground: colors.darkBlue,
  dividerColor: colors.gray,
  primaryButton: {
    text: colors.white,
    background: colors.orange,
    hoverText: colors.white,
    hoverBackground: colors.darkOrange,
  },
  secondaryButton: {
    lined: {
      text: colors.orange,
      background: colors.orange,
      hoverText: colors.orange,
      hoverBackground: colors.darkBeige,
    },
    link: {
      text: colors.orange,
      hoverText: colors.darkPurple,
    }
  },
  navButton: {
    text: colors.darkBlue,
    hoverText: colors.orange,
    disabledText: colors.silverGray,
  },
}

const darkTheme = {
  text: colors.white,
  subText: colors.white,
  lightSubText: colors.white,
  highlightedText: colors.orange,
  hoverText: colors.orange,
  background: colors.darkBlue,
  contrastThemeBackground: colors.surface01,
  dividerColor: colors.gray,
  primaryButton: {
    text: colors.white,
    background: colors.orange,
    hoverText: colors.white,
    hoverBackground: colors.darkOrange,
  },
  secondaryButton: {
    lined: {
      text: colors.white,
      background: colors.white,
      hoverText: colors.white,
      hoverBackground: colors.darkPurple,
    },
    link: {
      text: colors.white,
      hoverText: colors.orange,
    }
  },
  navButton: {
    text: colors.white,
    hoverText: colors.orange,
    disabledText: colors.silverGray,
  },
}

const whiteTheme = {
  ...lightTheme,
  background: colors.white,
};

const defaultSectionTheme = lightTheme

export {
  lightTheme,
  darkTheme,
  whiteTheme,
  defaultSectionTheme
}
