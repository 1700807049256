import { FullPageContentTypesMapping } from '@/constants'
import ContentTypeKeys from '@/interfaces/contentType'
import { capitalize } from '@/utils'

const PageLayout: React.FC<any> = ({ data }) => {
  return (
    data
      ?.filter((section: any) => section)
      ?.map((section: any, index: number) => {
        const Section = FullPageContentTypesMapping[capitalize(section.__typename) as ContentTypeKeys]

        if (!Section) return null

        return <Section {...section} key={index} />
      })
  )
}

export default PageLayout
