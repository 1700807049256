const colors = {
  orange: '#EA580C',
  orangeHover: '#D14E0B',
  // This orange and beige color is for hover state
  darkOrange: '#CA4A07',
  darkBeige: '#E7DCD7',
  darkBlue: '#001B3A',
  ppBlueHover: '#000E1F',
  lightBlue:'#E5F1FF',
  white: '#FFFFFF',
  white02: 'rgba(255, 255, 255, 0.7)',
  white04: 'rgba(255, 255, 255, 0.32)',
  white05: 'rgba(255, 255, 255, 0.1)',
  darkGray: '#333333',
  lightGray: '#777777',
  gainsboroGray: '#D9D9D9',
  // This gray is for Lead form input placeholder
  lightGray2: '#D6D6D6',
  // This gray is for disabled primary CTA button
  lightGray3: '#B6C4D3',
  silverGray: '#E1E1E1',
  gray: '#CCD1D8',
  basicLightGray: '#D1D3D4',
  // darkPurple is for hover state
  darkPurple: '#1A324E',
  grayBlue: '#334962',
  brightBlue: '#1577FF',
  cadetBlue: '#AFB7C0',
  blue: '#0055CA',
  steelBlue: '#B5C1DB',
  lightGrayBlue: '#596B7F',
  red: '#EA4747',
  chetwodeBlue: '#617BAB',
  hawkesBlue: '#D8DEE9',
  surface01: '#F8F8FC',
  surface02: '#EFF2F6',
  black: 'rgba(0, 0, 0, 1)',
  black01: 'rgba(0, 0, 0, 0.87)',
  black02: 'rgba(0, 0, 0, 0.6)',
  black04: 'rgba(0, 0, 0, 0.2)',
  black05: 'rgba(0, 0, 0, 0.1)',
}

export default colors
