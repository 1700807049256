import React, {
  useMemo, 
  useRef,
  useState 
} from 'react'
import Tag from '@/components/Tag'
import { HeaderItemInterface, MenuItemInterface } from '@/interfaces/headerItem'
import {
  MobileBlogTopicItem,
  MobileBlogTopicList,
  MobileBlogHeaderWrapper 
} from './MobileBlogHeader.styles'

const defaultTopic = { title: 'Topics', link: '/insights' }

const MobileBlogHeader: React.FC<{ data: HeaderItemInterface, activeTopicPath: string}> = ({ data, activeTopicPath }) => {
  const [isOpen, setIsOpen] = useState(false)
  const topicRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const dataItems = data?.sections
  const topics = dataItems?.[0]?.menuLinkItems

  const activeTopic = useMemo(() => {
    return topics?.find((topic: MenuItemInterface) => topic.link === activeTopicPath)
  }, [topics, activeTopicPath])

  return (
    <MobileBlogHeaderWrapper ref={topicRef} isOpen={isOpen}>
      <Tag arrowDirection={isOpen ? 'up' : 'down'} onClick={toggleDropdown}>{activeTopic?.title || defaultTopic.title}</Tag>
      <MobileBlogTopicList>
        <MobileBlogTopicItem
          key='All topics'
          href={defaultTopic.link}
          onClick={() => setIsOpen(false)}
          isActive={activeTopicPath === defaultTopic.link}
        >
          ALL
        </MobileBlogTopicItem>
        {topics?.map((topic: MenuItemInterface) => 
          <MobileBlogTopicItem
            key={topic?.sys?.id} 
            href={topic.link}
            onClick={() => setIsOpen(false)}
            isActive={activeTopicPath === topic.link}
            target={topic.openInNewTab ? '_blank' : '_self'}
          >
            {topic?.title}
          </MobileBlogTopicItem>
        )}
      </MobileBlogTopicList>
    </MobileBlogHeaderWrapper>
  )
}

export default MobileBlogHeader
