import React from 'react'
import DynamicLink from '@/components/DynamicLink'
import { ITopicTag } from '@/interfaces/common'
import { tagGenerator } from '@/utils'
import {
  Tag,
  TagListWrapper,
} from './TagList.styles'

interface TagListProps {
  list: ITopicTag[]
}

const TagList: React.FC<TagListProps> = ({ list }) => {
  return (
    <TagListWrapper>
      {list.map(({
        sys,
        title,
        pageSlug,
      }) => {
        if (!title) return null

        return (
          <DynamicLink
            key={sys?.id}
            href={'/insights' + (pageSlug || '')}
          >
            <Tag>{tagGenerator(title)}</Tag>
          </DynamicLink>
        )
      })}
    </TagListWrapper>
  )
}

export default TagList
