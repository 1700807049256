import { IRichTextDocument } from './common'
import { ISEOFields } from './head'
import { LeadsWrapperProps } from './leadsWrapper'

export enum CategoryTypeKeys {
  Topic = 'Topic',
  Tag = 'Tag',
}

// Refractor later
export enum CategorySlugKeys {
  de = 'de',
  fr = 'fr',
}

export interface CategoryProps {
  sys: { id: string }
  title: string
  pageSlug: string
  type: CategoryTypeKeys
  leadsWrapperSection?: Array<LeadsWrapperProps>
  pmFeedTitle: string
  seoFiller?: IRichTextDocument
  seo?: ISEOFields
}
