import { excludeEmptyItem } from './array.utils'
import { getRelatedArticleThumbnails, getThumbnails } from '@/fetchers'
import { CategoryTypeKeys } from './../interfaces/category'

const sortListByDate = <T = any>(arr?: T[] | null): T[] | null | undefined => {
  if (!arr) return null

  const sortArr = arr?.sort((prev: any, next: any) => {
    const _next = new Date(next?.date).getTime()
    const _prev = new Date(prev?.date).getTime()
    return _prev > _next ? -1 : 1
  })

  return sortArr
}

type GetLatestArticles = (args: {
  isPreviewMode: boolean
  locale: string
  lang: string
  limit?: number
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>
}) => Promise<any>

export const getLatestArticles: GetLatestArticles = async ({
  isPreviewMode,
  locale,
  lang,
  limit = 1000,
  fetcher,
}) => {
  // If articleCollection is empty, the page will only show header+footer
  const articleCollection = await getThumbnails({
    isPreviewMode,
    locale,
    limit,
    lang,
    fetcher,
  })
  if (!articleCollection)
    return null
  const latestArticles = excludeEmptyItem(articleCollection)
  return latestArticles
}

type GetLatestTopicTagRelatedArticles = (args: {
  isPreviewMode: boolean
  locale: string
  type: CategoryTypeKeys
  pageSlug: string
  limit?: number
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>
}) => Promise<any>

export const getLatestTopicTagRelatedArticles: GetLatestTopicTagRelatedArticles = async ({
  isPreviewMode,
  locale,
  type,
  pageSlug,
  limit = 1000,
  fetcher,
}) => {
  const relatedArticles = await getRelatedArticleThumbnails({
    isPreviewMode,
    locale,
    type,
    topicSlug: pageSlug,
    limit,
    fetcher,
  })
  
  if (!relatedArticles)
    return null
  const articleCollection = relatedArticles
  const latestRelatedArticles = sortListByDate(excludeEmptyItem(articleCollection))
  return latestRelatedArticles
}
