// TODO-Typo: NOT UPDATE YET, not sure which font system or where this shows up as a default
import styled from 'styled-components'

export const richTextResetStyles = `
  p,
  ul,
  ol,
  pre {
    margin: 0;
  }

  a {
    color: unset;
  }
`

const defaultSpacing = 22
const defaultOlIndent = 8
const defaultUlIndent = 28

export const BoldText = styled.b`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`
export const ImageContainer = styled.div``

export const Image = styled.img<{
  height: number
  width: number
  hoverable?: boolean
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  ${({ hoverable }) => hoverable && `
    transition: 0.4s;

    &:hover {
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.15);
    }
  `}
`

export const ImageDesc = styled.p`
  margin-top: 16px !important;
  text-align: center;
`

export const VideoContainer = styled.div<{
  noPaddingTop?: boolean
}>`
  position: relative;
  display: block;
  ${({ noPaddingTop }) => !noPaddingTop && 'padding-top: 56.25%;'}
  margin: 36px 0;

  display: flex;
  justify-content: center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 40px 0;
  }
`

export const RichTextContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGray};

  ${richTextResetStyles}

  p, a, li {
    font-size: ${({ theme }) => theme.fontSizes[15]};
    line-height: ${({ theme }) => theme.lineHeight[28]};
  }

  pre {
    height: 28px;
  }

  & ${ImageDesc} {
    display: none;
  }

  a {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    word-break: break-all;
  }

  ul {
    padding-left: ${defaultUlIndent}px; //default
    & > li {
      position: relative;
    }
  }

  & > ol > li > p:first-child > :last-child {
    display: inline-block;
  }

  & > ol > li > p:last-of-type > :last-child {
    display: inline;
  }

  & ol li > :first-child {
    padding-left: ${defaultSpacing}px;
  }

  & ol li:before {
    display: block;
    width: auto; //TODO: Change this to match design
    position: absolute;
  }

  & > ol > li > ol > li {
    &:first-child {
      margin-top: 0;
    }
  }

  & > ol > li {
    &:before,
    > :first-child > :first-child {
      display: inline-block;
    }
  }

  // Update OL markers
  & > ol {
    list-style-type: none;
    counter-reset: first;
    padding-left: 0; //TODO: Change this to move all ol
    p {
      padding-left: ${defaultSpacing + defaultOlIndent}px !important;
    }
    > li > :first-child {
      display: inline;
    }
    > li:before {
      counter-increment: first;
      content: counters(first, '.') '.';
      width: ${defaultSpacing}px;
      text-align: right;
    }
  }

  & > ol > li > ol {
    list-style-type: none;
    counter-reset: second;
    padding-left: ${defaultOlIndent}px; //TODO: Change this to move all 2nd ol
    p {
      padding-left: ${defaultSpacing}px;
    }
    > li:before {
      counter-increment: second;
      content: counter(second, decimal)'.'; //TODO: Change marker of 2nd
    }
  }

  & > ol > li > ol > li ol { //format for all the next lists
    list-style-type: none;
    counter-reset: third;
    padding-left: ${defaultSpacing}px; //TODO: Change this to move all 3rd ol
    p {
      padding-left: ${defaultSpacing}px;
    }
    > li > :first-child {
      padding-left: ${defaultSpacing + 5}px; //TODO: Change this to move all 3rd inner content
    }
    > li:before {
      counter-increment: third;
      content: '('counter(third, lower-latin)')'; //TODO: Change marker of 3rd
      width: auto;
    }
  }

  & > ol > li > ol > li > ol > li ol { //padding for all the next lists
    padding-left: ${defaultSpacing}px;
    > p {
      padding-left: ${defaultSpacing}px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    p, a, li {
      font-size: ${({ theme }) => theme.fontSizes[18]};
      line-height: ${({ theme }) => theme.lineHeight[32]};
    }

    pre {
      height: 32px;
    }
  }
`
