import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import {
  BannerBackgroundContainer,
  DesktopComponentWrapper,
  MobileComponentWrapper,
} from './LeadsPage.styles'
import DesktopWrapper from './components/DesktopWrapper'
import MobileWrapper from './components/MobileWrapper'
import { useWindowSize } from '@/hooks'

const LeadsPage: React.FC<LeadsWrapperProps> = (props) => {
  const { width } = useWindowSize()
  const isDesktop = width > 599 
  return (
    <BannerBackgroundContainer>
      {isDesktop ? (
        <DesktopComponentWrapper>
          <DesktopWrapper {...props} />
        </DesktopComponentWrapper>
      ) : (
        <MobileComponentWrapper>
          <MobileWrapper {...props} />
        </MobileComponentWrapper>
      )}
    </BannerBackgroundContainer>
  )
}

export default LeadsPage
