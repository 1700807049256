import { Document } from '@contentful/rich-text-types'
import { BsCheck } from 'react-icons/bs'

// Component
import {
  CheckboxWrapper,
  Icon,
  Label,
} from './Checkbox.styles'
import { ErrorMessage } from '../../'

// Util
import { documentToReactComponentsWithBr } from '@/utils'

interface ICheckboxProps {
  isChecked?: boolean
  label?: {
    json: Document
  }
  handleClick?: () => void
  errorMessage?: string
}

const Checkbox: React.FC<ICheckboxProps> = ({
  isChecked,
  label,
  handleClick,
  errorMessage,
}) => (
  <>
    <CheckboxWrapper onClick={handleClick}>
      <Icon>{isChecked && <BsCheck />}</Icon>
      {label && (
        <Label>
          {documentToReactComponentsWithBr(label.json)}
        </Label>
      )}
    </CheckboxWrapper>
    <ErrorMessage>{errorMessage}</ErrorMessage>
  </>
)

export default Checkbox
