import React from 'react'
import { ITopicTag } from '@/interfaces/common'
import { Topic, TopicListWrapper } from './TopicList.styles'

interface TopicListProps {
  list: ITopicTag[]
}

const TopicList: React.FC<TopicListProps> = ({ list }) => {
  return (
    <TopicListWrapper>
      {list.map(({ sys, title }) => {
        if (!title) return null

        return (
          // TODO: add DynamicLink for future scope (redirect to glossary filter directory), disable temporarily now
          <Topic
            key={sys?.id}
            style={{ cursor: 'not-allowed' }}
          >
            {title}
          </Topic>
        )
      })}
    </TopicListWrapper>
  )
}

export default TopicList
