// TODO-Typo: DO NOT EDIT Title+SubCopy+ThankYouTitle yet, belongs to Form font design system (not mapped yet)
import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { Button } from '@/components'
import { styledText } from '@/components/Typography/Typography.styles'

export const HubspotFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 315px;
  margin-top: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-width: 315px;
    max-width: 490px;
    margin-top: 0px;
  }

  & > div#form {
    position: absolute;
    top: -80px;
    display: block;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      top: -140px;
    }
  }
`

export const LeadFormWrapper = styled.form<{ isSubmitted: boolean }>`
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;

  & div#form {
    position: absolute;
    top: -80px;
    display: block;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      top: -140px;
    }
  }

  ${({ isSubmitted }) =>
    isSubmitted &&
    `
    position: relative;
    justify-content: center;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 35px 25px;
  }
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[30]};
  line-height: ${({ theme }) => theme.lineHeight[33]};
  margin-bottom: 25px;
  flex: 0 0 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const SubCopy = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[18]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.lineHeight[25]};
  padding: 0 10px;
  margin-bottom: 25px;
  width: 100%;
`

export const ThankYouCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`

export const ThankYouCardContent = styled.div`
  text-align: center;
`

export const ThankYouIcon = styled.img`
  flex: 0 0 70px;
  height: 70px;
  margin-bottom: 30px;
`

export const ThankYouTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[30]};
  line-height: ${({ theme }) => theme.lineHeight[33]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const ThankYouMessage = styled.div`
  ${styledText[TextStyles['Large Paragraph Text']]}
  color: ${({ theme }) => theme.colors.darkGray};

  a {
    color: ${({ theme }) => theme.colors.brightBlue};

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
      text-decoration: underline;
    }

    &:visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const SubmitButton = styled(Button)`
  margin-top: 15px;
`
