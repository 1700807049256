import { PrimaryTitleContainer } from './PrimaryTitle.styles'

import { useHighlightString } from '@/hooks'

// TODO: Remove this component
const PrimaryTitle: React.FC<{
  children?: string
  noMarginBottom?: boolean
}> = ({ 
  children,
  noMarginBottom,
}) => {
  const highlightedChildren = useHighlightString(children)

  if (!children) {
    return null
  }

  // TODO: Move this margin declaration to parent where it belongs
  return <PrimaryTitleContainer noMarginBottom={noMarginBottom}>{highlightedChildren}</PrimaryTitleContainer>
}

export default PrimaryTitle
