import { ReactNode } from 'react'
import Link from 'next/link'

type AnchorProps = {
  href: string
  children: ReactNode
  passHref?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top'
}

const Anchor: React.FC<AnchorProps> = ({
  href,
  passHref = true,
  target = '_blank',
  children,
}) => {
  return (
    <Link
      href={href}
      passHref={passHref}
      target={target}
    >
      {children}
    </Link>
  )
}

export default Anchor
