import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { serverRuntimeConfig } = getConfig()
const SENTRY_DSN = serverRuntimeConfig.sentryDsn

if (SENTRY_DSN)
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: serverRuntimeConfig.sentryEnvironment,
    attachStacktrace: true
  })
