import React, { useState } from 'react'
import {
  autoPlacement,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

import MenuItemList from '../../MenuItemList'
import SupportSection from '../../SupportSection'

import { excludeEmptyItem } from '@/utils'
import { HeaderItemInterface } from '@/interfaces/headerItem'
import { HeaderSectionType } from '@/interfaces/headerSectionType'
import {
  HeaderItem,
  HeaderItemContent,
  HeaderItemContentWrapper,
  StandaloneSectionSeparator,
  SectionWrapper,
} from './HeaderItem.styles'
import HeaderItemTitle from '../HeaderItemTitle'

interface HeaderItemProps {
  headerItem: HeaderItemInterface
}

const HeaderItemComponent: React.FC<HeaderItemProps> = ({ headerItem }) => {
  const collectionItems = headerItem.sections

  const [isOpen, setIsOpen] = useState(false)

  const {
    refs,
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom-start', 'bottom'],
      }),
      shift(),
    ]
  })

  const hover = useHover(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ])

  return (
    <HeaderItem ref={refs.setReference} {...getReferenceProps()}>
      <HeaderItemTitle
        link={headerItem.link}
        title={headerItem.title}
        openInNewTab={headerItem.openInNewTab}
      />
      {isOpen && !headerItem.link && collectionItems?.length > 0 && (
        <HeaderItemContentWrapper
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <HeaderItemContent>
            {excludeEmptyItem(collectionItems)?.map((section: any, index: number) => (
              <SectionWrapper
                key={section.sys?.id}
                displayFlex={section.type === HeaderSectionType.Standalone}
              >
                {section.type === HeaderSectionType.Standalone ? (
                  <>
                    {index === collectionItems.length - 1 && (
                      <StandaloneSectionSeparator />
                    )}
                    <SupportSection
                      title={section.title}
                      content={section.content || ''}
                      icon={section.icon}
                      actionButtonLink={section.actionButtonLink}
                      actionButtonTitle={section.actionButtonTitle}
                      buttonEventLabel={section.buttonEventLabel}
                    />
                  </>
                ) : (
                  <MenuItemList
                    title={section.title}
                    menuItems={section.menuLinkItems}
                  />
                )}
              </SectionWrapper>
            ))}
          </HeaderItemContent>
        </HeaderItemContentWrapper>
      )}
    </HeaderItem>
  )
}

export default HeaderItemComponent
