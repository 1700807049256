import { useState, useEffect } from 'react'

const useWindowSize = (): {
  width: number
  height: number
} => {
  const [size, setSize] = useState(() => ({
    width: 1920,
    height: 1280
  }))

  useEffect(() => {
    const handler = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handler)

    handler()

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return size
}

export default useWindowSize
