import { forwardRef, RefObject } from 'react'
import { UITemplatesContentItem } from '@/constants'
import { ContentItemInterface } from '@/interfaces/contentItem'
import { IconAndCopy } from './Templates'

interface ContentItemProps {
  data: ContentItemInterface
  height?: number
}

const defaultContentItem = IconAndCopy

const ContentItem = forwardRef<HTMLElement, ContentItemProps>((props, forwardedRef) => {
  const { data, height } = props
  const { uiTemplate } = props.data
  const UITemplateContentItem = uiTemplate ? (UITemplatesContentItem[uiTemplate] || defaultContentItem) : defaultContentItem
  const ForwardedRefComponent = forwardRef((props, ref) => (
    <UITemplateContentItem
      {...data} 
      height={height}
      forwardedRef={ref as RefObject<HTMLParagraphElement>}
    />
  ))

  if (height) {
    return (
      <ForwardedRefComponent
        ref={forwardedRef}
      />
    )  
  }

  return (
    <UITemplateContentItem {...data} />
  )
})

export default ContentItem
