import Link from 'next/link'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  height: 40px;
  width: 100%;
  position: relative;
  display: block;
`

export const PressItemWrapper = styled(Link)`
  width: 100%;
  display: block;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all .3s;

  :hover {
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 270px;
  }
`

export const PressHeader = styled.div`
  padding: 20px 30px;
`

export const PressBody = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[15]};
  line-height: ${({ theme }) => theme.lineHeight[20]};
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[18]};
  }
`

export const PressDivide = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.gainsboroGray};
`

export const PressTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 20px;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-height: 54px;
`

export const PressAuthor = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 5px;
`

export const PressDate = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
`
