import { ArticleProps } from '@/interfaces/article'
import { ILanguageFields, ISEOFields } from '@/interfaces/head'
import { DOMAIN_LOCALES } from '@/interfaces/locales'
import callContentful from './base.fetchers'

// Utils
import { articleDataModify } from '@/utils'

// Fragment 
import { seoFields } from '@/fetchers/fragments'

// Get Article slug of all locales
const getArticleSlugs = async (
  isPreviewMode: boolean,
  articleSlug: string,
  currentDomain: string,
  articleSysId: string | undefined,
  articleLang: string,
): Promise<ILanguageFields[] | null> => {
  if (!articleSysId) return null

  const allWebDomains = Object.keys(DOMAIN_LOCALES)
  const filteredWebDomains = allWebDomains.filter(
    (domain) => domain !== currentDomain
  )
  const articleLangSlug = articleLang ? `/${articleLang}` : ''

  const pageLocalesQueries = filteredWebDomains?.map((domain) => {
    return `{
      article(
        preview: ${isPreviewMode},
        id: "${articleSysId}",
        locale: "${DOMAIN_LOCALES[domain].code}"
        ) {
          articleSlug
          articleLang
        }
      }
      `
  })

  const pageLocalesRes = await Promise.all(
    pageLocalesQueries.map((pageLocalesQuery) =>
      callContentful(pageLocalesQuery, isPreviewMode)
    )
  )

  // TODO: check again when .de is setup
  const filteredPageLocaleRes = pageLocalesRes.reduce(
    (validPageLocaleRes, currentValue, index) => {
      if (!currentValue.data?.article) {
        return validPageLocaleRes
      }

      const domain = filteredWebDomains[index]
      const { articleLang, articleSlug } = currentValue.data?.article
      const _articleLangSlug = articleLang ? `/${articleLang}` : ''

      validPageLocaleRes.push({
        pageSlug: `/insights${_articleLangSlug}${articleSlug || ''}`,
        locale: DOMAIN_LOCALES[domain].code,
        domain: domain,
      })

      return validPageLocaleRes
    },
    []
  )

  filteredPageLocaleRes.push({
    pageSlug: `/insights${articleLangSlug}${articleSlug}`,
    domain: currentDomain,
    locale: DOMAIN_LOCALES[currentDomain].code,
  })

  return filteredPageLocaleRes
}

const getArticleContent = async (
  isPreviewMode: boolean,
  articleSlug: string,
  locale: string,
  articleLang: string
): Promise<ArticleProps> => {
  const articleLangFilter = articleLang ? `articleLang: "${articleLang}"` : ''

  const query = `
    {
      articleCollection(
        preview: ${isPreviewMode},
        where: {
          articleSlug: "${articleSlug}"
          ${articleLangFilter}
        },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
          title
          topics: topicsCollection(
            preview: ${isPreviewMode},
            limit: 2,
            locale: "${locale}"
          ) {
            items {
              sys {
                id
              }
              title
              pageSlug
            }
          }
          tags: tagsCollection(
            preview: ${isPreviewMode},
            limit: 7,
            locale: "${locale}"
          ) {
            items {
              sys {
                id
              }
              title
              pageSlug
            }
          }
          date
          heroImage {
            url
            description
          }
          thumbnailCopy
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  contentType
                  url
                  height
                  width
                  title
                  description
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on Quote {
                    quote
                    author
                  }
                }
              }
            }
          }
          seo {
            ...seoFields
          }
        }
      }
    }
    ${seoFields()}
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.articleCollection?.items[0]

  return articleDataModify(data)
}

const getArticleSmallContent = async (
  isPreviewMode: boolean,
  articleSlug: string,
  locale: string,
  articleLang: string,
): Promise<ArticleProps> => {
  const articleLangFilter = articleLang ? `articleLang: "${articleLang}"` : ''

  const query = `
    {
      articleCollection(
        preview: ${isPreviewMode},
        where: {
          articleSlug: "${articleSlug}"
          ${articleLangFilter}
        },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.articleCollection?.items[0]
  return data
}

const getAllArticleSlugs = async ({
  isPreviewMode,
}: {
  isPreviewMode: boolean
}): Promise<string[]> => {
  const query = `
    {
      articleCollection(
        preview: ${isPreviewMode}
        where: { articleSlug_not: "/" }
        limit: 2700
      ) {
        items {
          articleSlug
          articleLang
          seo {
            robots
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)

  return response.data?.articleCollection?.items
    ?.filter((article: { seo: ISEOFields }) => !article.seo?.robots?.includes('noindex'))
    ?.map((article: { articleSlug: string, articleLang?: string }) => article.articleLang ? `/${article.articleLang}${article.articleSlug}` : article.articleSlug)
    ?? []
}

const getAllArticlesRssInfo = async (): Promise<
  ArticleProps[] | null
> => {
  const ARTICLES_RSS_LIMIT = 1000;
  const query = /* GraphQL */ `
    {
      articleCollection(
        preview: false
        # Get En Article only.
        where: { articleLang_exists: false }
        limit: ${ARTICLES_RSS_LIMIT}
      ) {
        items {
          sys {
            id
          }
          title
          articleSlug
          date
          heroImage {
            url(transform: {
              width: 696,
              resizeStrategy: FIT,
            })
          }
          thumbnailCopy
          content {
            json
          }
        }
      }
    }
  `;

  const response = await callContentful(query, false)
  return response.data?.articleCollection?.items?.filter(Boolean) || null
}

export {
  getArticleContent,
  getArticleSmallContent,
  getArticleSlugs,
  getAllArticleSlugs,
  getAllArticlesRssInfo,
}
