import styled from 'styled-components'
import { Container } from '@/components'

export const LeadsContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`

// TODO-Design: button is dark-colored, while theme can be Dark => fix at http://localhost:3000/products/track
export const LeadsBackgroundContainer = styled.div<{ backgroundImage?: string | undefined }>`
  background: ${({ theme, backgroundImage }) => 
    backgroundImage 
      ? `url(${backgroundImage})`
      : theme.sectionTheme
        ? theme.sectionTheme?.background
        : theme.colors.surface01};
  background-size: cover;
  background-position: center center;
`
