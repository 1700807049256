import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import Typography from '@/components/Typography'
import {
  LeadsBackgroundContainer,
  LeadsContainer,
} from '../../components/LeadsWrapper.styles'
import { ImageContainer, RichTextContainer } from '@/components/RichText/RichText.styles'
import { styledText } from '@/components/Typography/Typography.styles'
import ArrowRight from '../../../../public/images/icons/arrow-right.svg'

export const BannerContainer = styled(LeadsContainer)`
  position: relative;
  align-items: flex-start;

  .custom__richtext ${ImageContainer} {
    max-width: 505px;
    display: flex;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export const BannerBackgroundContainer = styled(LeadsBackgroundContainer)`
  background: ${({ theme, backgroundImage }) => backgroundImage ? `url(${backgroundImage})` : theme.colors.surface01};
  position: relative;

  :only-child {
    min-height: calc(100vh - 91px);
  }
`

export const BannerLeftWrapper = styled.div`
  max-width: 315px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 505px;
    margin-right: 58px;
  }
`

export const BannerRightWrapper = styled.div`
  max-width: 315px;
  width: 100%;
  margin-top: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-width: 315px;
    max-width: 490px;
    margin-top: 0px;
  }
`

export const Title = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})<{ $sectionTheme?: SectionThemeKey }>`
  margin-bottom: 30px;
  color: ${({ theme, $sectionTheme }) =>
    $sectionTheme === SectionThemeKey.Dark
      ? theme.colors.white
      : theme.colors.darkBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    margin-bottom: 20px;
  }
`

const innerListIndentWidth = 40
const innerListSpacing = 15
const finalListIndentWidth = 20

export const CustomRichTextContainer = styled(RichTextContainer)`
  &.custom__richtext {
    &, p, a, li {
      ${styledText[TextStyles['Large Paragraph Text']]}
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }

  &.custom__richtext ul {
    list-style-type: none;
    padding-left: 30.5px !important;
    margin-top: 10px;
    margin-block-end: revert;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    &.custom__richtext ul > li {
      margin-bottom: 10px;
    }
  }

  ul li:before {
    content: '';
    left: -20px;
    border-style: solid;
    border-width: 7.5px 0 7.5px 7.5px;
    border-color: transparent transparent transparent ${({ theme }) => theme.colors.darkBlue};
    position: absolute;
    transform: translateY(25%) translateX(-100%);
  }

  &.custom__richtext ol li:before {
    width: ${innerListIndentWidth}px;
  }

  &.custom__richtext ol li > :first-child {
    padding-left: ${innerListIndentWidth + innerListSpacing}px;
  }

  // Update OL markers
  &.custom__richtext > ol {
    list-style-type: decimal;
    counter-reset: first;
    > li > :first-child {
      padding-left: 0px;
    }
    > li:before {
      counter-increment: first;
      content: counters(first, ".")'.';
      opacity: 0;
      visibility: hidden;
    }
  }

  &.custom__richtext > ol > li > ol {
    padding: 0;
    p {
      padding-left: ${innerListIndentWidth + innerListSpacing}px;
    }
    > li:before {
      content: counters(first, ".")'.'counter(second);
    }
  }

  &.custom__richtext > ol > li > ol > li ol { //format for all the next lists
    padding-left: ${innerListIndentWidth + innerListSpacing}px;
    p {
      padding-left: ${innerListIndentWidth + innerListSpacing}px;
    }
    > li > :first-child {
      padding-left: ${finalListIndentWidth + innerListSpacing}px;
    }
    > li:before {
      content: '('counter(third, lower-latin)')';
    }
  }

  &.custom__richtext > ol > li > ol > li > ol > li ol { //padding for all the next lists
    padding-left: ${finalListIndentWidth + innerListSpacing}px;
    > p {
      padding-left: ${finalListIndentWidth + innerListSpacing}px;
    }
  }
`

export const HighlightedSubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.orange};
`

export const ProductImage = styled.img`
  width: 380px;
  display: block !important;
`

export const CustomerLogo = styled.img`
  height: 30px;
  width: auto;
`

export const CustomerLogosWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 0;

  & > ${CustomerLogo} {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  & > ${CustomerLogo}:last-child {
    margin-right: 0px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 10px;
  }
`

export const TinyCopy = styled(Typography).attrs({
  type: 'div',
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.darkBlue};

  & a {
    color: ${({ theme }) => theme.colors.brightBlue};

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
      text-decoration: underline;
    }

    &:visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: ${({ theme }) => theme.lineHeight[18]};
    margin-top: 20px;
  }
`

const buttonWidth = 46
const arrowDistanceFromSlider = 15
const arrowDistanceFromSliderMobile = 10
const imgPaddingMobile = arrowDistanceFromSliderMobile - (arrowDistanceFromSliderMobile / 5 - 1) * 5
const imgPaddingDesktop = arrowDistanceFromSlider - (arrowDistanceFromSlider / 5 - 1) * 5

export const Arrow = styled(ArrowRight)`
  height: 13x;
  fill: ${({ theme }) => theme.colors.darkBlue};
  transition: 0.4s;
`

export const ArrowContainer = styled.div`
  width: ${buttonWidth}px;
  height: ${buttonWidth}px;
  background: transparent;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.silverGray};
  border-radius: 50%;
  opacity: 1;
  z-index: 1;
  transition: 0.4s;

  &:before {
    display: none;
  }

  &:not(.slick-disabled):hover {
    ${Arrow} {
      fill: ${({ theme }) => theme.colors.orange};
    }
  }

  &.slick-disabled {
    cursor: default;

    ${Arrow} {
      fill: ${({ theme }) => theme.colors.silverGray};
    }
  }
`

export const NextArrowContainer = styled(ArrowContainer)`
  right: 0;
`

export const PrevArrowContainer = styled(ArrowContainer)`
  left: 0;

  ${Arrow} {
    transform: scaleX(-1);
  }
`

export const SliderWrapper = styled.div<{ productImagesLength: number }>`
  margin-top: 30px;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-list {
    margin: 0
      ${({ productImagesLength }) => productImagesLength === 1 ? 0 : buttonWidth + arrowDistanceFromSliderMobile - 5}px;
  }

  .slick-slide {
    img {
      padding: 0
        ${({ productImagesLength }) => productImagesLength === 1 ? 0 : imgPaddingMobile}px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 20px;

    .slick-list {
      margin: 0
        ${({ productImagesLength }) => productImagesLength === 1 ? 0 : buttonWidth + arrowDistanceFromSlider - 5}px;
    }

    .slick-slide {
      img {
        padding: 0
          ${({ productImagesLength }) => productImagesLength === 1 ? 0 : imgPaddingDesktop}px;
      }
    }
  }
`

export const DesktopComponentWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`

export const MobileComponentWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`
