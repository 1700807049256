import { HeroImageWrapper, HeroImageImg } from './HeroImage.styles'

interface HeroImageProps {
  alignment?: 'Left' | 'Right' | 'Center'
  className?: string
  src?: string
  alt?: string
}

const HeroImage: React.FC<HeroImageProps> = ({
  alignment,
  className,
  ...restProps
}) => (
  <HeroImageWrapper alignment={alignment || 'Right'} className={className}>
    <HeroImageImg {...restProps} />
  </HeroImageWrapper>
)

export default HeroImage
