import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import ArrowRight from '../../../public/images/icons/angle-right.svg'

export const CarrierPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const CarrierPaginationDescription = styled(Typography).attrs({
  type: 'span',
  variant: TextStyles['Large Paragraph Text'],
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  margin: 0 27.5px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 30px;
  }
`

export const CarrierPaginationCurrentAmount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: 5px;
`

export const CarrierArrowRight = styled(ArrowRight)`
  width: 21px;
  height: 12px;
  cursor: pointer;

  path {
    transform: scale(0.55);
    fill: ${({ theme }) => theme.colors.darkBlue};
  }
`

export const CarrierArrowLeft = styled(CarrierArrowRight)`
  transform: rotate(-180deg);
`

export const CarrierArrowGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 9.6px;

  svg {
    margin: 0 -7px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 30px;
  }
`
