import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
// import CountrySelector from '../CountrySelector'
import { HeaderComponentProps } from '../Header'
import NavigationDropdown from './NavigationDropdown'
import {
  HamburgerButton, 
  HamburgerButtonLine, 
  MobileHeaderWrapper
} from './HeaderMobile.styles'

enum CollapsePanel {
  MENU= 'menu',
  LANGUAGE = 'language'
}

const HeaderMobile:React.FC<HeaderComponentProps> = ({
  headerItems,
  // currentDomain,
}) => {
  const [active, setActive] = useState<string| null>(null)
  const router = useRouter()

  useEffect(() => { 
    !!active && setActive(null)
  }, [router])

  const handleExpand = (id: string | null) => {
    setActive(prev => id === prev ? null : id)
  }

  return (
    <MobileHeaderWrapper>
      {/* --- Language selector */}
      {/** TODO: Khanh - Add Country selector back after go live */}
      {/* <CountrySelector 
        onClick={() => handleExpand(CollapsePanel.LANGUAGE)} 
        isExpanded={active === CollapsePanel.LANGUAGE}
        currentDomain={currentDomain}
      />  */}
      {/* --- Hamburger button  */}
      <HamburgerButton 
        onClick={() => handleExpand(CollapsePanel.MENU)} 
        isExpanded={active === CollapsePanel.MENU}
      >
        <HamburgerButtonLine/>
        <HamburgerButtonLine/>
        <HamburgerButtonLine/>
      </HamburgerButton>
      {/* --- Navigation */}
      <NavigationDropdown
        headerItems={headerItems}
        isExpanded={active === CollapsePanel.MENU}
      />
    </MobileHeaderWrapper>
  )
}

export default HeaderMobile
