// TODO-Typo: Recheck which font system here
import styled, { css } from 'styled-components'

const LinkStyles = css`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brightBlue};

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue};
  }

  :visited {
    color: ${({ theme }) => theme.colors.blue};
  }
`

const CCPAInnerContainer = css`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 18px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeight[16]};

  & a {
    ${LinkStyles}
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 17px 30px;
    font-size: ${({ theme }) => theme.fontSizes[18]};
    line-height: ${({ theme }) => theme.lineHeight[24]};
  }
`

export const CCPAContainer = styled.div`
  #pp_ccpa_inner_container {
    ${CCPAInnerContainer}
  }
`
