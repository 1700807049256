import Image from 'next/image'
import { IPopUpProps } from '@/interfaces/popUp'
import { 
  PopUpContainer, 
  PopUpWrapper, 
  ImageWrapper,
  FormWrapper,
  ClosePopUp,
} from './PopUp.styles'
import usePopUp from './usePopUp.hook'
import Form from '@/components/Form'

//TODO: update correct code in UI ticket
const PopUp: React.FC<{popUp: IPopUpProps}> = ({
  popUp
}) => {
  const { showPopUp, closePopUp } = usePopUp({ popUp })

  return (
    <PopUpWrapper showPopUp={showPopUp} onClick={closePopUp}>
      <PopUpContainer isImage={!!popUp?.image?.url} onClick={(e) => e.stopPropagation()}>
        {popUp?.image && (
          <ImageWrapper>
            <Image
              src={popUp?.image?.url}
              alt={popUp?.image?.description}
              fill
              sizes='100vw'
              style={{
                objectFit: 'cover'
              }}
            />
          </ImageWrapper>
        )}
        <FormWrapper>
          <Form {...popUp?.form} isPopUp={true} />
          <ClosePopUp onClick={closePopUp}/>
        </FormWrapper>
      </PopUpContainer>
    </PopUpWrapper>
  )
}
  
export default PopUp
