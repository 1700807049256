import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { styledText } from '@/components/Typography/Typography.styles'
import ArrowDown from '../../../public/images/icons/angle-down.svg'

export const LocationFilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 315px;
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 410px;
    margin-bottom: 0;
  }
`

export const LocationSelect = styled.div`
  ${styledText[TextStyles['Heading 3']]}
  margin: 0 auto;
  padding: 8px 16.5px 8px 10px;
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.darkBlue};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.darkGray};
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`

export const Arrow = styled(ArrowDown)`
  path {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`

export const LocationOption = styled.div<{ isSelected: boolean }>`
  ${styledText[TextStyles['Large Paragraph Text']]}
  color: ${({ theme }) => theme.colors.darkGray};
  height: 50px;
  padding: 12px 10px 14px 10px;
  transition: .4s;
  font-weight: ${({ theme, isSelected }) => theme.fontWeight[isSelected ? 'bold' : 'regular']};
  
  :hover {
    background: ${({ theme }) => theme.colors.lightBlue};
    cursor: pointer;
  }
`

export const LocationList = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 13px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
  z-index: 100;
  display: ${props => props.isOpen ? 'inline-block' : 'none'};
`

export const LocationSearchInput = styled.input`
  ${styledText[TextStyles['Large Paragraph Text']]}
  width: 100%;
  padding: 13px 10px;
  border: 0;
  outline: none;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.white};

  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`
