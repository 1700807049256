import { SectionThemeKey } from './sectionThemeKey'
export interface CarrierInfoInterface {
  carrier_countries: string[]
  carrier_cs_phone: string
  carrier_id: string
  carrier_logo: string
  carrier_url: string
  carrier_url_tracking: string
  cms_url: string
  has_source_site: boolean
  id: number
  name: string
  timezone_awareness: string
}

export interface CarriersListProps {
  carriersData: CarrierInfoInterface[]
}

export enum ListingType  {
  CARRIER = 'Carrier List',
  PRESS = 'Press List',
}

export interface CarrierListingInterface {
  sys?: { id: string }
  primaryTitle?: string
  secondaryTitle?: string
  type?: ListingType
  theme?: SectionThemeKey
  withSeparator?: boolean
  allCountriesText?: string
  searchCountryInputPlaceholder?: string
  searchInputPlaceholder?: string
  singularCarriersFoundText?: string
  pluralCarriersFoundText?: string
  paginationText?: string
  notFoundMessage?: any
}
