import React from 'react'
import { HeaderItemInterface, MenuItemInterface } from '@/interfaces/headerItem'
import { DesktopBlogHeaderItem, DesktopBlogHeaderList } from './DesktopBlogHeader.styles'
import { excludeEmptyItem } from '@/utils'

const DesktopBlogHeader: React.FC<{ data: HeaderItemInterface, activeTopicPath: string }> = ({ data, activeTopicPath }) => {
  const items = data?.sections
  const listMenu = excludeEmptyItem(items?.[0]?.menuLinkItems)

  return (
    <DesktopBlogHeaderList>
      {listMenu?.map((navItem: MenuItemInterface) => (
        <DesktopBlogHeaderItem  
          href={navItem.link} 
          key={navItem?.sys?.id}
          isActive={activeTopicPath === navItem.link}
          target={navItem.openInNewTab ? '_blank' : '_self'}>
          {navItem.title}
        </DesktopBlogHeaderItem>
      ))}
    </DesktopBlogHeaderList>
  )
}

export default DesktopBlogHeader
