export interface ITable {
  sys?: { id?: string }
  table?: IRow[]
}

export enum NodeType {
  CELL = 'cell',
  ROW = 'row',
}

export enum CellColor {
  BLUE = 'blue',
  WHITE = 'white',
}

export interface ICell {
  nodeType: NodeType
  value: string
  color?: CellColor
  isHeader?: boolean
  rowIndex?: number
  colIndex?: number
  rowSpan?: number
  colSpan?: number
  height?: number
}

export interface IRow {
  nodeType: NodeType
  isHeader: boolean
  content: ICell[]
}
