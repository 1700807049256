import { lightTheme, darkTheme, whiteTheme } from '../constants'
import { SectionThemeKey } from '../interfaces/sectionThemeKey'
import { PageThemeKey } from '@/interfaces/pageTheme.interfaces'
import PAGE_THEME from '@/constants/pageTheme.constants'

type SectionTheme = typeof lightTheme

/**
 * Get sectionTheme based on the inputted theme
 * @param {SectionThemeKey} theme section theme | Dark, Light or White
 * @return {SectionTheme} sectionTheme object used for ThemeProvider
 */
export const getSectionTheme = (theme?: SectionThemeKey):SectionTheme => {
  switch (theme) {
    case SectionThemeKey.Dark:
      return darkTheme;
    case SectionThemeKey.White:
      return whiteTheme;
    case SectionThemeKey.Light:
    default:
      return lightTheme;
  }
}

type PageTheme = typeof PAGE_THEME.Checkout
type GetPageTheme = (theme?: PageThemeKey) => PageTheme | undefined

export const getPageTheme: GetPageTheme = (theme) => {
  switch (theme) {
    case PageThemeKey.PostPurchase:
      return PAGE_THEME.PostPurchase
    case PageThemeKey.Checkout:
      return PAGE_THEME.Checkout
    case PageThemeKey.Returns:
      return PAGE_THEME.Returns
    case PageThemeKey.Logistics:
      return PAGE_THEME.Logistics
    case PageThemeKey.CoPilot:
      return PAGE_THEME.CoPilot
    default:
      return
  }
}
