import { useState } from 'react'
import { useIsomorphicLayoutEffect } from '.'

/**
 * React hook that tracks state of a CSS media query
 *
 * @param queries the media queries to match
 */
const useMediaQuery = (queries: string[]): boolean[] => {
  const isBrowser = typeof window !== 'undefined'
  const isSupported = isBrowser && 'matchMedia' in window

  const [matches, setMatches] = useState<boolean[]>(
    () => {
      if (!isSupported) {
        return queries.map(() => false)
      }

      return queries.map((q) => window.matchMedia(q).matches)
    },
  )

  useIsomorphicLayoutEffect(() => {
    if (!isSupported) {
      return
    }

    const mediaQueryList = queries.map((q) => window.matchMedia(q))

    const listenerList = mediaQueryList.map(() => {
      const listener = () => {
        const isEqual = (previous: boolean[], current: boolean[]): boolean =>
          previous.length === current.length &&
          previous.every((element, idx) => element === current[idx])

        const currentMatches = mediaQueryList.map((mediaQuery) => mediaQuery.matches)

        if (!isEqual(matches, currentMatches)) {
          setMatches(currentMatches)
        }
      }

      window.addEventListener('resize', listener)

      listener()

      return listener
    })

    return () => {
      mediaQueryList.forEach((_, index) => {
        window.removeEventListener('resize', listenerList[index])
      })
    }
  }, [queries])

  return matches
}

export default useMediaQuery
