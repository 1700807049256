// Type
import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'

// Template
import { LeadGenBanner } from './templates'

// Constant
import { UITemplatesLeadsWrapper } from '@/constants'

const LeadsWrapper: React.FC<LeadsWrapperProps> = (props) => {
  const { uiTemplate } = props
  const defaultUITemplate = LeadGenBanner
  const UITemplateBanner = uiTemplate
    ? (UITemplatesLeadsWrapper[uiTemplate] || defaultUITemplate)
    : defaultUITemplate

  return <UITemplateBanner {...props} />
}

export default LeadsWrapper
