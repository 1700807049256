import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import LeadForm from '@/components/Form/templates/LeadForm'
import {
  BannerContainer,
  BannerLeftWrapper,
  BannerRightWrapper,
} from '../LeadsPage.styles'
import LeadsTopWrapper from './LeadsTopWrapper'
import LeadsBottomWrapper from './LeadsBottomWrapper'

const DesktopWrapper: React.FC<LeadsWrapperProps> = (props) => {
  const { leadsForm } = props

  return (
    <BannerContainer>
      <BannerLeftWrapper>
        <LeadsTopWrapper {...props} />
        <LeadsBottomWrapper {...props} />
      </BannerLeftWrapper>

      {/* FORM DESKTOP */}
      {
        leadsForm &&
          <BannerRightWrapper>
            <LeadForm {...leadsForm} />
          </BannerRightWrapper>
      }
    </BannerContainer>
  )
}

export default DesktopWrapper
