import dayjs from 'dayjs'
import React from 'react'
import {
  PressItemWrapper,
  ImageWrapper,
  PressHeader,
  PressBody,
  PressTitle,
  PressDivide,
  PressAuthor,
  PressDate,
} from './PressItem.styles'
import Image from 'next/image'

interface PressItemProps {
  publicationName: string
  publicationLogo?: {
    url: string
    description: string
  }
  articleTitle: string
  authorName?: string
  link: string
  date: string
}

const PressItem: React.FC<PressItemProps> = ({
  publicationName,
  publicationLogo,
  articleTitle,
  authorName,
  link,
  date,
}) => {
  return (
    <PressItemWrapper href={link} target='_blank'>
      <PressHeader>
        {publicationLogo?.url && (
          <ImageWrapper>
            <Image
              src={publicationLogo.url}
              alt={publicationLogo.description}
              quality={100}
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
                objectPosition: 'left'
              }}
            />
          </ImageWrapper>
        )}
      </PressHeader>
      <PressDivide />
      <PressBody>
        <PressTitle>{articleTitle}</PressTitle>
        <PressAuthor>{authorName || publicationName}</PressAuthor>
        <PressDate>{dayjs(date).format('MMM DD, YYYY')}</PressDate>
      </PressBody>
    </PressItemWrapper>
  )
}

export default PressItem
