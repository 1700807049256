import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// Template
import { Animated } from './templates'

// Constant
import { UITemplatesCarrier } from '@/constants'

// Type
import { CarrierProps } from '@/interfaces/carrier'

const DefaultUITemplate = Animated

const Carrier: React.FC<CarrierProps> = (props) => {
  const { uiTemplate } = props
  const UITemplate = uiTemplate
    ? (UITemplatesCarrier[uiTemplate] || DefaultUITemplate)
    : DefaultUITemplate

  return <UITemplate {...props} />
}

export default Carrier
