import Image from 'next/image'
import { useMemo } from 'react'
import CarrierInfo from './CarrierInfo'
import {
  CarrierAmount,
  CarriersFilters,
  CarriersListWrapper,
  CarrierAmountDescription,
  CarrierList,
  CarrierAmountWrapper,
  PressList,
} from './CarriersList.styles'
import { CarrierInfoInterface, ListingType } from '@/interfaces/carriers'
import CarrierSearchInput from './CarrierSearch'
import CarrierPagination from './CarrierPagination'
import LocationFilter from './LocationFilter'
import useCarriers from './CarrierList.hook'
import NotFound from './NotFound'
import ppLoading from '../../public/images/pp-loading.gif'
import PressItem from './PressItem'
import { IPress } from '@/interfaces/press'

const generateSquareLogo = (logo: string) =>
  logo &&
  `https://assets.parcelperform.com/pp-web-app-assets/logo-carriers/square-logo-carries/${logo
    .split(/[/]+/)
    .pop()}`

interface CarrierListProps {
  dataList?: any[]
  type?: ListingType
  allCountriesText?: string
  searchCountryInputPlaceholder?: string
  searchInputPlaceholder?: string
  singularCarriersFoundText?: string
  pluralCarriersFoundText?: string
  paginationText?: string
  notFoundMessage?: any
}

const CarriersList: React.FC<CarrierListProps> = ({
  dataList,
  type,
  allCountriesText,
  searchCountryInputPlaceholder,
  searchInputPlaceholder,
  singularCarriersFoundText,
  pluralCarriersFoundText,
  paginationText,
  notFoundMessage,
}) => {
  // This is temporary. Once we get the update, we will need to display the carrier again -> remove this filter
  const dataListFiltered = useMemo(() => {
    return dataList?.filter((carrier) => carrier?.carrier_id !== 'katodo')
  }, [dataList])

  const {
    carriersData,
    isFirstLoad,
    totalPages,
    total,
    currentAmount,
    isCarrier,
    onFilter,
    onChangePage,
    onSearch,
  } = useCarriers({ dataList: dataListFiltered, type })

  const renderPagination = () => {
    if (totalPages > 1)
      return <CarrierPagination
        total={total}
        paginationText={paginationText}
        currentAmount={currentAmount}
        onChange={onChangePage}
      />
  }

  const renderSpinner = () => {
    return (
      <Image
        src={ppLoading}
        alt='Spinner'
        width={100}
        height={100}
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
      />
    )
  }

  const renderCarrierContent = () => {
    if (total < 1)
      return <NotFound message={notFoundMessage} isCarrier={isCarrier} />
    return (
      <>
        <CarrierList>
          {carriersData?.map((carrier: CarrierInfoInterface) => (
            <CarrierInfo
              key={carrier.id}
              logo={generateSquareLogo(carrier.carrier_logo)}
              name={carrier.name}
              trackingPageUrl={carrier.cms_url}
            />
          ))}
        </CarrierList>
        {renderPagination()}
      </>
    )
  }
    
  const renderPressContent = () => {
    if (total < 1)
      return <NotFound message={notFoundMessage} isCarrier={isCarrier} />
    return (
      <>
        <PressList>
          {carriersData?.map((carrier: IPress) => (
            <PressItem
              key={carrier.sys?.id}
              publicationName={carrier.publicationName}
              publicationLogo={carrier.publicationLogo}
              articleTitle={carrier.articleTitle}
              authorName={carrier.authorName}
              link={carrier.link}
              date={carrier.date}
            />
          ))}
        </PressList>
        {renderPagination()}
      </>
    )
  }
    
  return (
    <>
      <CarriersFilters>
        <LocationFilter
          onFilter={onFilter}
          type={type}
          allCountriesText={allCountriesText}
          searchCountryPlaceholder={searchCountryInputPlaceholder}
        />
        <CarrierSearchInput
          onSearch={onSearch}
          searchPlaceholder={searchInputPlaceholder}
        />
        {isCarrier && (
          <CarrierAmountWrapper>
            <CarrierAmount>{total}</CarrierAmount>
            <CarrierAmountDescription>
              {total == 1
                ? singularCarriersFoundText || 'Carrier found'
                : pluralCarriersFoundText || 'Carriers found'}
            </CarrierAmountDescription>
          </CarrierAmountWrapper>
        )}
      </CarriersFilters>
      {isCarrier ? (
        <CarriersListWrapper>
          {isFirstLoad ? renderSpinner() : renderCarrierContent()}
        </CarriersListWrapper>
      ) : (
        <>{isFirstLoad ? renderSpinner() : renderPressContent()}</>
      )}
    </>
  )
}

export default CarriersList
