import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const Image = styled.img`
  object-fit: contain;
  object-position: center center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    object-position: left center;
  }
`

export const AssetWrapper = styled.div`
  ${Image}, > .dotlottie-container {
    width: 50px;
    height: 50px;
    display: block;
    flex: 0 0 auto;
    margin: 0 auto;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin: unset;
    }
  }
`

export const TitleHolder = styled.div<{ minHeight: number | undefined }>`
  margin-top: 10px;
  margin-bottom: 5px;
  height: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
    margin: 12px 0;
  }
`

// TODO-Design: Figma is left-align, current is center-align
export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
  min-height: 1px;
  word-break: break-word;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    text-align: unset;
  }
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  flex: auto;
  text-align: center;
  color: ${({ theme }) => theme.sectionTheme?.subText || defaultSectionTheme.subText};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    text-align: unset;
  }
`

export const LinkContainer = styled.div`
  margin-top: 10px;
  display: block;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 40px;
    text-align: unset;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
  background-color: ${({ theme }) => theme.sectionTheme?.background || defaultSectionTheme.background};
  
  ${({ theme }) => theme.mediaBreakpointUp.sm} {
    width: 315px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: unset;
  }
`
