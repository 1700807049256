import { LOCAL_COOKIE, LOCAL_HAS_VISITED } from '@/constants'

export const setCookieBannerClosed = ():void => {
  if (typeof window !== 'undefined' && !localStorage.getItem(LOCAL_COOKIE))
    localStorage.setItem(LOCAL_COOKIE, '1')
}

export const getCookieBannerClosed = ():string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(LOCAL_COOKIE)
  }
  return null
}

export const setHasVisited = ():void => {
  if (typeof window !== 'undefined' && !localStorage.getItem(LOCAL_HAS_VISITED))
    localStorage.setItem(LOCAL_HAS_VISITED, '1')
}

const getHasVisited = ():string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(LOCAL_HAS_VISITED)
  }
  return null
}

const hasQuantcast = () => {
  return document.getElementById('qc-cmp2-container')
}

export const showCookieBannerPopup = ():boolean => {
  const hasVisited = getHasVisited()
  const cookieBannerClosed = getCookieBannerClosed()

  if (hasQuantcast()) return false
  if (hasVisited === '1' && cookieBannerClosed === '1') return false
  return true
}
