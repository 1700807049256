import { FC, useState } from 'react'
import { ArticleProps } from '@/interfaces/article'
import { ContentFeedInterface } from '@/interfaces/contentFeed'
import {
  Container,
  Thumbnail,
  LoadMoreButton 
} from '@/components'
import {
  PrimaryTitle,
  SecondaryTitle,
  WrapperContentItemsContainer 
} from '../WrapperContentItems/components'
import {
  CategoryContainer,
  ThumbnailsWrapper,
  ThumbnailsItemListWrapper,
} from '../Category/Category.styles'
import { ContentFeedWrapper } from './ContentFeed.styles'

const COUNT = 3

const ContentFeed: FC<ContentFeedInterface> = ({
  primaryTitle, 
  secondaryTitle, 
  withSeparator,
  contentFeed,
  showDate,
  showButton
}) => {
  const [thumbnailList, setThumbnailList] = useState(contentFeed?.slice(0, COUNT) || [])

  const handleLoadMore = () =>  {
    if (!contentFeed?.length) return

    setThumbnailList(prev => prev.concat(
      contentFeed.slice(
        thumbnailList.length,
        thumbnailList.length + COUNT
      )
    ))
  }

  return (
    <ContentFeedWrapper>
      <WrapperContentItemsContainer withSeparator={withSeparator}>
        <Container>
          {secondaryTitle && (
            <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
          )}
          <PrimaryTitle noMarginBottom>{primaryTitle}</PrimaryTitle>
        </Container>
        <ThumbnailsWrapper>
          <CategoryContainer>
            <ThumbnailsItemListWrapper>
              {thumbnailList?.map(
                (articleThumbnail: ArticleProps, index: number) => 
                  <Thumbnail
                    key={index}
                    {...articleThumbnail}
                    date={showDate ? articleThumbnail.date : ''}
                    thumbnailCopy=''
                  />
              )}
            </ThumbnailsItemListWrapper>
            {contentFeed?.length && showButton && thumbnailList?.length < contentFeed?.length &&
              <LoadMoreButton onClick={handleLoadMore}>
                Load Older Posts
              </LoadMoreButton>
            }
          </CategoryContainer>
        </ThumbnailsWrapper>
      </WrapperContentItemsContainer>
    </ContentFeedWrapper>
  )
}

export default ContentFeed
