import dayjs from 'dayjs'
import { excludeEmptyItem, truncateTextWithEllipsis } from '@/utils'
import { ArticleProps } from '@/interfaces/article'
import { DynamicLink } from '@/components'
import {
  FeaturedArticleContainer,
  HeroImageLinkContainer,
  HeroImage,
  ThumbnailContentContainer,
  Title,
  SubCopy,
  CreatedDate,
  ThumbnailItemList,
  ThumbnailTopicName,
  FeaturedButtonMobile,
  FeaturedButtonDesktop
} from './FeaturedArticle.styles'

const FeaturedArticle: React.FC<ArticleProps> = (props) => {
  const {
    title,
    articleSlug,
    articleLang,
    topics,
    thumbnailCopy,
    date,
    heroImage,
  } = props
  const topicList = excludeEmptyItem(topics)
  const articleLangSlug = articleLang ? `/${articleLang}` : ''

  return (
    <FeaturedArticleContainer>
      <HeroImageLinkContainer
        href={`/insights${articleLangSlug}${articleSlug || ''}`}
        style={{ display: 'inline-block' }}
      >
        {heroImage && <HeroImage alt={heroImage.description} src={heroImage.url} />}
        <FeaturedButtonMobile variant='secondary'>
          Read More
        </FeaturedButtonMobile>
      </HeroImageLinkContainer>
      <ThumbnailContentContainer>
        <ThumbnailItemList>
          {topicList?.map((item: any) => (
            <DynamicLink
              href={'/insights' + (item.pageSlug || '')}
              key={item.sys?.id}
            >
              <ThumbnailTopicName>
                {item.title}
              </ThumbnailTopicName>
            </DynamicLink>
          ))}
        </ThumbnailItemList>
        <DynamicLink href={`/insights${articleLangSlug}${articleSlug || ''}`} style={{ display: 'inline-block' }}>
          {title && <Title>{truncateTextWithEllipsis(title, 102)}</Title>}
          {thumbnailCopy && <SubCopy>{truncateTextWithEllipsis(thumbnailCopy, 120)}</SubCopy>}
        </DynamicLink>
        {date && <CreatedDate>{dayjs(date).format('MMM DD, YYYY')}</CreatedDate>}
        <FeaturedButtonDesktop
          variant='secondary'
          href={`/insights${articleLangSlug}${articleSlug || ''}`}
        >
          Read More
        </FeaturedButtonDesktop>
      </ThumbnailContentContainer>
    </FeaturedArticleContainer>
  )
}

export default FeaturedArticle
