import { CategoryProps } from '@/interfaces/category'
import { ILanguageFields, ISEOFields } from '@/interfaces/head'
import { DOMAIN_LOCALES } from '@/interfaces/locales'
import callContentful from './base.fetchers'

// Utils
import { categoryDataModify, getFormattedPageSlug } from '@/utils'

// Fragment 
import { leadsWrapperFields, seoFields } from '@/fetchers/fragments'

// TODO: all the getSlugs are the same, beside the type at line 23, and maybe 28 => put into base.fetchers.ts later
// Get Category slug of all locales
const getCategorySlugs = async (
  isPreviewMode: boolean,
  pageSlug: string,
  currentDomain: string,
  sysId: string | undefined
): Promise<ILanguageFields[] | null> => {
  if (!sysId) return null

  const allWebDomains = Object.keys(DOMAIN_LOCALES)
  const filteredWebDomains = allWebDomains.filter(
    (domain) => domain !== currentDomain
  )

  const pageLocalesQueries = filteredWebDomains?.map((domain) => {
    return `{
        category(
          preview: ${isPreviewMode},
          id: "${sysId}",
          locale: "${DOMAIN_LOCALES[domain].code}"
        ) {
          pageSlug
        }
      }
      `
  })

  const pageLocalesRes = await Promise.all(
    pageLocalesQueries.map((pageLocalesQuery) =>
      callContentful(pageLocalesQuery, isPreviewMode)
    )
  )

  const filteredPageLocaleRes = pageLocalesRes.reduce(
    (validPageLocaleRes, currentValue, index) => {
      if (!currentValue.data?.category) {
        return validPageLocaleRes
      }

      const domain = filteredWebDomains[index]

      validPageLocaleRes.push({
        pageSlug: `/insights${getFormattedPageSlug(
          currentValue.data?.category?.pageSlug
        )}`,
        locale: DOMAIN_LOCALES[domain].code,
        domain: domain,
      })

      return validPageLocaleRes
    },
    []
  )


  filteredPageLocaleRes.push({
    pageSlug: `/insights${getFormattedPageSlug(pageSlug)}`,
    domain: currentDomain,
    locale: DOMAIN_LOCALES[currentDomain].code,
  })

  return filteredPageLocaleRes
}

// TODO: add other fields when needed for testing
const getCategoryContent = async (
  isPreviewMode: boolean,
  pageSlug: string,
  locale: string
): Promise<CategoryProps> => {
  const query = `
    {
      categoryCollection(
        preview: ${isPreviewMode},
        where: { pageSlug: "${pageSlug}" },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
          pageSlug
          title
          type
          seo {
            ...seoFields
          }
          pmFeedTitle
          seoFiller {
            json
          }
          leadsWrapperSection: leadsWrapperSectionCollection(
            preview: ${isPreviewMode},
            locale: "${locale}",
            limit: 2,
          ) {
            items {
              ... leadsWrapperFields
            }
          }
        }
      }
    }
    ${seoFields()}
    ${leadsWrapperFields(isPreviewMode, 700)}
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.categoryCollection?.items[0]
  
  return categoryDataModify(data)
}

const getCategorySmallContent = async (
  isPreviewMode: boolean,
  pageSlug: string,
  locale: string
): Promise<CategoryProps> => {
  const query = `
    {
      categoryCollection(
        preview: ${isPreviewMode},
        where: { pageSlug: "${pageSlug}" },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.categoryCollection?.items[0]
  return data
}

const getAllCategorySlugs = async ({
  isPreviewMode,
}: {
  isPreviewMode: boolean
}): Promise<string[]> => {
  const query = `
    {
      categoryCollection(
        preview: ${isPreviewMode}
        where: { pageSlug_not: "/" }
        limit: 2700
      ) {
        items {
          pageSlug
          seo {
            robots
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)

  return response.data?.categoryCollection?.items
    ?.filter((category: { seo: ISEOFields }) => !category.seo?.robots?.includes('noindex'))
    ?.map((category: { pageSlug: string }) => category.pageSlug)
    ?? []
}

export const getInsightsRssInfo = async (): Promise<ISEOFields | null> => {
  const query = /* GraphQL */ `
    {
      categoryCollection(
        preview: false, 
        where: { pageSlug: "/" }
      ) {
        items {
          identifier
          seo {
            title
            description
            ogUrl
          }
        }
      }
    }
  `;

  const response = await callContentful(query, false);
  return response.data?.categoryCollection.items?.filter(Boolean)?.[0]?.seo || null;
}

export {
  getCategorySlugs,
  getCategorySmallContent,
  getCategoryContent,
  getAllCategorySlugs,
}
