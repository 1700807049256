import dayjs from 'dayjs'
import getConfig from 'next/config'
import { IPmArticle } from '@/interfaces/article'
import DynamicLink from '../DynamicLink'
import {
  PmArticleContainer,
  HeroImage,
  Title,
  CreatedDate,
} from './PmArticle.styles'

const PmArticle: React.FC<IPmArticle> = ({
  title,
  articleType,
  slug,
  date,
  image,
}) => {
  const { publicRuntimeConfig } = getConfig()

  return (
    <DynamicLink
      isOpenNewTab
      style={{ display: 'inline-block' }}
      href={`https://${publicRuntimeConfig.pmcDomain}/${articleType}/${slug}`}
    >
      <PmArticleContainer>
        {image && <HeroImage alt={image.description} src={image.url} />}
        {title && <Title>{title}</Title>}
        {date && (
          <CreatedDate>{dayjs(date).format('MMM DD, YYYY')}</CreatedDate>
        )}
      </PmArticleContainer>
    </DynamicLink>
  )
}

export default PmArticle
