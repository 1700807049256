import dynamic from 'next/dynamic'

// UI-Template Keys
import { WrapperUITemplateKeys } from '@/interfaces/wrapperContent'
import { ContentItemUITemplateKeys } from '@/interfaces/contentItem'
import { BannerUITemplateKeys } from '@/interfaces/banner'
import { LeadsWrapperUITemplateKeys } from '@/interfaces/leadsWrapper'
import { CarrierUITemplateKeys } from '@/interfaces/carrier'
import React from 'react'
import { FormUITemplateKeys } from '@/interfaces/form'

export const UITemplatesWrapper: {
  [key in WrapperUITemplateKeys]?: React.ComponentType<any>
} = {
  [WrapperUITemplateKeys.MultipleIconAndCopySection]: dynamic(() => import('../components/WrapperContentItems/Templates/MultipleIconAndCopy')),
  [WrapperUITemplateKeys.MultipleImageAndCopySection]: dynamic(() => import('../components/WrapperContentItems/Templates/MultipleImageAndCopy')),
  [WrapperUITemplateKeys.CustomerNavigationTab]: dynamic(() => import('../components/WrapperContentItems/Templates/CustomerNavigationTab')),
  [WrapperUITemplateKeys.ProductFeaturesList]: dynamic(() => import('../components/WrapperContentItems/Templates/ProductFeatures')),
  [WrapperUITemplateKeys.SliderForCards]: dynamic(() => import('../components/WrapperContentItems/Templates/SliderForCards')),
  [WrapperUITemplateKeys.Statistics]: dynamic(() => import('../components/WrapperContentItems/Templates/MultipleStatistics')),
  [WrapperUITemplateKeys.ProductSuiteTabs]: dynamic(() => import('../components/WrapperContentItems/Templates/ProductSuiteTabs')),
  [WrapperUITemplateKeys.Grid]: dynamic(() => import('../components/WrapperContentItems/Templates/Grid')),
  [WrapperUITemplateKeys.CareersJobs]: dynamic(() => import('../components/WrapperContentItems/Templates/CareersJobs')),
  [WrapperUITemplateKeys.FAQs]: dynamic(() => import('../components/WrapperContentItems/Templates/FAQs')),
}

export const UITemplatesContentItem: {
  [key in ContentItemUITemplateKeys]?: React.ComponentType<any>
} = {
  [ContentItemUITemplateKeys.IconAndCopy]: dynamic(() => import('../components/ContentItem/Templates/IconAndCopy')),
  [ContentItemUITemplateKeys.ImageAndCopy]: dynamic(() => import('../components/ContentItem/Templates/ImageAndCopy')),
  [ContentItemUITemplateKeys.SmallImageAndCopy]: dynamic(() => import('../components/ContentItem/Templates/SmallImageAndCopy')),
  [ContentItemUITemplateKeys.IconAndCopyInCard]: dynamic(() => import('../components/ContentItem/Templates/IconAndCopyInCard')),
  [ContentItemUITemplateKeys.Statistics]: dynamic(() => import('../components/ContentItem/Templates/Statistics')),
  [ContentItemUITemplateKeys.StatisticsAndIcon]: dynamic(() => import('../components/ContentItem/Templates/StatisticsAndIcon')),
  [ContentItemUITemplateKeys.Grid]: dynamic(() => import('../components/ContentItem/Templates/GridItem')),
  [ContentItemUITemplateKeys.DescriptiveBanner]: dynamic(() => import('../components/ContentItem/Templates/DescriptiveBanner')),
}

export const UITemplatesBanner: {
  [key in BannerUITemplateKeys]?: React.ComponentType<any>
} = {
  [BannerUITemplateKeys.AboveTheFold]: dynamic(() => import('../components/Banner/templates/AboveTheFold')),
  [BannerUITemplateKeys.ProductSuiteContent]: dynamic(() => import('../components/Banner/templates/ProductSuite'))
}

export const UITemplatesLeadsWrapper: {
  [key in LeadsWrapperUITemplateKeys]?: React.ComponentType<any>
} = {
  [LeadsWrapperUITemplateKeys.LeadGenBanner]: dynamic(() => import('../components/LeadsWrapper/templates/LeadGenBanner')),
  [LeadsWrapperUITemplateKeys.LeadsPage]: dynamic(() => import('../components/LeadsWrapper/templates/LeadsPage')),
  [LeadsWrapperUITemplateKeys.RichTextParagraph]: dynamic(() => import('../components/LeadsWrapper/templates/RichTextParagraph')),
}

export const UITemplatesCarrier: {
  [key in CarrierUITemplateKeys]?: React.ComponentType<any>
} = {
  [CarrierUITemplateKeys.Animated]: dynamic(() => import('../components/Carrier/templates/Animated')),
  [CarrierUITemplateKeys.Static]: dynamic(() => import('../components/Carrier/templates/Static')),
}

export const UITemplatesForm: {
  [key in FormUITemplateKeys]?: React.ComponentType<any>
} = {
  [FormUITemplateKeys.LeadsForm]: dynamic(() => import('../components/Form/templates/LeadForm')),
  [FormUITemplateKeys.StickySubscribeBanner]: dynamic(() => import('../components/Form/templates/StickySubscribeBanner')),
}
