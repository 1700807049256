import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Container from '@/components/Container'
import Typography from '@/components/Typography'
import { StatisticsContainer } from '@/components/ContentItem/Templates/StatisticsAndIcon/StatisticsAndIcon.styles'

export const WrapperContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;

  > a {
    margin-top: 30px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 350px;
    margin-bottom: 0;
  }
`

export const StatContainer = styled.div<{ hasBelowThreeItems?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: -35px -35px 0 0;

  ${StatisticsContainer} {
    margin: 35px 35px 0 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ hasBelowThreeItems }) => hasBelowThreeItems && `
      justify-content: flex-end;
    `}
    margin: -50px -50px 0 68px;

    ${StatisticsContainer} {
      margin: 50px 50px 0 0;
    }
  }
`

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text};
`
