import dayjs from 'dayjs'
import get from 'lodash.get'

type SortDatesByClosest = <TArrayElement extends Record<string, any>>(
  arrayOfDates: TArrayElement[],
  closest?: Date,
  sortByField?: string,
) => TArrayElement[]

/**
 * @param arrayOfDates array with item format {date: string}
 * @param closest closest date, default is current date
 */
export const sortDatesByClosest: SortDatesByClosest = (arrayOfDates, closest = new Date(), sortByField = 'startDate') => {
  const closestDate: number = new Date(closest).getTime()

  return arrayOfDates.sort((a, b) => {
    const aToDate = new Date(a?.[sortByField] as string).getTime()
    const bToDate = new Date(b?.[sortByField] as string).getTime()
    return Math.abs(aToDate - closestDate) - Math.abs(bToDate - closestDate)
  })
}

/**
 *
 * @param date - Date string
 * @returns Date string in format: `Thu, 01 Jan 1970 00:00:00 GMT`
 */
export const convertISOtoUTC = (date: string): string => {
  const utcDate = new Date(date)
  return utcDate.toUTCString()
}

type SortDatesBy = <TArrayElement extends Record<string, any>>(args: {
  arrayOfDates: TArrayElement[]
  sortByField?: string
  sortType?: 'ASC' | 'DESC'
}) => TArrayElement[]

export const sortDatesBy: SortDatesBy = ({
  arrayOfDates,
  sortByField = 'startDate',
  sortType = 'DESC',
}) => {
  return arrayOfDates.sort((firstElement, secondElement) => {
    const firstElementTime = new Date(get(firstElement, sortByField)).getTime()
    const secondElementTime = new Date(get(secondElement, sortByField)).getTime()

    return sortType === 'DESC'
      ? secondElementTime - firstElementTime
      : firstElementTime - secondElementTime
  })
}

export const getCurrentYear = (): number => new Date().getFullYear()

export const startAndEndDateFormat = (startDate: string, endDate: string | undefined): string => {
  if (endDate) {
    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()

    if (end - start > 0) {
      if (dayjs(startDate).format('YYYY') !== dayjs(endDate).format('YYYY')) // diff year
        return dayjs(startDate).format('MMM DD, YYYY') + ' - ' + dayjs(endDate).format('MMM DD, YYYY')

      if (dayjs(startDate).format('MMM') !== dayjs(endDate).format('MMM')) // diff month
        return (dayjs(startDate).format('MMM DD') + ' - ' + dayjs(endDate).format('MMM DD, YYYY'))

      if (dayjs(startDate).format('DD') !== dayjs(endDate).format('DD')) // diff day
        return (dayjs(startDate).format('MMM DD') + ' - ' + dayjs(endDate).format('DD, YYYY'))

      return (dayjs(startDate).format('MMM DD, YYYY'))
    }
  }

  return dayjs(startDate).format('MMM DD, YYYY')
}
