import { IEvent } from '@/interfaces/eventsSection'
import { DOMAIN_LOCALES } from '@/interfaces/locales'
import { SUB_RESOURCE_PAGES } from '@/constants'
import { fullPageDataModify, minifyQuery } from '@/utils'
import callContentful, { callPublicContentful } from './contentfulAPI/base.fetchers'

// Fragment
import {
  bannerFields,
  carriersFields,
  contentFeedFields,
  contentItemFields,
  customerCardFields,
  productUseCaseFields,
  wrapperContentItemsFields,
  leadsWrapperFields,
  carrierListingFields,
} from './fragments'

const queryConstraint = `[${SUB_RESOURCE_PAGES.map(({ type }) => `"${type}"`).join(', ')}]`

export const getEvents = async (isPreviewMode: boolean, locale: string, resourceQueryType?: string | null): Promise<IEvent[]> => {
  const resourceQuery = resourceQueryType !== undefined ? 
    resourceQueryType !== null ? 
      `where: { type: "${resourceQueryType}" }` 
      : 
      `where: { type_in: ${queryConstraint}}`
    : 
    `where: { type_not_in: ${queryConstraint}}`

  const query = `
    {
      eventCollection (
        locale: "${locale}"
        ${resourceQuery}
      ) {
        items {
          sys {
            id
          }
          title
          description
          type
          image {
            url
            description
          }
          eventSlug
          location
          startDate
          endDate
          greyLabel
          buttonText
          buttonLink
          openInNewTab
        }
      }
    }
  `

  const isClient = typeof window !== 'undefined'
  const fetcher = isClient ? callPublicContentful : callContentful
  const response = await fetcher(query, isPreviewMode)
  return response?.data?.eventCollection?.items || []
}

export const getEventSlug = async (
  isPreviewMode: boolean,
  eventSlug: string,
  currentDomain: string,
  eventSysId: string | undefined,
): Promise<any> => {
  if (!eventSysId) return null

  const allWebDomains = Object.keys(DOMAIN_LOCALES)
  const filteredWebDomains = allWebDomains.filter((domain) => domain !== currentDomain)

  const pageLocalesQueries = filteredWebDomains
    ?.map((domain) => {
      return `{
        event(
          preview: ${isPreviewMode},
          id: "${eventSysId}",
          locale: "${DOMAIN_LOCALES[domain].code}"
        ) {
          pageSlug: eventSlug
        }
      }
      `
    })

  const pageLocalesRes = await Promise.all(
    pageLocalesQueries.map((pageLocalesQuery) => 
      callContentful(pageLocalesQuery, isPreviewMode)
    ),
  )

  const filteredPageLocaleRes = pageLocalesRes.reduce((validPageLocaleRes, currentValue, index) => {
    if (!currentValue.data?.event) {
      return validPageLocaleRes
    }

    const domain = filteredWebDomains[index]

    validPageLocaleRes.push({
      ...currentValue.data?.event,
      locale: DOMAIN_LOCALES[domain].code,
      domain: domain,
    })

    return validPageLocaleRes
  }, [])

  filteredPageLocaleRes.push({
    pageSlug: eventSlug,
    domain: currentDomain,
    locale: DOMAIN_LOCALES[currentDomain].code,
  })

  return filteredPageLocaleRes
}

export const getEventSmallContent = async (
  isPreviewMode: boolean,
  pageSlug: string,
  locale: string
): Promise<any> => {
  const query = `
    {
      eventCollection(
        preview: ${isPreviewMode},
        where: { eventSlug: "${pageSlug}" },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.eventCollection?.items[0]
  return data
}

export const getEventContent = async (
  isPreviewMode: boolean,
  eventSlug: string,
  locale: string
): Promise<any> => {

  const query = `
  {
    eventCollection(
      preview: ${isPreviewMode},
      where: {
        eventSlug: "${eventSlug}"
      },
      limit: 1,
      locale: "${locale}"
    ) {
      items {
        sys {
          id
        }
        title
        type
        image {
          url
          description
        }
        location
        startDate
        endDate
        buttonText
        buttonLink
        sectionList: sectionListCollection(
          preview: ${isPreviewMode},
          limit: 10,
        ) {
          items {
            sys {
              id
            }
            __typename
            ... on WrapperContentItems {
              ...wrapperContentItemsFields
            }
            ... on Banner {
              ...bannerFields
            }
            ... on Carriers {
              ...carriersFields
            }
            ... on LeadsWrapper {
              ...leadsWrapperFields
            }
            ... on CarrierListing {
              ...carrierListingFields
            }
            ... on ContentFeed {
              ...contentFeedFields
            }
          }
        }
        seo {
          title
          description
          ogType
          ogUrl
          ogImage {
            contentType
            url
          }
          ogTitle
          ogDescription
          robots
        }
      }
    }
  }
  ${bannerFields(isPreviewMode)}
  ${contentItemFields()}
  ${customerCardFields()}
  ${productUseCaseFields()}
  ${wrapperContentItemsFields(isPreviewMode)}
  ${carriersFields(isPreviewMode)}
  ${leadsWrapperFields(isPreviewMode, 380)}
  ${carrierListingFields()}
  ${contentFeedFields()}
  `
  const response = await callContentful(minifyQuery(query), isPreviewMode)
  const data = response.data?.eventCollection?.items[0]
  return fullPageDataModify(data)
}

export const getAllResourceSlugs = async ({
  isPreviewMode,
}: {
  isPreviewMode: boolean
}): Promise<string[]> => {
  const query = `
    {
      eventCollection(
        preview: ${isPreviewMode}
      ) {
        items {
          eventSlug
          seo {
            robots
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)

  return response.data?.eventCollection?.items
    ?.filter((event: IEvent) => event.eventSlug && !event.seo?.robots?.includes('noindex'))
    ?.map((event: IEvent) => event.eventSlug)
    ?? []
}
