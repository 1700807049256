import React from 'react'
import {
  CarrierInfoWrapper,
  CarrierLogo,
  CarrierName, 
  CarrierTrackingLink
} from './CarrierInfo.styles'

interface CarrierInfoProps {
  logo: string
  name: string
  trackingPageUrl: string
}

const CarrierInfo: React.FC<CarrierInfoProps> = ({
  logo, 
  name, 
  trackingPageUrl
}) => {
  return (
    <CarrierInfoWrapper>
      <CarrierName>{name}</CarrierName>
      <CarrierTrackingLink href={trackingPageUrl} target='_blank'>
        <CarrierLogo src={logo} alt={name} />
      </CarrierTrackingLink>
    </CarrierInfoWrapper>
  )
}

export default CarrierInfo
