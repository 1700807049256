import styled from 'styled-components'
import { HeaderTitleArrow } from '../HeaderItemTitle/HeaderItemTitle.style'

export const HeaderItemContentWrapper = styled.div`
  padding-top: 36px;
  z-index: 1;
`

export const HeaderItemText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const HeaderItem = styled.li`
  position: relative;

  &:hover {
    ${HeaderTitleArrow} {
      transform: rotate(180deg);
    }
  }

  &:not(:last-child) {
    margin-right: 30px;
  }
`

export const HeaderItemContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0px 0px 8px 8px;
  padding: 0;
  display: inline-flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 16px;
    flex-direction: row;
    box-shadow: 0px 16px 24px 0px rgba(96, 97, 112, 0.16), 0px 2px 8px 0px rgba(40, 41, 61, 0.04);
  }
`

export const StandaloneSectionSeparator = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.colors.black05};
  margin-right: 16px;
`

export const SectionWrapper = styled.div<{ displayFlex: boolean }>`
  ${(props) => props.displayFlex && `
    display: flex;
  `}

  &:not(:last-child) {
    margin-right: 16px;
  }
`
