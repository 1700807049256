// TODO-Typo: DO NOT EDIT yet, no font system
import styled from 'styled-components'
import { Container } from '@/components'
import {
  SubCopy,
  ThumbnailContentContainer,
  Title,
} from '../Thumbnail/Thumbnail.styles'

export const CategoryBackgroundContainer = styled.div`
  background: ${({ theme }) => theme.colors.surface01};
`

export const PmFeedBackgroundContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
`

export const PmFeedWrapper = styled.div`
  padding: 50px 0;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    padding: 60px 0;
  }
`

export const PmFeedTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[30]};
  line-height: ${({ theme }) => theme.lineHeight[36]};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 30px;
`

export const CategoryContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ThumbnailsWrapper = styled.div<{ noPaddingTop?: boolean }>`
  padding: 50px 0;

  ${ThumbnailContentContainer} {
    height: 368px;

    ${SubCopy},
    ${Title} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${ThumbnailContentContainer} {
      height: 420px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    padding: 60px 0;
  }

  ${({ noPaddingTop }) => noPaddingTop && `
    padding-top: 0 !important;
  `}
`

export const ThumbnailsItemListWrapper = styled.div`
  display: grid;
  grid: none;
  gap: 30px 0px;
  justify-content: center;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    grid-template-columns: repeat(2, 362px);
    gap: 60px 31px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.xl} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const heading2DesktopFontSize = 24
const paragraphDesktopFontsize = 18
const heading2MobileFontSize = 18
const paragraphMobileFontsize = 15

export const SEOFiller = styled.div`
  background: ${({ theme }) => theme.colors.surface01};
  padding-top: ${50 - 0.83 * heading2MobileFontSize}px;
  padding-bottom: ${50 - paragraphMobileFontsize}px;
  color: ${({ theme }) => theme.colors.darkGray};

  a {
    color: ${({ theme }) => theme.colors.brightBlue};

    :hover,
    :visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes[heading2MobileFontSize]};
    line-height: ${({ theme }) => theme.lineHeight[24]};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes[paragraphMobileFontsize]};
    line-height: ${({ theme }) => theme.lineHeight[26]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${60 - 0.83 * heading2DesktopFontSize}px;
    padding-bottom: ${60 - paragraphDesktopFontsize}px;

    h2,
    p {
      line-height: ${({ theme }) => theme.lineHeight[32]};
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSizes[heading2DesktopFontSize]};
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes[paragraphDesktopFontsize]};
    }
  }
`
