import getConfig from 'next/config'

export enum Locales {
  English = 'en-US',
  Germany = 'de-DE',
}

export enum SlugLocales {
  German = 'de',
  French = 'fr',
}

interface IDomainLocale {
  code: Locales
  flagIcon: string
  displayName: string
  activatedFlagIcon?: string
}

const { publicRuntimeConfig } = getConfig()

export const DOMAIN_LOCALES: Record<string, IDomainLocale> = {
  [publicRuntimeConfig.webDomain]: {
    code: Locales.English,
    displayName: 'Global',
    activatedFlagIcon: '/images/flags/globe.svg',
    flagIcon: '/images/flags/globe-blue.svg',
  },
  [publicRuntimeConfig.webDomainDE]: {
    code: Locales.Germany,
    displayName: 'Germany',
    flagIcon: '/images/flags/germany.svg',
  },
}

export const GlobalLanguageData: Record<string, { pageSlug: string }> = {
  [publicRuntimeConfig.webDomain]: {
    pageSlug: '/'
  },
  [publicRuntimeConfig.webDomainDE]: {
    pageSlug: '/'
  },
}
