import { CustomEventName } from '@/constants';
import { pushEvent } from '../tracking.utils'

export enum SocialChannel {
  facebook = 'facebook',
  twitter = 'twitter',
  linkedin = 'linkedin',
}
export type SocialChannelOptions = keyof typeof SocialChannel;
export const socialSharingButtonPushEvent = (
  channel: SocialChannelOptions
): void => {
  const details = { channel }
  pushEvent(CustomEventName.ClickShareBlog, details)
}
