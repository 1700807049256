import { ForwardedRef } from 'react'
import { ContentItemInterface } from '@/interfaces/contentItem'
import { AssetRenderer } from '@/components'
import {
  Container,
  ImageWrapper,
  ContentContainer,
  TitleHolder,
  Title,
} from './SmallImageAndCopy.styles'

interface ITitleProps {
  forwardedRef?: ForwardedRef<HTMLParagraphElement>
  height?: number
}

const SmallImageAndCopy: React.FC<ContentItemInterface & ITitleProps> = (
  props
) => {
  const {
    title,
    image,
    forwardedRef,
  } = props

  return (
    <Container>
      {image && (
        <ImageWrapper>
          <AssetRenderer
            asset={image}
            imageProps={{
              quality: 100,
              fill: true,
              size: '100vw',
              style: {
                objectFit: 'cover',
              }
            }}
            videoProps={{
              height: {
                mobile: 116,
                desktop: 186,
              },
            }}
          />
        </ImageWrapper>
      )}
      {title && (
        <ContentContainer>
          <TitleHolder style={{ textAlign: 'center' }}>
            <Title ref={forwardedRef}>{title}</Title>
          </TitleHolder>
        </ContentContainer>
      )}
    </Container>
  )
}

export default SmallImageAndCopy
