import { createContext, useContext } from 'react'
import { GlobalLanguageData } from '@/interfaces/locales'
import { formatLanguageData } from '@/utils'

interface ILanguageContext {
  languageData: typeof GlobalLanguageData
}

const defaultLanguageData = {
  languageData: GlobalLanguageData,
}

const LanguageContext = createContext<ILanguageContext>(defaultLanguageData)

export const LanguageProvider: React.FC<any> = (props) => {
  const { children, languageData } = props

  return (
    <LanguageContext.Provider value={{ languageData: formatLanguageData(languageData) }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext:any = () => useContext(LanguageContext)
