import React from 'react'
import {
  documentToReactComponents as originalDocumentToReactComponents,
  Options as RenderDocumentOptions,
} from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

/**
 * This util replace "\n" in Contentful's rich-text to <br /> tag
 */
const documentToReactComponentsOptions: RenderDocumentOptions = {
  renderText: (text) => text
    .split('\n')
    .reduce(
      (children: any, textSegment: any, index: any) =>
        [...children, index > 0 && <br key={index} />, textSegment],
      [],
    ),
}

const documentToReactComponentsWithBr = (richTextDocument: Document, options?: RenderDocumentOptions): React.ReactNode => originalDocumentToReactComponents(richTextDocument, { ...documentToReactComponentsOptions, ...options })

export default documentToReactComponentsWithBr
