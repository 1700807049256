import { forwardRef } from 'react'
import { Document } from '@contentful/rich-text-types'

// Component
import {
  TextAreaWrapper,
  TextAreaInput,
} from './TextArea.styles'
import {
  Label,
  ErrorMessage,
} from '../..'

// Util
import { documentToReactComponentsWithBr } from '@/utils'

interface ITextAreaProps {
  value?: string
  name?: string
  label?: {
    json: Document
  }
  placeholder?: string
  isMandatory?: boolean
  errorMessage?: string
  rows?: number
}

const TextArea: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  ITextAreaProps & Record<string, any>
> = (props, ref) => {
  const {
    value,
    name,
    label,
    rows = 3,
    placeholder,
    isMandatory,
    errorMessage,
    ...restProps
  } = props

  return (
    <TextAreaWrapper>
      {label && (
        <Label
          htmlFor={name}
          isMandatory={isMandatory}
        >
          {documentToReactComponentsWithBr(label.json)}
        </Label>
      )}
      <TextAreaInput
        ref={ref}
        name={name}
        rows={rows}
        placeholder={placeholder}
        {...restProps}
        isInvalid={!!errorMessage}
      >
        {value}
      </TextAreaInput>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </TextAreaWrapper>
  )
}

export default forwardRef(TextArea)
