// TODO-Typo: split this file into /typography/head, /typography/paragraphy, /typography/heading
export enum TextStyles {
  // Hero Styles
  'Large Display Font' = 'Large Display Font',
  'Subheading' = 'Subheading',
  // Heading Styles
  'Heading 1' = 'Heading 1',
  'Heading 2' = 'Heading 2',
  'Heading 3' = 'Heading 3',
  'Heading 4' = 'Heading 4',
  'Heading 5' = 'Heading 5',
  // Paragraph Styles
  'Large Paragraph Text' = 'Large Paragraph Text',
  'Large Paragraph Bold' = 'Large Paragraph Bold',
  'Small Paragraph Text' = 'Small Paragraph Text',
  'Small Paragraph Bold' = 'Small Paragraph Bold',
  'Overline' = 'Overline',
  'Caption Text' = 'Caption Text',
  // Button Styles
  'Button' = 'Button', // TODO-Typo: should not be in typo system, check with designer again
}
