import styled from 'styled-components'
import {
  RichTextContainer,
  Image,
  ImageDesc,
  ImageContainer
} from '@/components/RichText/RichText.styles'
import Container from '../Container'

export const Wrapper = styled.div`
  padding: 32px 0;
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 80px 0;
  }

  ${Container} {
    max-width: 750px;
  }
`

const defaultUlIndent = 28

export const Content = styled(RichTextContainer)`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;

  &.custom__richtext a {
    font-weight: 600;
  }

  &.custom__richtext h2 a,
  &.custom__richtext h3 a,
  &.custom__richtext h4 a {
    font-weight: inherit;
    font-size: inherit;
  }

  &.custom__richtext ${Image} {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  &.custom__richtext ${ImageDesc} {
    display: block;
    margin-top: 10px !important;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-top: 16px !important;
    }
  }

  &.custom__richtext ${ImageContainer} {
    margin: 36px 0;
    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin: 40px 0;
    }
  }

  &.custom__richtext p, a, li {
    font-size: 16px;
    line-height: 25px;
  }

  &.custom__richtext ul {
    margin-left: 6px;
    & > li {
      margin-top: 25px;
      & ::marker {
        font-size: 21px; // TODO: Change this to ajust size of marker
      }
    }
  }

  &.custom__richtext pre {
    height: 25px;
  }

  // Update OL markers
  &.custom__richtext ol {
    padding-left: 6px;
    p {
      padding-left: ${defaultUlIndent}px !important;
    }
    > li > :first-child {
      display: inline-flex;
    }

    & > li {
      margin-top: 25px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: 0;
    padding-right: 0;

    &.custom__richtext p, a, li {
      font-size: 18px;
      line-height: 28px;
    }

    &.custom__richtext ol, ul {
      & > li {
        margin-top: ${defaultUlIndent}px;
      }
    }

    &.custom__richtext pre {
      height: ${defaultUlIndent}px;
    }

    &.custom__richtext ul {
      margin-left: ${defaultUlIndent / 2}px;
    }

    &.custom__richtext ol {
      padding-left: ${defaultUlIndent / 2}px;
    }
  }
`
export const Footer = styled.div`
  margin-top: 16px;
`
