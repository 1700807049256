// TODO-Typo: DO NOT EDIT DesktopBlogHeaderItem, belongs to Nav font system (not mapped yet)
import { DynamicLink } from '@/components'
import styled from 'styled-components'

export const DesktopBlogHeaderList = styled.div`
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: none;
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
  }
`

export const DesktopBlogHeaderItem = styled(DynamicLink)<{ isActive: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  color: ${({ theme, isActive }) => isActive ? theme.colors.orange : theme.colors.darkBlue};
  margin: 0 18px;
  transition: all .2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`
