import { errorCodes } from '@/constants'

export default {
  [errorCodes.ERROR_404]: {
    errorCode: '404',
    title: 'We can\'t find the page you\'re looking for',
    description: 'Here are some links that might be helpful:',
    buttonText: 'Back to home',
  },

  [errorCodes.ERROR_500]: {
    errorCode: '500',
    title: 'Oops!\nSomething went wrong.',
    description: 'Please try again later.',
  },

  [errorCodes.ERROR_502]: {
    errorCode: '502',
    title: 'Bad gateway',
    description: 'Here\'s how you can try to fix it:',
    subPoints: [
      'Try refreshing or reloading the page',
      'Close all browsers and start a new session',
      'Clear your cache and delete your cookies'
    ]
  },

  [errorCodes.ERROR_503]: {
    errorCode: '503',
    title: 'Upgrading in progress',
    description: 'We\'re performing some upgrades at the moment.\nPlease try again shortly.'
  }, 
} 
