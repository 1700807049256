import { Asset } from '@/interfaces/common'

export enum CarrierUITemplateKeys {
  Animated = 'Animated',
  Static = 'Static',
}

export interface CarrierProps {
  uiTemplate?: CarrierUITemplateKeys
  smallTitle?: string
  title?: string
  firstCarrierList?: Asset[]
  secondCarrierList?: Asset[]
  buttonText?: string
  buttonLink?: string
  buttonEventLabel?: string
  openInNewTab?: boolean
  withSeparator?: boolean
}
