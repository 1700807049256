import { DynamicLink } from '@/components'
import styled, { css } from 'styled-components'
import AngleDown from '../../../public/images/icons/keyboard-arrow-down.svg'
import { SectionTheme } from '../../../styled'

export const PanelArrow = styled(AngleDown)`
  transition: .6s ease-in-out
`

export const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.lineHeight[19]};
  font-weight: 900;
  font-size: ${({ theme }) => theme.fontSizes[14]};
`

export const LinkPanelTitle = styled(DynamicLink)``

export const PanelContentWrapper = styled.div<{ isOpen: boolean }>`
  overflow: hidden;

  ${props => props.isOpen && css`
    max-height: 100vh;
    transition: .8s ease-in-out;
  `}

  ${props => !props.isOpen && css`
    max-height: 0;
    transition: .5s ease-out;
  `}
`

export const PanelContent = styled.div`
  min-height: fit-content;
`

export const PanelWrapper = styled.div<{ isOpen: boolean, panelTheme: SectionTheme }>`
  color: ${({ theme, panelTheme }) => theme.sectionTheme?.text || panelTheme?.text};

  ${PanelArrow}, ${PanelArrow} path { 
    fill: ${({ theme, panelTheme }) => theme.sectionTheme?.text || panelTheme?.text}
  }

  :not(:last-child) ${PanelTitle} {
    margin-bottom: 30px;
  }

  :last-child ${PanelContent} {
    padding-top: 30px;
  }

  ${props => props.isOpen && css`
    ${PanelArrow} {
      transform: rotate(180deg)
    }
  `}

  a {
    color: inherit;
  }
`
