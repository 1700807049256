import React from 'react'

import { HeaderComponentProps } from '../Header'
// import CountrySelector from '../CountrySelector'
import NavigationBar from './NavigationBar'

import { LoginButton } from '../Header.styles'
import {
  StyledButton,
  // LoginButtonAndCountrySelectorSeparator,
  TopRightSection,
} from './HeaderDesktop.styles'

const HeaderDesktop: React.FC<HeaderComponentProps> = ({
  headerItems,
  // currentDomain
}) => {
  return (
    <>
      {/* --- Navigation */}
      <NavigationBar headerItems={headerItems}/>

      <TopRightSection>
        {/* --- Login */}
        <LoginButton href='/login'>Login</LoginButton>
        {/** TODO: Khanh - Add the separator and Country selector back after go live */}
        {/* <LoginButtonAndCountrySelectorSeparator /> */}
        {/* --- Language selector */}
        {/* <CountrySelector currentDomain={currentDomain}/> */}
        {/* --- Quick contact */}
        <StyledButton
          href='https://resources.parcelperform.com/demo'
          variant='secondary'
        >
          Contact Us
        </StyledButton>
      </TopRightSection>
    </>
  )
}

export default HeaderDesktop
