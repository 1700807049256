// TODO-Typo: DO NOT EDIT YET, not sure which font system
import styled from 'styled-components'
import CloseSvg from '../../../public/images/icons/close-btn.svg'

export const CookieBannerContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 18px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeight[16]};
  z-index: 2;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 17px 140px 17px 30px;
    font-size: ${({ theme }) => theme.fontSizes[18]};
    line-height: ${({ theme }) => theme.lineHeight[24]};
  }
`

export const CookieLink = styled.a`
  color: ${({ theme }) => theme.colors.brightBlue};

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue};
  }

  :visited {
    color: ${({ theme }) => theme.colors.blue};
  }
`

export const CloseButtonContainer = styled.div`
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
`

export const CloseButton = styled(CloseSvg)``
