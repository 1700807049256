import { IGeo } from '@/interfaces/geo'
import { createContext, useContext } from 'react'
import { ParsedUrlQuery } from 'querystring'

interface IGlobalState {
  pageUrl: string
  geoInfo?: IGeo | null
  pageParams: ParsedUrlQuery
  utmData?: Record<string, unknown> | null 
  isHomepage?: boolean
}

const GlobalStateContext = createContext<IGlobalState>({
  pageUrl: '',
  geoInfo: {},
  pageParams: {},
  utmData: {},
  isHomepage: false,
})

export const GlobalStateProvider: React.FC<IGlobalState> = ({ children, ...state }) => (
  <GlobalStateContext.Provider value={state}>
    {children}
  </GlobalStateContext.Provider>
)

export const useGlobalState = (): IGlobalState => useContext(GlobalStateContext)
