import { RefObject } from 'react'
import { ContentItemInterface } from '@/interfaces/contentItem'

import ArrowRight from '../../../../public/images/icons/arrow-right.svg'
import { 
  Container,
  Image,
  TitleHolder,
  Title,
  Description,
  LinkContainer,
  AssetWrapper,
} from './IconAndCopy.styles'
import { AssetRenderer, Button } from '@/components'

interface ITitleProps {
  forwardedRef?: RefObject<HTMLParagraphElement>
  height?: number
}

const IconAndCopy: React.FC<ContentItemInterface & ITitleProps> = (props) => {
  const {
    title,
    description,
    image,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    forwardedRef,
  } = props
  //in case buttonText contains empty spaces
  const buttonText = props.buttonText?.trim() || ''
  const height = forwardedRef?.current?.clientHeight
  
  return (
    <Container>
      {image && (
        <AssetWrapper>
          <AssetRenderer
            asset={image}
            ImageComponent={Image}
          />
        </AssetWrapper>
      )}
      {
        title &&
        <TitleHolder minHeight={height}>
          <Title ref={forwardedRef}>{title}</Title>
        </TitleHolder>
      }
      <Description>{description}</Description>
      {
        buttonLink && (
          <LinkContainer>
            <Button
              variant='link'
              href={buttonLink}
              eventLabel={buttonEventLabel}
              openInNewTab={openInNewTab}
              icon={ArrowRight}
            >
              {buttonText || 'Learn more'}
            </Button>
          </LinkContainer>
        )
      }
    </Container>
  )
}

export default IconAndCopy
