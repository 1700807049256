import { errorCodes } from '@/constants'

export default {
  [errorCodes.ERROR_404]: {
    errorCode: '404 [de]',
    title: 'We can\'t find the page you\'re looking for [de]',
    description: 'Here are some links that might be helpful: [de]',
    buttonText: 'Back to home [de]',
  },

  [errorCodes.ERROR_500]: {
    errorCode: '500',
    title: 'Oops!\nSomething went wrong. [de]',
    description: 'Please try again later. [de]',
  },

  [errorCodes.ERROR_502]: {
    errorCode: '502 [de]',
    title: 'Bad gateway [de]',
    description: 'Here\'s how you can try to fix it: [de]',
    subPoint: [
      'Try refreshing or reloading the page [de]',
      'Close all browsers and start a new session [de]',
      'Clear your cache and delete your cookies [de]'
    ]
  },

  [errorCodes.ERROR_503]: {
    errorCode: '503 [de]',
    title: 'Upgrading in progress [de]',
    description: 'We\'re performing some upgrades at the moment.\nPlease try again shortly. [de]'
  }, 
} 
