import { UITemplatesForm } from '@/constants/uiTemplate.constants'
import { IFormProps } from '@/interfaces/form'
import LeadForm from './templates/LeadForm'

const Form: React.FC<IFormProps> = (props) => {
  const { uiTemplate } = props
  const DefaultUITemplate = LeadForm
  const UITemplateForm = uiTemplate
    ? (UITemplatesForm[uiTemplate] || DefaultUITemplate)
    : DefaultUITemplate

  return <UITemplateForm {...props} />
}

export default Form
