import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'

export const TitleContainer = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  width: 100%;
  text-align: center;
  padding-top: 62px;
  padding-bottom: 12px;
  color: ${({ theme }) => theme.colors.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cadetBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 38px;
    padding-bottom: 19px;
  }
`

export const TagTitle = styled.span`
  color: ${({ theme }) => theme.colors.chetwodeBlue};
`

export const CategoryTitle = styled(TitleContainer)`
  text-transform: capitalize;
`
