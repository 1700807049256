import { ThemeProvider } from 'styled-components'

const LocalThemeProvider: React.FC<any> = ({ theme: localTheme, children }) => {
  return (
    <ThemeProvider theme={(oldTheme) => ({ ...oldTheme, ...localTheme })}>
      {children}
    </ThemeProvider>
  )
}

export default LocalThemeProvider
