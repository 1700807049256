import { GlobalLanguageData } from '@/interfaces/locales'
import { ILanguageFields } from '@/interfaces/head'

type FormattedLanguageData = Record<string, { pageSlug: string }>

export const formatLanguageData = (languageData: ILanguageFields[] | null): FormattedLanguageData => {
  if (!languageData) {
    return {}
  }

  const formattedLanguageData = languageData.reduce<FormattedLanguageData>((prev, cur) => {
    return {
      ...prev,
      [cur.domain]: {
        pageSlug: cur.pageSlug,
      }
    }
  }, {})
  return formattedLanguageData || GlobalLanguageData
}
