import React, { useMemo } from 'react'
import Link from 'next/link'
import { isAbsoluteUrl } from '@/utils'
import { useParseCurrentURL } from '@/hooks/useParseUrl'

interface LinkProps {
  isCurrentDomain: boolean
  href: string
}

const DynamicLink: React.FC<any> = ({
  href, 
  isActive:_, 
  isOpenNewTab,
  ...props 
}) => {
  const currentParsingUrl = useParseCurrentURL()

  const link = useMemo<LinkProps | undefined>(() => {
    if (!href) return

    if (!isAbsoluteUrl(href)) return { isCurrentDomain: true, href: href.startsWith('/') ? href : `/${href}` }

    const url = new URL(href)
    const isCurrentDomain = currentParsingUrl.origin === url?.origin
    return { isCurrentDomain, href: isCurrentDomain ? url?.pathname : url?.href }
  }, [href, currentParsingUrl.origin])

  return !link?.isCurrentDomain
    ? <a
      href={link?.href}
      {...props}
      rel="noreferrer"
      target={isOpenNewTab ? '_blank' : '_self'}
    />
    : <Link
      href={link?.href}
      passHref
      {...props}
      rel="noreferrer"
      target={isOpenNewTab ? '_blank' : '_self'}
    />
}

export default DynamicLink
