import React from 'react'
import Thumbnail from '@/components/Thumbnail'
import { Container } from '@/components'
import { ArticleProps } from '@/interfaces/article'
import {
  List,
  Title,
  Wrapper
} from './RelatedArticles.styles'

interface RelatedArticlesProps {
  relatedArticles: ArticleProps[]
}

const RelatedArticles: React.FC<RelatedArticlesProps> = ({ relatedArticles }) => {
  return (
    <Wrapper>
      <Container>
        <Title>Related Articles Worth Your Time</Title>
        <List>
          {relatedArticles?.map((article: ArticleProps, index: number) => (
            <Thumbnail key={index} {...article} />
          ))}
        </List>
      </Container>
    </Wrapper>
  )
}

export default RelatedArticles
