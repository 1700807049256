// TODO-Typo: NOT EDIT YET, no font system
import styled from 'styled-components'
import LocationPin from './../../../../public/images/icons/location-pin.svg'

export const ContactWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  position: relative;
  padding-bottom: 30px;

  ::after {
    content: '';
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    opacity: .5;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    padding-bottom: 0;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    
    &:after {
      display: none;
    }
  }
`

export const ContactHeading = styled.div`
  margin-bottom: 26px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
`

export const ContactList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    flex-direction: row;
    margin: -30px 0 -25px 0;
  }
`

export const ContactItem = styled.div<{ itemListLength?: number }>`
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    min-width: 20%;
    flex-grow: 1;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    padding: 25px 0 25px 0;
    && {
      margin-bottom: 0;
    }

    ${({ itemListLength }) => itemListLength && itemListLength > 5 && `
      &:last-child {
        flex-grow: 13;
      }
    `}
  }
`

export const ContactLocation = styled.div`
  margin-bottom: 9px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[19]};

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    display: inherit;
    margin-bottom: 12px;
    width: 170px;
  }
`

export const LocationPinIcon = styled(LocationPin)`
  margin-right: 10px;

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    margin-right: 5px;
  }
`

export const ContactAddress = styled.div`
  line-height: ${({ theme }) => theme.lineHeight[19]};
  white-space: pre-wrap;

  > div:first-child {
    margin-bottom: 4px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    width: 170px;
  }
`
