import styled from 'styled-components'

const Container = styled.div`
  // TODO: this width should be change based on user screen size. Will do it after having the breakpoints from Design team
  width: 100%;
  max-width: 1148px;
  padding-left: 24px;
  padding-right: 24px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
`

// For Customer Card container
export const CustomContainer = styled(Container)`
  max-width: 1100px;
`

export default Container
