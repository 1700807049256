import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { MenuItemDescription } from '../MenuItem/MenuItem.styles'

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 4'],
})`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 4px;
`

export const Item = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 4px;
    padding: 8px;

    &:hover {
      background: ${({ theme }) => theme.colors.surface01};

      ${MenuItemDescription} {
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
    
    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-bottom: 4px;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
