import { UITemplatesWrapper } from '@/constants'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import MultipleIconAndCopy from './Templates/MultipleIconAndCopy'

const WrapperContentItems: React.FC<WrapperContentInterface> = (props) => {
  const {
    uiTemplate
  } = props
  const defaultWrapperContentItems = MultipleIconAndCopy
  const UITemplateWrapper = uiTemplate ? (UITemplatesWrapper[uiTemplate] || defaultWrapperContentItems) : defaultWrapperContentItems

  return <UITemplateWrapper {...props} />
}

export default WrapperContentItems
