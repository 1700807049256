import dynamic from 'next/dynamic'

/* 
* This file is for mapping inner components, since default in any 
* UITemplate of Wrapper is currently ContentItem
*/
import ContentTypeKeys from '@/interfaces/contentType'

export const ContentTypesMapping: {
  [key in ContentTypeKeys]?: React.ComponentType<any>
} = {
  [ContentTypeKeys.Banner]: dynamic(() => import('../components/Banner')),
  [ContentTypeKeys.ContentItem]: dynamic(() => import('../components/ContentItem')),
  [ContentTypeKeys.CustomerCard]: dynamic(() => import('../components/CustomerCard')),
  [ContentTypeKeys.ProductUseCase]: dynamic(() => import('../components/ProductUseCase')),
  [ContentTypeKeys.WrapperContentItems]: dynamic(() => import('../components/WrapperContentItems')),
  [ContentTypeKeys.Carriers]: dynamic(() => import('../components/Carrier')),
  [ContentTypeKeys.LeadsWrapper]: dynamic(() => import('../components/LeadsWrapper')),
  [ContentTypeKeys.Article]: dynamic(() => import('../components/Article')),
  [ContentTypeKeys.Category]: dynamic(() => import('../components/Category')),
}

export const FullPageContentTypesMapping: {
  [key in ContentTypeKeys]?: React.ComponentType<any>
} = {
  [ContentTypeKeys.Banner]: dynamic(() => import('../components/Banner')),
  [ContentTypeKeys.WrapperContentItems]: dynamic(() => import('../components/WrapperContentItems')),
  [ContentTypeKeys.Carriers]: dynamic(() => import('../components/Carrier')),
  [ContentTypeKeys.LeadsWrapper]: dynamic(() => import('../components/LeadsWrapper')),
  [ContentTypeKeys.CarrierListing]: dynamic(() => import('../components/CarrierListingWrapper')),
  [ContentTypeKeys.ContentFeed]: dynamic(() => import('../components/ContentFeed')),
  [ContentTypeKeys.EventsSection]: dynamic(() => import('../components/EventsSection')),
  [ContentTypeKeys.Resources]: dynamic(() => import('../components/ResourcesSection')),
  [ContentTypeKeys.Wrapper]: dynamic(() => import('../components/Wrapper')),
}

export enum ContentTypeIds {
  Asset = 'Asset',
  article = 'article',
  banner = 'banner',
  carriers = 'carriers',
  category = 'category',
  contentItem = 'contentItem',
  customerCard = 'customerCard',
  footer = 'footer',
  form = 'form',
  formField = 'formField',
  fullPage = 'fullPage',
  headerItem = 'headerItem',
  leadsWrapper = 'leadsWrapper',
  menuContentSection = 'menuContentSection',
  menuItem = 'menuItem',
  productUseCase = 'productUseCase',
  seo = 'seo',
  wrapper = 'wrapper',
  wrapperContentItems = 'wrapperContentItems',
  carrierListing = 'carrierListing',
  popUp = 'popUp',
  press = 'press',
  quote = 'quote',
  event = 'event',
  glossary = 'glossary',
}

export const entryPages: ContentTypeIds[] = [
  ContentTypeIds.article,
  ContentTypeIds.fullPage,
  ContentTypeIds.category,
  ContentTypeIds.event,
  ContentTypeIds.glossary,
]

// Applies for all, refractor later
export enum Alignment {
  Left = 'Left',
  Right = 'Right',
  Center = 'Center',
}
