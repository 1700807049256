import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from 'react'
import { EMAIL_PATTERN } from '@pperform/pp-constants/validation'

// Type
import { IFormField } from '@/interfaces/form'

// Constant
import {
  DEFAULT_REQUIRED_MESSAGE,
  DEFAULT_INVALID_EMAIL_MESSAGE,
} from '@/constants/form.constants'

// Util
import {
  checkIsFromEmailDomainBlocked,
  GTMButtonClick,
  GTMFormSubmission,
} from '@/utils'

// Fetcher
import { postSubmitForm } from '@/fetchers'

// Hook
import { useReCaptcha } from '@/hooks'
import { useGlobalState } from '@/hooks'

const useSubscribeBanner = ({
  zapierHookId,
  formFields,
  eventLabel,
  buttonEventLabel,
} : {
  zapierHookId?: string
  formFields?: IFormField[]
  eventLabel?: string
  buttonEventLabel?: string
}): {
  showSubscribeBanner: boolean
  emailInputField?: IFormField | null
  handleFormSubmit: FormEventHandler
  handleEmailChange: ChangeEventHandler<HTMLInputElement>
  handleButtonClick: MouseEventHandler<HTMLButtonElement>
  handleCloseButtonClick: MouseEventHandler<HTMLImageElement>
  email: string
  error: string
  isSubmitted: boolean
  isSubmitting: boolean
} => {
  const emailInputField = formFields?.[0]

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [showSubscribeBanner, setShowSubscribeBanner] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    pageUrl,
    geoInfo,
    utmData,
  } = useGlobalState()

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value)
  }

  const {
    handleReCaptchaVerify,
  } = useReCaptcha()

  const handleFormSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    if (emailInputField?.isMandatory && email.trim() === '') {
      setError(emailInputField?.mandatoryCheckErrorMessage || DEFAULT_REQUIRED_MESSAGE)
      setIsSubmitting(false)
      return
    } else if (emailInputField?.isNonCompanyEmailDomainBlocked && checkIsFromEmailDomainBlocked(email)) {
      setError(emailInputField?.emailDomainBlockedCheckErrorMessage || DEFAULT_REQUIRED_MESSAGE)
      setIsSubmitting(false)
      return
    } else if (!EMAIL_PATTERN.test(email)) {
      setError(emailInputField?.customErrorMessage || DEFAULT_INVALID_EMAIL_MESSAGE)
      setIsSubmitting(false)
      return
    }
    if (!zapierHookId) {
      return
    }

    const captchaCode = await handleReCaptchaVerify()

    if (!captchaCode) {
      console.error('reCAPTCHA verify failed')
      return
    }

    try {

      let submitData: any = {
        [emailInputField?.name || 'email']: email,
        pageUrl,
        city: geoInfo?.city,
        country: geoInfo?.country,
        region: geoInfo?.timezone?.split('/')[0],
      }

      if (utmData) {
        submitData = {
          ...submitData,
          ...utmData,
        }
      }

      const response = await postSubmitForm({
        data: submitData,
        zapierHookId,
        captchaCode,
      })
      if (response?.ok) {
        setIsSubmitted(true)
        if (eventLabel) GTMFormSubmission(eventLabel)
      }
    } finally {
      setIsSubmitting(false)
      setEmail('')
      setError('')
      setTimeout(() => setShowSubscribeBanner(false), 3000)
    }
  }

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (buttonEventLabel) GTMButtonClick(buttonEventLabel)
  }

  const handleCloseButtonClick: MouseEventHandler<HTMLImageElement> = () => {
    setShowSubscribeBanner(false)
  }

  return {
    email,
    error,
    showSubscribeBanner,
    emailInputField,
    handleEmailChange,
    handleFormSubmit,
    handleCloseButtonClick,
    handleButtonClick,
    isSubmitted,
    isSubmitting
  }
}

export default useSubscribeBanner
