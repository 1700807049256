import { capitalize } from '@/utils'
import ContentTypeKeys from '@/interfaces/contentType'
import { EventType, IEvent } from '@/interfaces/eventsSection'
import { IResourcesSectionProps } from '@/interfaces/resource.interfaces'

interface EventData {
  upcoming: IEvent[]
  past: IEvent[]
  productChats: IEvent[]
}

export interface ResourceData {
  __typename: string
  successStories: IEvent[]
  ebooks: IEvent[]
  reports: IEvent[]
}

export const modifyEventsData = (data: IEvent[]): EventData => {
  return data.reduce((results: EventData, current: IEvent) => {
    if (current.type === EventType.PRODUCT_CHATS) { 
      results.productChats.push(current) 
    } else if (new Date(current.endDate) < new Date() && current.type === EventType.WEBINAR) { 
      results.past.push(current)
    } else if (new Date(current.endDate) >= new Date()) { 
      results.upcoming.push(current)
    }
    return results
  }, {
    upcoming: [],
    past: [],
    productChats: []
  })
}

export const modifyResourcesData = (data: IEvent[]): ResourceData => {
  return data.reduce((results: ResourceData, current: IEvent) => {
    switch (current.type) {
      case EventType.SUCCESS_STORIES:
        results.successStories.push(current) 
        break
      case EventType.EBOOKS: 
        results.ebooks.push(current)
        break
      case EventType.REPORTS:
        results.reports.push(current)
        break
    }
    return results
  }, {
    __typename: ContentTypeKeys.Resources,
    successStories: [],
    ebooks: [],
    reports: [],
  })
}

export const resourcesPageSectionListModify = (
  sectionList: any[],
  resourcesData: IResourcesSectionProps,
): any[] => {
  const staticSection = [resourcesData]
  const bannerSectionIndex = sectionList.findIndex((section: any) => section && capitalize(section?.__typename) === ContentTypeKeys.Banner)
  staticSection.unshift(sectionList.splice(bannerSectionIndex, 1)[0])
  return staticSection.concat(sectionList)
}
