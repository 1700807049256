import React, { FC } from 'react'
import { CellColor, ITable } from '@/interfaces/table'
import {
  TableCell,
  TableHeadCell,
  TableRow,
  TableWrapper,
} from './Table.styles'

const Table: FC<ITable> = ({ table: data }) => {
  if (!data?.length) return null

  const renderClassName = (
    rowIndex: number,
    colIndex: number,
    arrLength: number,
    rowSpan?: number
  ) => {
    if (colIndex) return ''
    if (rowIndex === arrLength - 1) return 'bottom-left-cell'
    if (rowSpan && rowIndex + rowSpan === arrLength) return 'bottom-left-merge-cell'
    return 'first-col'
  }

  return (
    <TableWrapper>
      <table>
        <thead>
          <TableRow>
            {data[0].content?.map((cell, index) => {
              if (!cell.nodeType) return null
              return (
                <TableHeadCell color={CellColor.BLUE} key={index}>
                  {cell.value}
                </TableHeadCell>
              )
            })}
          </TableRow>
        </thead>

        <tbody>
          {data.slice(1)?.map((row, rowIndex, arr) => {
            return (
              <TableRow key={rowIndex}>
                {row.content.map((cell, colIndex) => {
                  if (!cell.nodeType) return null
                  return (
                    <TableCell
                      rowSpan={cell.rowSpan}
                      colSpan={cell.colSpan}
                      color={cell.color}
                      key={colIndex}
                      className={
                        renderClassName(
                          rowIndex,
                          colIndex,
                          arr.length,
                          cell.rowSpan
                        )
                      }
                    >
                      {cell.value}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </tbody>
      </table>
    </TableWrapper>
  )
}

export default Table
