import React from 'react'
import { paginationKeys } from '../CarrierList.hook'
import {
  CarrierArrowLeft,
  CarrierArrowGroup,
  CarrierPaginationCurrentAmount,
  CarrierPaginationDescription,
  CarrierPaginationWrapper, 
  CarrierArrowRight
} from './CarrierPagination.styles'

interface CarrierPaginationProps {
  total: number
  currentAmount: number
  onChange: (key: paginationKeys) => void
  paginationText?: string
}

const CarrierPagination: React.FC<CarrierPaginationProps> = ({
  total,
  onChange,
  currentAmount,
  paginationText
}) => {
  return (
    <CarrierPaginationWrapper>
      {/* First page */}
      <CarrierArrowGroup onClick={() => onChange(paginationKeys.FIRST_PAGE)}>
        <CarrierArrowLeft />
        <CarrierArrowLeft />
      </CarrierArrowGroup>

      {/* Previous page */}
      <CarrierArrowLeft onClick={() => onChange(paginationKeys.PREV_PAGE)} />
      
      <CarrierPaginationDescription>
        <CarrierPaginationCurrentAmount>{currentAmount}</CarrierPaginationCurrentAmount>
        <span>{`${paginationText || 'of'} ${total}`}</span>
      </CarrierPaginationDescription>

      {/* Next page */}
      <CarrierArrowRight onClick={() => onChange(paginationKeys.NEXT_PAGE)} />

      {/* Last page */}
      <CarrierArrowGroup onClick={() => onChange(paginationKeys.LAST_PAGE)}>
        <CarrierArrowRight />
        <CarrierArrowRight />
      </CarrierArrowGroup>
    </CarrierPaginationWrapper>
  )
}

export default CarrierPagination
