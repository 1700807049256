import { HeaderItemInterface } from '@/interfaces/headerItem'
import { FooterInfor } from '@/interfaces/footer'
import { PopUp } from '@/components'
import {
  Layout,
  HeaderWrapper,
  MainWrapper,
  FooterWrapper,
} from './Layout.styles'
import Header from './Header'
import Footer from './Footer'
import CookieBanner from './CookieBanner'
import BlogHeader from './BlogHeader'
import CCPA from './CCPA'
import StickySubscribeBanner from '../Form/templates/StickySubscribeBanner'

// Type
import { IFormProps } from '@/interfaces/form'
import { IPopUpProps } from '@/interfaces/popUp'

// Hook
// import useScrollEffect from './useScrollEffect.hook'

interface LayoutComponentProps {
  navData: HeaderItemInterface[]
  blogNavData: HeaderItemInterface
  currentDomain: string
  hideHeaderFooter: boolean
  footerData: FooterInfor[]
  hasCookieBanner: boolean
  stickySubscribeBanner?: IFormProps
  popUp?: IPopUpProps
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({
  children,
  navData,
  blogNavData,
  footerData,
  currentDomain,
  hideHeaderFooter,
  hasCookieBanner,
  stickySubscribeBanner,
  popUp
}) => (
  <Layout>
    <HeaderWrapper hideHeaderFooter={hideHeaderFooter}>
      <Header
        headerItems={navData}
        currentDomain={currentDomain}
        hideHeaderFooter={hideHeaderFooter}
      />
      {popUp && <PopUp popUp={popUp} />}
      {blogNavData && <BlogHeader primaryNavigation={blogNavData} />}
      {stickySubscribeBanner && <StickySubscribeBanner {...stickySubscribeBanner} />}
    </HeaderWrapper>
    <MainWrapper>     
      {children}
    </MainWrapper>
    {
      !hideHeaderFooter &&
      <FooterWrapper>
        <Footer footerItems={navData} footerInfor={footerData?.[0]} />
      </FooterWrapper>
    }
    {hasCookieBanner && <CookieBanner currentDomain={currentDomain} />}
    <CCPA />
  </Layout>
)

export default LayoutComponent
