import styled from 'styled-components'

export const SECTION_WRAPPER_PADDING_DESKTOP = 80
export const SECTION_WRAPPER_PADDING_MOBILE = 32

const SECTION_MAX_WIDTH = 1148

// TODO: Remove other Containers inside SectionWrapper, this commit only removes the Container of Homepage's Content Types.
const SectionWrapper = styled.div`
  padding-top: ${SECTION_WRAPPER_PADDING_MOBILE}px;
  padding-bottom: ${SECTION_WRAPPER_PADDING_MOBILE}px;

  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${SECTION_WRAPPER_PADDING_DESKTOP}px;
    padding-bottom: ${SECTION_WRAPPER_PADDING_DESKTOP}px;

    // Center the content in the middle of the screen and limit the width.
    padding-left: calc(50% - ${SECTION_MAX_WIDTH / 2}px);
    padding-right: calc(50% - ${SECTION_MAX_WIDTH / 2}px);
  }
`

export default SectionWrapper
