import React from 'react'

import { excludeEmptyItem } from '@/utils'
import { HeaderItemInterface } from '@/interfaces/headerItem'
import { NavigationBar } from './NavigationBar.styles'

import HeaderItem from '../HeaderItem'

const NavigationBarComponent: React.FC<any> = ({ headerItems }) => {
  return (
    <NavigationBar>
      <ul>
        {excludeEmptyItem(headerItems)?.map((item: HeaderItemInterface) => (
          <HeaderItem key={item.sys?.id} headerItem={item} />
        ))}
      </ul>
    </NavigationBar>
  )
}

export default NavigationBarComponent
