import colors from './colors'
import typography from './typography'
import breakpoint from './breakpoint'

// TODO: spacing
const theme = {
  colors,
  ...typography,
  ...breakpoint,
}

export default theme
