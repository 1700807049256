import React from 'react'
import getConfig from 'next/config'
import { NextSeo } from 'next-seo'
import {
  ErrorPageName,
  ErrorPageTitle,
  ErrorPageWrapper,
  ErrorPageDescription,
  ErrorPageSubPoint,
  ErrorPageButton,
  ErrorPageContainer
} from './ErrorPage.styles'
import { errorCodes } from '@/constants'
import { errorMessages } from './constants/languages'

const { publicRuntimeConfig } = getConfig()

interface ErrorPageProps {
  code: string | number
  domain: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  code, domain
}) => {
  // Set default error code to 404 if code is not in errorCodes
  if (!Object.values(errorCodes).includes(code as errorCodes)) {
    code = errorCodes.ERROR_404
  }

  if (!errorMessages[domain]) {
    domain = publicRuntimeConfig.webDomain
  }

  const errorContent = errorMessages[domain]?.[code.toString()]
  return (
    <ErrorPageWrapper>
      <NextSeo
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicons/favicon-32x32.png',
            type:'image/png',
            sizes: '32x32',
          },
          {
            rel: 'icon',
            href: '/favicons/favicon-16x16.png',
            type:'image/png',
            sizes: '16x16',
          },
          {
            rel: 'apple-touch-icon',
            href: '/favicons/apple-touch-icon.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/favicons/safari-pinned-tab.svg',
            color: '#001B3A',
          },
          {
            rel: 'manifest',
            href: '/favicons/site.webmanifest',
          }
        ]}
      />
      <ErrorPageContainer>
        <ErrorPageName>{errorContent.errorCode}</ErrorPageName>
        <ErrorPageTitle>{errorContent.title}</ErrorPageTitle>
        <ErrorPageDescription>{errorContent.description}</ErrorPageDescription>
        {errorContent.subPoints?.map((subPoint: string, index: number)=> (
          <ErrorPageSubPoint key={index}>{subPoint}</ErrorPageSubPoint>
        ))}
        {errorContent.buttonText && <ErrorPageButton href='/' variant="secondary">{errorContent.buttonText}</ErrorPageButton>}
      </ErrorPageContainer>
    </ErrorPageWrapper>
  )
}

export default ErrorPage
