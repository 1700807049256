// TODO-Typo: DO NOT EDIT, current typo system is 'Terms condition' (not mapped yet)
import styled from 'styled-components'
import { CellColor } from '@/interfaces/table'

export const TableHeadCell = styled.th`
  padding: 16px 32px;
  background: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[20]};
  vertical-align: baseline;
  text-align: left;
  border: solid 1px ${({ theme }) => theme.colors.lightGray2};
  white-space: pre-wrap;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom: none;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom: none;
    border-left: none;
  }

  &:not(:first-child):not(:last-child) {
    border-left: none;
    border-bottom: none;
  }
`

export const TableCell = styled.td<{ color?: CellColor }>`
  min-width: 380.67px;
  padding: 16px 32px;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[20]};
  color: ${({ theme }) => theme.colors.darkGray};
  vertical-align: baseline;
  background: ${({ color, theme }) =>
    color === CellColor.BLUE ? theme.colors.lightBlue : theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.lightGray2};
  white-space: pre-wrap;

  &.bottom-left-cell {
    border-bottom-left-radius: 4px;
    border-left: solid 1px ${({ theme }) => theme.colors.lightGray2} !important;
  }

  &.first-col {
    border-left: solid 1px ${({ theme }) => theme.colors.lightGray2} !important;
  }

  &.bottom-left-merge-cell {
    border-left: solid 1px ${({ theme }) => theme.colors.lightGray2} !important;
    border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray2} !important;
    border-bottom-left-radius: 4px;
  }
`

export const TableRow = styled.tr`
  :last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child) ${TableCell}:not(:first-child) {
    border-bottom: none;
    border-left: none;
  }

  &:last-child ${TableCell}:not(:first-child) {
    border-left: none;
  }

  &:last-child ${TableCell}:first-child {
    border-left: none;
  }

  &:not(:last-child) > ${TableCell}:first-child {
    border-bottom: none;
    border-left: none;
  }
`

export const TableWrapper = styled.div`
  border-radius: 4px;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    border-spacing: 0;
    width: 100%;
  }

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    outline: none;
    border-radius: 4px;
  }
`
