import getConfig from 'next/config'
import en_error_messages from './en'
import de_error_messages from './de'

const { publicRuntimeConfig } = getConfig()

export const errorMessages:any = {
  [publicRuntimeConfig.webDomain] : en_error_messages,
  [publicRuntimeConfig.webDomainDE] : de_error_messages,
} 
