import { ContentItemInterface } from '@/interfaces/contentItem'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { AssetRenderer } from '@/components'
import {
  GridTitle,
  GridItemWrapper,
  GridDescription,
  GridImage,
  GridButton,
} from './GridItem.styles'

 enum GridType {
  IMAGE ='image',
  TITLE ='title',
  DESCRIPTION ='description',
  BUTTON ='button',
}

const GridItem: React.FC<ContentItemInterface> = ({
  title,
  image,
  description,
  backgroundColor,
  buttonLink,
  buttonText,
  buttonEventLabel,
  openInNewTab
}) => {

  const isRender = (type: GridType ) => {
    switch(type) {
      case GridType.IMAGE: 
        return !!image
      case GridType.TITLE: 
        return !image && title
      case GridType.DESCRIPTION: 
        return !image && description
      case GridType.BUTTON: 
        return !image && buttonLink && buttonText
      default: 
        return false
    }
  }

  return (
    <GridItemWrapper isImageBlock={!!image} backgroundColor={backgroundColor}>
      {isRender(GridType.IMAGE) && (
        <AssetRenderer asset={image} ImageComponent={GridImage} />
      )}
      {isRender(GridType.TITLE) && <GridTitle>{title}</GridTitle>}
      {isRender(GridType.DESCRIPTION) && <GridDescription>{description}</GridDescription>}
      {isRender(GridType.BUTTON) && <GridButton
        href={buttonLink}
        eventLabel={buttonEventLabel}
        openInNewTab={openInNewTab}
        buttonTheme={SectionThemeKey.Dark}
      >
        {buttonText}
      </GridButton> }
    </GridItemWrapper>
  )
}

export default GridItem
