import { RefObject } from 'react'
import SectionSeparator from '../SectionSeparator'
import { ContentItemsContainer } from './WrapperContentItemsContainer.styled'

interface IWrapperContentItemsContainer {
  withSeparator?: boolean
  style?: any
  forwardRef?: RefObject<HTMLDivElement>
  id?: string
}

const WrapperContentItemsContainer: React.FC<IWrapperContentItemsContainer> = ({
  withSeparator,
  style,
  children,
  forwardRef,
  id,
}) => (
  <>
    <ContentItemsContainer
      ref={forwardRef}
      style={style}
      id={id}
    >
      {children}
    </ContentItemsContainer>
    {withSeparator && <SectionSeparator />}
  </>
)

export default WrapperContentItemsContainer
