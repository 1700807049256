import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { IPopUpProps } from '@/interfaces/popUp'
import { CookieKey } from '@/constants'

const DURATION_BY_SECOND = 60 * 60 // 1min = 60s

interface PopUpData {
  showPopUp: boolean
  closePopUp: () => void
}

const usePopUp = ({ popUp }: { popUp: IPopUpProps }): PopUpData => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [isReturner] = useState(Cookies.get(CookieKey.isReturner) === 'true')

  useEffect(() => {
    const timer = popUp ?
      setTimeout(() => setShowPopUp(true), (isReturner ? popUp.returnUserTimer : popUp.newUserTimer) * 1000)
      :
      setTimeout(() => null, 0)
    return () => {
      clearTimeout(timer)
      setShowPopUp(false)
    }
  }, [popUp])

  useEffect(() => {
    document.body.setAttribute('style', `overflow: ${showPopUp ? 'hidden' : 'unset'};`)
  }, [showPopUp])

  useEffect(() => {
    return () => {
      document.body.setAttribute('style', 'overflow: unset;')
    }
  }, [])

  useEffect(() => {
    if (isReturner) return
    let onPageTimer = isNaN(Cookies.get(CookieKey.onPageTimer)) ? 0 : Number(Cookies.get(CookieKey.onPageTimer))

    const interval = setInterval(() => {
      Cookies.set(CookieKey.onPageTimer, ++onPageTimer)
      if (onPageTimer >= DURATION_BY_SECOND) {
        Cookies.set(CookieKey.isReturner, true)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return {
    showPopUp,
    closePopUp: () => setShowPopUp(false)
  }
}

export default usePopUp
