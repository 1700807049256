// Component
import {
  Wrapper,
  SmallTitle,
  Title,
  LogosWrapper,
  Logo,
  LogoImage,
  SecondaryButton,
} from './Static.styles'
import { AssetRenderer, LocalThemeProvider } from '@/components'
import { SectionSeparator } from '@/components/WrapperContentItems/components'

// Type
import { CarrierProps } from '@/interfaces/carrier'

// Util
import { excludeEmptyItem } from '@/utils'

// Hook
import { useHighlightString } from '@/hooks'

// Constant
import { lightTheme } from '@/constants'

const Static: React.FC<CarrierProps> = ({
  smallTitle,
  title,
  firstCarrierList,
  withSeparator,
  buttonLink,
  buttonText,
  buttonEventLabel,
  openInNewTab,
}) => {
  const allItems = excludeEmptyItem(firstCarrierList)
  const highlightedTitle = useHighlightString(title)

  return (
    <LocalThemeProvider theme={{ sectionTheme: lightTheme }}>
      <Wrapper>
        {smallTitle && <SmallTitle>{smallTitle}</SmallTitle>}
        {title && <Title>{highlightedTitle}</Title>}
        <LogosWrapper>
          {allItems?.map((item, index) => (
            <Logo key={index}>
              <AssetRenderer ImageComponent={LogoImage} asset={item} />
            </Logo>
          ))}
        </LogosWrapper>
        {buttonLink && buttonText && (
          <SecondaryButton
            href={buttonLink}
            eventLabel={buttonEventLabel}
            openInNewTab={openInNewTab}
          >
            {buttonText}
          </SecondaryButton>
        )}
      </Wrapper>
      {!withSeparator && <SectionSeparator />}
    </LocalThemeProvider>
  )
}

export default Static
