enum ThunkDebugName {
  GetRedisUtils = 'GET_REDIS_UTILS',
  SetCacheControl = 'SET_CACHE_CONTROL',
  GetServerPropsCached = 'GET_SERVER_PROPS_CACHED',
  CacheServerProps = 'CACHE_SERVER_PROPS',
  CheckIsPreviewMode = 'CHECK_IS_PREVIEW_MODE',
  GetPageUrl = 'GET_PAGE_URL',
  GetLocale = 'GET_LOCALE',
}

export default ThunkDebugName
