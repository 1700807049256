import { CustomEventName } from '@/constants'
import {
  resetGTMDataLayer, pushEvent, sendLinkedInAdsId 
} from '..'

export const GTMFormSubmission = (formLabel: string | undefined): void => {
  resetGTMDataLayer()
  const details = { formLabel }
  pushEvent(CustomEventName.FormSubmission, details)
}

export const GTMContactFormSubmission = (data: any): void => {
  resetGTMDataLayer()
  pushEvent(CustomEventName.ContactFormSubmission, data)
}

export const GTMLinkedInAdsIdSubmission = (linkedInAdsId: number): void => {
  sendLinkedInAdsId(linkedInAdsId)
}
