import {
  createContext, useEffect, useState 
} from 'react'
import { AccordionContainer } from './Accordion.styles'

export type PanelKey =  number | string | null

interface IAccordionContext {
  selectedPanel: PanelKey
  setSelectedPanel: (key: PanelKey) => void
}

export const AccordionContext = createContext<IAccordionContext>({
  selectedPanel: null, 
  setSelectedPanel: () => { return }
})

interface AccordionProps {
  parentId?: string
  isCollapseAll?: boolean
}

const Accordion:React.FC<AccordionProps> = ({
  parentId, isCollapseAll, children 
}) => {
  const [selectedPanel, setSelectedPanel] = useState<PanelKey>(null)

  useEffect(() => {
    parentId && document.getElementById(parentId)?.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth'
    })
  }, [selectedPanel, parentId])
  
  useEffect(() => {
    if(isCollapseAll && selectedPanel) setSelectedPanel(null)
  }, [isCollapseAll])

  return (
    <AccordionContext.Provider value={{ selectedPanel, setSelectedPanel }}>
      <AccordionContainer>
        {children}
      </AccordionContainer>
    </AccordionContext.Provider>
  )
}

export default Accordion
