// TODO-Typo: DO NOT EDIT MobileBlogTopicItem, not sure which font system
import { DynamicLink } from '@/components'
import styled from 'styled-components'

export const MobileBlogTopicList = styled.div`
  display: none;
  background: ${({ theme }) => theme.colors.white};
  width: calc(100% - 10px);
  margin: 0 auto;
  padding: 35px 30px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`

export const MobileBlogTopicItem = styled(DynamicLink)<{ isActive: boolean }>`
  font-weight: 900;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[18]};
  text-transform: uppercase;
  color: ${({ theme, isActive }) => isActive ? theme.colors.orange : theme.colors.darkBlue};
  margin-bottom: 35px;
  display: block;

  :last-child {
    margin-bottom: 0;
  }
`

export const MobileBlogHeaderWrapper = styled.div<{ isOpen: boolean }>`
  ${props => props?.isOpen && `
    ${MobileBlogTopicList} {
      display: block;
      position: absolute;
      top: calc(100% + 5px);
      left: 5px;
    }
  `}
`
