import { Asset, VideoContentType } from '@/interfaces/common'
import { isVideo } from '@/utils'
import { CSSProperties } from 'react'
import { CustomVideoContainer } from './Video.styles'

const Video: React.FC<{
  asset?: Asset
  height?: {
    mobile?: number | string
    desktop?: number | string
  }
  style?: CSSProperties
}> = (props) => {
  if (!props.asset || !isVideo(props.asset)) return null

  const {
    style,
    asset,
    height,
  } = props
  const { url, contentType } = asset

  return (
    <CustomVideoContainer
      noPaddingTop
      style={style}
      customHeight={height}
    >
      <video
        src={url}
        controls
        controlsList='nodownload'
      >
        {contentType && contentType !== VideoContentType.QUICKTIME && (
          <source src={url} type={contentType} />
        )}
      </video>
    </CustomVideoContainer>
  )
}

export default Video
