// TODO-Typo: DO NOT EDIT YET, not sure which typo system
import styled from 'styled-components'

export const Title = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const IconsWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`

export const Icon = styled.a.attrs({ target: '_blank' })`
  display: inline-block;
  height: 100%;

  :not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.orange};
    }
  }
`
