import { ReactNode, createElement } from 'react'

const renderHTML = (rawHTML: string, element = 'div'): ReactNode => createElement(
  element,
  {
    dangerouslySetInnerHTML: { __html: rawHTML },
  },
)

export default renderHTML
