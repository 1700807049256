import React from 'react'
import { useTheme } from 'styled-components'

// Component
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import LocalThemeProvider from '@/components/LocalThemeProvider'

// Type
import { BannerProps } from '@/interfaces/banner'

// Hook
import { useGlobalState } from '@/hooks'

// Util
import { getSectionTheme } from '@/utils'

import Default from './components/Default'
import Homepage from './components/Homepage'
import Experience from './components/Experience'

const AboveTheFold: React.FC<
  BannerProps & {
    noPadding?: boolean
    noLocalTheme?: boolean
  }
> = (props) => {
  const { noLocalTheme, theme } = props

  const { isHomepage } = useGlobalState()
  const { pageTheme } = useTheme()

  const renderAboveTheFold = () => {
    if (isHomepage) return <Homepage {...props} />
    if (pageTheme) return <Experience {...props} />
    return <Default {...props} />
  }

  if (noLocalTheme) {
    return <Default {...props} />
  }

  return (
    <LocalThemeProvider
      theme={{ sectionTheme: getSectionTheme(theme || SectionThemeKey.Light) }}
    >
      {renderAboveTheFold()}
    </LocalThemeProvider>
  )
}

export default AboveTheFold
