import { IPopUpProps } from '@/interfaces/popUp'
import { sortDatesBy } from '@/utils'

// If popups have directory = "/", use the latest published popup
// If popups have other directory, use the latest published popup
export const getMatchingPopUps = (
  pageSlug = '/',
  popUps: IPopUpProps[]
): IPopUpProps[] => {
  if (!popUps?.length) return []

  const directorySlug = `/${pageSlug.split('/')[1]}`
  const typedPopUps = popUps.reduce((acc, popUp) => {
    if (!popUp) return acc

    const directoryUrls = popUp.directoryUrls || []
    const exactUrls = popUp.exactUrls || []
    const isWholePagePopUp = directoryUrls?.some((url) => url === '/')
    const isDirectoryPopUp = directoryUrls?.some((url) => url === directorySlug)
    const isExactSlugPopUp = exactUrls?.some((url) => url === pageSlug)

    switch (true) {
      case isWholePagePopUp:
        acc.wholePagePopUps.push(popUp)
        break
      case isDirectoryPopUp:
        acc.directoryPopUps.push(popUp)
        break
      case isExactSlugPopUp:
        acc.exactSlugPopUps.push(popUp)
        break
    }

    return acc
  }, {
    wholePagePopUps: [] as IPopUpProps[],
    directoryPopUps: [] as IPopUpProps[],
    exactSlugPopUps: [] as IPopUpProps[],
  })

  const matchedPopUps = typedPopUps.wholePagePopUps?.length
    ? typedPopUps.wholePagePopUps
    : typedPopUps.directoryPopUps?.length
      ? typedPopUps.directoryPopUps
      : typedPopUps.exactSlugPopUps

  return sortDatesBy({
    arrayOfDates: matchedPopUps,
    sortByField: 'sys.publishedAt',
  })
}
