import { Document } from '@contentful/rich-text-types'

export enum FormUITemplateKeys {
  LeadsForm = 'Leads form',
  StickySubscribeBanner = 'Sticky subscribe banner',
}

export enum InputWidth {
  Full = 'Full-width',
  Half = 'Half-width',
}

export type FieldType = 'Input' | 'Email' | 'TextArea' | 'Checkbox'

export interface IFormField {
  name?: string
  label?: {
    json: Document
  }
  placeholder?: string
  isMandatory?: boolean
  isNonCompanyEmailDomainBlocked?: boolean
  type?: FieldType
  width?: InputWidth
  mandatoryCheckErrorMessage?: string
  emailDomainBlockedCheckErrorMessage?: string
  customErrorMessage?: string
}

export interface IFormProps {
  uiTemplate?: FormUITemplateKeys
  hubspotFormId?: string
  title?: string
  subCopy?: string
  eventLabel?: string
  formFieldList?: IFormField[]
  buttonText?: string
  buttonEventLabel?: string
  zapierHookId?: string
  thankYouTitle?: string
  thankYouMessage?: any
  linkedInAdsId?: number
  isPopUp?: boolean
}
