declare global {
  interface Window {
    dataLayer: any
    google_tag_manager: any
    lintrk: any
  }
}

// TODO: delete this function or the 1st line inside pushEvent() function
export const resetGTMDataLayer = (): void => {
  // window.google_tag_manager[publicRuntimeConfig.GTM_ID]?.dataLayer?.reset()
  // .reset() is dangerous since it's deleting the whole dataLayer, including the gtm.start event, and others
  window.dataLayer = window.dataLayer || []
}

export const pushEvent = (eventName: string, details?: Record<string, any>):void => {
  window.dataLayer = window.dataLayer || []

  if (window.google_tag_manager) {
    // Google Tag Manager has already been loaded
    window.dataLayer.push({
      event: eventName,
      ...details,
    })
  } else {
    window.addEventListener('gtm_loaded', function() {
      // Google Tag Manager has been loaded
      window.dataLayer.push({
        event: eventName,
        ...details,
      })
    })
  }
}

export const sendLinkedInAdsId = (linkedInAdsId: number): void => {
  if (window.lintrk) {
    window.lintrk('track', { conversion_id: linkedInAdsId })
  }
}
