import Image from 'next/image'
import { MenuItemInterface } from '@/interfaces/headerItem'
import {
  MenuItemDescription,
  MenuItemWrapper,
  MenuItemTitle,
  MenuItemInfo,
  ImageWrapper,
  ImagePlaceholder,
} from './MenuItem.styles'

const MenuItem: React.FC<MenuItemInterface> = (data) => {
  return (
    <MenuItemWrapper
      href={data.link}
      target={data.openInNewTab ? '_blank' : undefined}
    >
      {data.icon ? (
        <ImageWrapper>
          <Image
            src={data.icon.url}
            alt={data.icon.description}
            fill
            sizes='100vw'
          />
        </ImageWrapper>
      ) : (
        <ImagePlaceholder />
      )}
      <MenuItemInfo>
        <MenuItemTitle>{data.title}</MenuItemTitle>
        {data.description && (
          <MenuItemDescription>{data.description}</MenuItemDescription>
        )}
      </MenuItemInfo>
    </MenuItemWrapper>
  )
}

export default MenuItem
