import styled from 'styled-components'
import Button from '@/components/Button'
import { IconAlignment } from '@/interfaces/button.interfaces'
import ArrowForward from '../../../public/images/icons/arrow-forward.svg'

// NEW-BUTTON: Text - Product Experience - Header
export const BackToList = styled(Button).attrs({
  variant: 'link',
  icon: ArrowForward,
  iconAlignment: IconAlignment.Left,
})`
  margin-bottom: 32px;

  position: relative;
  padding: 4px 0;

  // Custom for only Text button
  padding: 4px 0 3px 0;
  border-radius: 0 !important;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.colors.darkBlue};

  & > span > svg,
  & > svg {
    transform: rotate(180deg);

    &, & path {
      fill: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  :not([disabled]):hover {
    color: ${({ theme }) => theme.colors.ppBlueHover} !important;
    border-color: ${({ theme }) => theme.colors.ppBlueHover} !important;

    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme }) => theme.colors.ppBlueHover} !important;
      }
    }
  }
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  margin: 16px 0;
  letter-spacing: -0,25px;
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const SocialSharingWrapper = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: unset;
    margin-left: 24px;
  }
`

export const SubHeaderWrapper = styled.div<{ hasTagList?: boolean }>`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const CreatedDate = styled.div<{ hasTagList?: boolean }>`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const TagListWrapper = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 8px;
  }
`
