import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const YMALWrapper = styled.div`
  padding: 50px 0;

  ${({ theme }) =>  theme.mediaBreakpointUp.md} {
    padding: 40px 0 50px 0;
    background: ${({ theme }) => theme.colors.white};
  }
`

export const YMALList = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 30px;
  justify-content: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 31px;
  }
`

export const YMALTitle = styled(Typography).attrs({
  type: 'h1',
  variant: TextStyles['Heading 2'],
})`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 30px;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    margin-bottom: 50px;
    text-align: left;
  }
`
