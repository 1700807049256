import styled from 'styled-components'
import { 
  LeadFormWrapper, 
  ThankYouCard, 
  Title 
} from '../Form/templates/LeadForm/LeadForm.styles'
import Close from '../../public/images/close-pop-up.svg'

export const PopUpWrapper = styled.div<{ showPopUp: boolean }>`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: ${({ showPopUp }) => showPopUp? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1000;
`

export const ImageWrapper = styled.div`
  width: 390px;
  height: 100%;
  position: relative;
  display: none
`

export const FormWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 25px;

  ${LeadFormWrapper} {
    padding: 0;
    box-shadow: none;
    height: 100%;
    align-content: flex-start;
    overflow-y: auto;

    ${Title} {
      line-height: ${({ theme }) => theme.lineHeight[35]};
    }

    ${ThankYouCard} {
      height: 100%;
    }

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      min-width: 440px;
    }
  }
`

export const PopUpContainer = styled.div<{ isImage: boolean }>`
  width: calc(100% - 60px);
  height: 522px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  display:flex;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  cursor: default;

  ${FormWrapper} {
    ${({ isImage }) => isImage ? 'max-width: 510px' : 'width: 100%'};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ isImage }) => isImage ? 'height: 467px' : 'height: 432px'};
    ${({ isImage }) => isImage ? 'max-width: 901px' : 'max-width: 700px'};
    width: fit-content;
    min-width: 700px;
    top: 91px;

    ${ImageWrapper} {
      display: block
    }
  }
`

export const ClosePopUp = styled(Close)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
