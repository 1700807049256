import { SectionThemeKey } from './sectionThemeKey'

export enum IconAlignment {
  Left = 'Left',
  Right = 'Right',
}

export interface ButtonProps {
  children?: any
  // TODO: replace with an enum ButtonVariant
  variant?: 'primary' | 'secondary' | 'link' | 'icon' | 'hyperlink'
  onClick?: () => void
  href?: string
  eventLabel?: string
  openInNewTab?: boolean
  // TODO: replace with an enum ButtonSize
  size?: 'small' | 'normal'
  // TODO: replace with an enum ButtonType
  type?: 'button' | 'submit' | 'reset'
  icon?: any
  disabled?: boolean
  style?: any
  className?: string
  buttonTheme?: SectionThemeKey
  iconAlignment?: IconAlignment
}
