import React from 'react'
import { SearchWrapper } from '../CarriersList.styles'
import { SearchIcon, SearchInput } from './CarrierSearch.styles'

interface CarrierSearchInputProps {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchPlaceholder?: string
}

const CarrierSearchInput: React.FC<CarrierSearchInputProps> = ({ onSearch, searchPlaceholder }) => {
  return (
    <SearchWrapper>
      <SearchInput onChange={onSearch} placeholder={searchPlaceholder || 'Search'} />
      <SearchIcon src='/images/icons/search.svg' alt='' />
    </SearchWrapper>
  )
}

export default CarrierSearchInput
