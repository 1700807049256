import { DynamicLink } from '@/components'
import AngleDown from '../../../../../public/images/icons/keyboard-arrow-down.svg'
import styled from 'styled-components'

export const HeaderTitleArrow = styled(AngleDown)`
  transition: .5s ease-in-out;
  margin-left: 4px;
`

export const HeaderItemText = styled.div`
  color: white;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`

export const HeaderItemLink = styled(DynamicLink)`
  ${HeaderTitle} {
    cursor: pointer;
  }
`
