import styled from 'styled-components'

export const NavigationBar = styled.nav`
  flex-grow: 1;
  padding-left: 56px;
  display: none;

  ul {
    list-style-type: none;
    display: flex;
    padding-left: 0;
  }

  ${({ theme }) =>  theme.mediaBreakpointUp.md} {
    display: block;
  }
`
