import { FC } from 'react'
import { IEvent } from '@/interfaces/eventsSection'

// Components
import Thumbnail from '../Thumbnail'

// Styles
import {
  ResourceSubSectionWrapper,
  SectionTitle
} from './ResourceSubSection.styles'
import Container from '../Container'
import {
  CategoryContainer,
  ThumbnailsItemListWrapper,
  ThumbnailsWrapper
} from '../Category/Category.styles'

export interface IResourceSubSection {
  title: string
}

const ResourceSubSection: FC<IResourceSubSection & { events: IEvent[] }> = ({
  title,
  events,
}) => {
  return (
    <ResourceSubSectionWrapper>
      <Container>
        <SectionTitle>{title}</SectionTitle>
      </Container>
      {!!events?.length && (
        <ThumbnailsWrapper>
          <CategoryContainer>
            <ThumbnailsItemListWrapper>
              {events?.map(
                (eventThumbnail: IEvent, index: number) =>
                  <Thumbnail
                    key={index}
                    {...eventThumbnail}
                    heroImage={eventThumbnail.image}
                    startDate=''
                    date=''
                    description={index === 0 ? eventThumbnail.description : ''}
                  />
              )}
            </ThumbnailsItemListWrapper>
          </CategoryContainer>
        </ThumbnailsWrapper>
      )}
    </ResourceSubSectionWrapper>
  )
}

export default ResourceSubSection
