import styled from 'styled-components'
import { defaultSectionTheme } from '../../../constants'
import { FilledButtonAsLink } from '../Button.styles'

export const ButtonLink = styled(FilledButtonAsLink)`
  border: 1px solid ${({ theme, buttonTheme }) =>
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.primaryButton.background
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.background
          : defaultSectionTheme.primaryButton.background};
  background: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      :  buttonTheme
        ? buttonTheme.primaryButton.background
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.background
          : defaultSectionTheme.primaryButton.background};

  color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.white
      : buttonTheme
        ? buttonTheme.primaryButton.text
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.text
          : defaultSectionTheme.primaryButton.text};

  :not([disabled]):hover {
    border: 1px solid ${({ theme, buttonTheme }) =>
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.primaryButton.hoverBackground
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.hoverBackground
          : defaultSectionTheme.primaryButton.hoverBackground};
    background: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.primaryButton.hoverBackground
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.hoverBackground
          : defaultSectionTheme.primaryButton.hoverBackground};

    color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.white
      : buttonTheme
        ? buttonTheme.primaryButton.hoverText
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.hoverText
          : defaultSectionTheme.primaryButton.hoverText};
  }

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.primaryButton.text
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.text
          : defaultSectionTheme.primaryButton.text};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.white
      : buttonTheme
        ? buttonTheme.primaryButton.hoverText
        : theme.sectionTheme
          ? theme.sectionTheme.primaryButton.hoverText
          : defaultSectionTheme.primaryButton.hoverText};
      }
  }

  &[disabled] {
    opacity: 1 !important;
    background: ${({ theme }) => theme.colors.lightGray3};
  } 
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
