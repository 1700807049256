import {
  useEffect, useRef, useState 
} from 'react'
import { useWindowSize } from '@/hooks'
import { ArticleProps, IPmArticle } from '@/interfaces/article'
import { CategoryProps, CategoryTypeKeys } from '@/interfaces/category'
import {
  checkIsPreviewModeRequest,
  excludeEmptyItem,
  getLatestArticles,
  getLatestTopicTagRelatedArticles,
} from '@/utils'
import FeaturedArticle from './FeaturedArticle'
import Title from './Title'
import { Container, LoadMoreButton } from '@/components'
import {
  CategoryBackgroundContainer,
  PmFeedBackgroundContainer,
  PmFeedWrapper,
  PmFeedTitle,
  CategoryContainer,
  ThumbnailsWrapper,
  ThumbnailsItemListWrapper,
  SEOFiller,
} from './Category.styles'
import { Locales, SlugLocales } from '@/interfaces/locales'
import Thumbnail from '../Thumbnail'
import PmArticle from '../PmArticle'
import LeadsWrapper from '../LeadsWrapper'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useRouter } from 'next/router'
import { callPublicContentful } from '@/fetchers/contentfulAPI/base.fetchers'
import {
  FIRST_THUMBNAIL_LIST_COUNT_DESKTOP,
  FIRST_THUMBNAIL_LIST_COUNT_MOBILE,
  FIRST_THUMBNAIL_LIST_COUNT_DESKTOP_WITH_PM_FEED,
  SECOND_THUMBNAIL_LIST_COUNT_DESKTOP,
  SECOND_THUMBNAIL_LIST_COUNT_MOBILE,
  NUM_OF_PM_ARTICLES,
} from '@/constants'

interface CategoryExtendedProps extends CategoryProps {
  articles: Array<ArticleProps>
  lang: string
  locale: Locales
  latestArticlesLength: number
  pmArticles: IPmArticle[] | null
}

const MOBILE_BREAKPOINT = 599

const Category: React.FC<CategoryExtendedProps> = (props) => {
  const {
    title,
    pageSlug,
    type,
    leadsWrapperSection,
    articles,
    pmFeedTitle,
    pmArticles,
    seoFiller,
    locale,
    lang,
    latestArticlesLength,
  } = props
  const isCategoryPage = pageSlug === '/' || Object.values<string>(SlugLocales).includes(pageSlug)
  const featuredArticleData = articles && articles.length > 0 && articles[0]
  const filteredListWrapperSection = excludeEmptyItem(leadsWrapperSection)
  const initialThumbnailList = articles?.slice(1)
  const { width } = useWindowSize()
  const [allThumbnailList, setAllThumbnailList] = useState<ArticleProps[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const isAllThumbnailListSuccessfullyFetched = useRef(false)
  const isShowPmFeed = pmArticles && pmArticles.length === NUM_OF_PM_ARTICLES

  // If tablet is needed, update later
  const isDesktop = width > MOBILE_BREAKPOINT

  const FIRST_THUMBNAIL_COUNT = isDesktop
    ? isShowPmFeed
      ? FIRST_THUMBNAIL_LIST_COUNT_DESKTOP_WITH_PM_FEED
      : FIRST_THUMBNAIL_LIST_COUNT_DESKTOP
    : FIRST_THUMBNAIL_LIST_COUNT_MOBILE

  const SECOND_THUMBNAIL_COUNT = isDesktop
    ? SECOND_THUMBNAIL_LIST_COUNT_DESKTOP
    : SECOND_THUMBNAIL_LIST_COUNT_MOBILE

  const BUTTON_THUMBNAIL_COUNT = 9

  // Thumbnail section
  const firstThumbnailList = initialThumbnailList?.slice(
    0,
    FIRST_THUMBNAIL_COUNT
  )

  const initializeSecondThumbnailList = () => {
    return initialThumbnailList?.slice(
      FIRST_THUMBNAIL_COUNT,
      FIRST_THUMBNAIL_COUNT + SECOND_THUMBNAIL_COUNT,
    )
  }

  const [secondThumbnailList, setSecondThumbnailList] = useState(initializeSecondThumbnailList)

  useEffect(() => {
    setSecondThumbnailList(initializeSecondThumbnailList)
  }, [isDesktop])

  const { query } = useRouter()

  const fetchAllThumbnailList = async (): Promise<ArticleProps[]> => {
    setIsFetching(true)
    const isPreviewMode = checkIsPreviewModeRequest(query)
    let allThumbnailList: ArticleProps[]

    if (pageSlug === '/') {
      allThumbnailList = await getLatestArticles({
        isPreviewMode,
        locale,
        lang,
        fetcher: callPublicContentful,
      })
    } else {
      switch (type) {
        case CategoryTypeKeys.Topic:
          allThumbnailList = await getLatestTopicTagRelatedArticles({
            isPreviewMode,
            locale,
            type,
            pageSlug,
            fetcher: callPublicContentful,
          })
          break
        default:
          allThumbnailList = await getLatestTopicTagRelatedArticles({
            isPreviewMode,
            locale,
            type,
            pageSlug,
            fetcher: callPublicContentful,
          })
      }
    }

    setAllThumbnailList(allThumbnailList)
    setIsFetching(false)

    return allThumbnailList
  }

  const currentDisplayingThumbnailCount
    = 1 + firstThumbnailList.length + secondThumbnailList.length

  const hasNextPage = currentDisplayingThumbnailCount < latestArticlesLength

  const appendThumbnailsToSecondList = (allThumbnailList: ArticleProps[]): void => {
    setSecondThumbnailList(prevSecondThumbnailList => {
      const endIndex = currentDisplayingThumbnailCount + BUTTON_THUMBNAIL_COUNT

      return [
        ...prevSecondThumbnailList,
        ...allThumbnailList.slice(currentDisplayingThumbnailCount, endIndex)
      ]
    })
  }

  const handleLoadMoreButtonClick = () => {
    if (!isAllThumbnailListSuccessfullyFetched.current) {
      fetchAllThumbnailList().then(allThumbnailList => {
        appendThumbnailsToSecondList(allThumbnailList)
        isAllThumbnailListSuccessfullyFetched.current = true
      })
    }
    
    if (!allThumbnailList) return

    appendThumbnailsToSecondList(allThumbnailList)
  }

  return (
    <CategoryBackgroundContainer>
      <CategoryContainer>
        {title && !isCategoryPage && <Title type={type}>{title}</Title>}
        {featuredArticleData && <FeaturedArticle {...featuredArticleData} />}
        {
          firstThumbnailList?.length > 0 &&
          <ThumbnailsWrapper>
            <ThumbnailsItemListWrapper>
              {firstThumbnailList
                .slice(0, 3)
                .map((articleThumbnail: ArticleProps, index: number) => (
                  <Thumbnail {...articleThumbnail} key={index} />
                ))}
            </ThumbnailsItemListWrapper>
          </ThumbnailsWrapper>
        }
      </CategoryContainer>
      {isShowPmFeed && (
        <PmFeedBackgroundContainer>
          <Container>
            <PmFeedWrapper>
              <PmFeedTitle>{pmFeedTitle}</PmFeedTitle>
              <ThumbnailsItemListWrapper>
                {pmArticles?.map((article: IPmArticle, index: number) => (
                  <PmArticle {...article} key={index} />
                ))}
              </ThumbnailsItemListWrapper>
            </PmFeedWrapper>
          </Container>
        </PmFeedBackgroundContainer>
      )}
      <CategoryContainer>
        <ThumbnailsWrapper noPaddingTop={!isShowPmFeed}>
          <ThumbnailsItemListWrapper>
            {firstThumbnailList
              .slice(3, FIRST_THUMBNAIL_COUNT)
              .map((articleThumbnail: ArticleProps, index: number) => (
                <Thumbnail {...articleThumbnail} key={index} />
              ))}
          </ThumbnailsItemListWrapper>
        </ThumbnailsWrapper>
      </CategoryContainer>
      {
        filteredListWrapperSection &&
        filteredListWrapperSection.length > 0
          && <LeadsWrapper {...filteredListWrapperSection[0]}></LeadsWrapper>
      }
      {
        secondThumbnailList && secondThumbnailList.length > 0 &&
        <ThumbnailsWrapper>
          <CategoryContainer>
            <ThumbnailsItemListWrapper>
              {secondThumbnailList.map(
                (articleThumbnail: ArticleProps, index: number) => 
                  <Thumbnail 
                    {...articleThumbnail}
                    key={index} 
                  />
              )}
            </ThumbnailsItemListWrapper>
            {
              hasNextPage &&
              <LoadMoreButton
                disabled={isFetching}
                variant='secondary'
                onClick={handleLoadMoreButtonClick}
              >
                Load Older Posts
              </LoadMoreButton>
            }
          </CategoryContainer>
        </ThumbnailsWrapper>
      }
      {
        filteredListWrapperSection &&
        filteredListWrapperSection.length > 1
          && <LeadsWrapper {...filteredListWrapperSection[1]}></LeadsWrapper>
      }
      {seoFiller && (
        <SEOFiller>
          <Container>{documentToReactComponents(seoFiller.json, {
            renderText: (text) => {
              if (text[text.length-1] === '\x0a') {
                return <>
                  <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
                  <pre />
                </>
              }
              if (text === '\n') {
                return <pre />
              }
              return <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
            },
          })}</Container>
        </SEOFiller>
      )}
    </CategoryBackgroundContainer>
  )
}

export default Category
