import {
  useState,
  useRef,
  FormEventHandler,
  useCallback,
} from 'react'
import { EMAIL_PATTERN } from '@pperform/pp-constants/validation'
import { postSubmitForm } from '@/fetchers'
import { GTMFormSubmission, excludeUndefinedKey } from '@/utils'
import { useReCaptcha } from '@/hooks'
import { useGlobalState } from '@/hooks'
import { UTMKeys } from '@/constants'

export const ERROR_MESSAGES = {
  EMAIL_EMPTY: 'This field can\'t be left blank',
  EMAIL_INVALID: 'Email format must be in email@example.com',
  NOT_CHECKING_CHECKBOX: 'Please check this box to proceed',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSubscribeForm = ({
  zapierHookId,
  eventLabel
}: {
  zapierHookId: string | undefined
  eventLabel: string | undefined
}) => {
  const [agreeToReceiveEmails, setAgreeToReceiveEmails] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [checkboxError, setCheckboxError] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)

  const handleToggleAgreement = useCallback(
    () => setAgreeToReceiveEmails((prevAgreeToReceiveEmails) => !prevAgreeToReceiveEmails),
    [],
  )

  const {
    handleReCaptchaVerify,
  } = useReCaptcha()

  const {
    pageUrl,
    geoInfo,
    pageParams,
  } = useGlobalState()

  const handleSubmit: FormEventHandler = useCallback(async (e) => {
    e.preventDefault()

    setIsSubmitting(true)

    const emailInput = emailInputRef?.current?.value.trim() || ''

    const isEmailEmpty = !emailInput
    const isEmailInvalid = !EMAIL_PATTERN.test(emailInput)
    const isNotAgreeToReceiveEmails = !agreeToReceiveEmails

    setCheckboxError(isNotAgreeToReceiveEmails ? ERROR_MESSAGES.NOT_CHECKING_CHECKBOX : '')

    if (isEmailEmpty) {
      setEmailError(ERROR_MESSAGES.EMAIL_EMPTY)
    } else if (isEmailInvalid) {
      setEmailError(ERROR_MESSAGES.EMAIL_INVALID)
    } else {
      setEmailError('')
    }

    if (isEmailEmpty || isEmailInvalid || isNotAgreeToReceiveEmails) {
      setIsSubmitting(false)
      return
    }

    if (!zapierHookId) {
      setIsSubmitting(false)
      return
    }

    const captchaCode = await handleReCaptchaVerify()

    if (!captchaCode) {
      console.error('reCAPTCHA verify failed')
      setIsSubmitting(false)
      return
    }

    try {
      const utmData = excludeUndefinedKey({
        [UTMKeys.utmCampaign]: pageParams[UTMKeys.utmCampaign],
        [UTMKeys.utmContent]: pageParams[UTMKeys.utmContent],
        [UTMKeys.utmMedium]: pageParams[UTMKeys.utmMedium],
        [UTMKeys.utmSource]: pageParams[UTMKeys.utmSource],
        [UTMKeys.utmTerm]: pageParams[UTMKeys.utmTerm],
      })

      let submitData: any = {
        email: emailInput,
        pageUrl,
        city: geoInfo?.city,
        country: geoInfo?.country,
        region: geoInfo?.timezone?.split('/')[0],
      }

      if (utmData) {
        submitData = {
          ...submitData,
          ...utmData,
        }
      }

      const response = await postSubmitForm({
        data: submitData,
        zapierHookId,
        captchaCode,
      })
      if (response?.ok) {
        setIsSubmitted(true)
        if (eventLabel) GTMFormSubmission(eventLabel)
      }
    } finally {
      setIsSubmitting(false)
    }
  }, [zapierHookId, eventLabel, agreeToReceiveEmails, handleReCaptchaVerify])

  return {
    agreeToReceiveEmails,
    emailError,
    checkboxError,
    isSubmitted,
    isSubmitting,
    emailInputRef,
    handleToggleAgreement,
    handleSubmit,
  }
}

export default useSubscribeForm
