import styled from 'styled-components'
import { TextStyles } from '@/constants'
import { styledText } from '@/components/Typography/Typography.styles'

const ErrorMessage = styled.div`
  margin-bottom: 5.4px;
  margin-top: 5.4px;
  min-height: 14.2px;
  ${styledText[TextStyles['Caption Text']]}
  color: ${({ theme }) => theme.colors.red};
`

export default ErrorMessage
