import styled from 'styled-components'

export const CarriersFilters = styled.div`
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
 
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 60px auto;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 410px 410px 208px;
    grid-column-gap: 60px;
  }
`

export const CarrierAmount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[25]};
  margin-right: 4px;
`

export const CarrierAmountDescription = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[25]};
`

export const CarrierAmountWrapper = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.sectionTheme?.subText};
`

export const SearchWrapper = styled.span`
  position: relative;
  margin-bottom: 30px;
  border-radius: 3px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0px;
  }
`

export const CarriersListWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 30px 33px;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 50px 124px;
  }
`

export const CarrierList = styled.div`
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 27.5px;
  grid-template-columns: repeat(3, minmax(65px, 65px));
  justify-content: center;
  padding-bottom: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-row-gap: 60px;
    grid-column-gap: 65px;
    grid-template-columns: repeat(5, minmax(128px, 128px));
    padding-bottom: 60px;
  }
`

export const PressList = styled.div`
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: repeat(1, minmax(100%, 100%));
  justify-content: center;
  padding-bottom: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-row-gap: 60px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, minmax(270px, 270px));
    padding-bottom: 60px;
  }
`
