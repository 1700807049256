import { FC, useState } from 'react'
import { IEvent, IEventsSection } from '@/interfaces/eventsSection'
import {
  Container,
  Thumbnail,
  LoadMoreButton 
} from '@/components'
import {
  CategoryContainer,
  ThumbnailsWrapper,
  ThumbnailsItemListWrapper,
} from '../Category/Category.styles'
import { PrimaryTitle, WrapperContentItemsContainer } from '../WrapperContentItems/components'
import { EventsSectionWrapper } from './EventsSection.style'

const COUNT = 3

const EventsSection: FC<IEventsSection & { events: IEvent[] }> = ({
  title,
  buttonText,
  withSeparator,
  showLocation,
  showDate, 
  events
}) => {
  const [thumbnailList, setThumbnailList] = useState(events?.slice(0, COUNT) || [])

  const handleLoadMore = () =>  {
    if (!events?.length) return

    setThumbnailList(prev => prev.concat(
      events.slice(
        thumbnailList.length,
        thumbnailList.length + COUNT
      )
    ))
  }

  return (
    <EventsSectionWrapper>
      <WrapperContentItemsContainer withSeparator={withSeparator}>
        <Container>
          <PrimaryTitle noMarginBottom>{title}</PrimaryTitle>
        </Container>
        {!!thumbnailList?.length && (
          <ThumbnailsWrapper>
            <CategoryContainer>
              <ThumbnailsItemListWrapper>
                {thumbnailList?.map(
                  (eventThumbnail: IEvent, index: number) => 
                    <Thumbnail
                      key={index}
                      {...eventThumbnail}
                      heroImage={eventThumbnail.image}
                      startDate={showDate ? eventThumbnail.startDate : ''}
                      location={showLocation ? eventThumbnail?.location : ''}
                      openInNewTab={eventThumbnail.openInNewTab}
                    />
                )}
              </ThumbnailsItemListWrapper>
              {!!events?.length && thumbnailList?.length < events?.length &&
              <LoadMoreButton onClick={handleLoadMore}>
                {buttonText || 'Load Older Posts'}
              </LoadMoreButton>
              }
            </CategoryContainer>
          </ThumbnailsWrapper>
        )}
      </WrapperContentItemsContainer>
    </EventsSectionWrapper>
  )
}

export default EventsSection
