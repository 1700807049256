import { ArticleProps } from '@/interfaces/article'
import { RssChannel, RssItem } from '@/interfaces/rss.interfaces'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { convertISOtoUTC } from './dateTime.utils'

type ConvertToRssData = (args: {
  articles: ArticleProps[]
  baseUrl: string
  channel: RssChannel
}) => RssItem[]

export const convertToRssData: ConvertToRssData = ({
  articles,
  baseUrl,
  channel,
}) => {
  return articles?.map((article) => {
    const richText = article.content
      ? documentToHtmlString(article?.content.json)
      : ''

    return {
      title: article.title,
      link: baseUrl + '/insights' + article.articleSlug,
      pubDate: article.date && convertISOtoUTC(article.date),
      guid: article.sys.id,
      description: article.thumbnailCopy,
      imageUrl: article.heroImage?.url,
      content: richText,
      source: {
        url: channel.url,
        title: channel.title,
      },
    }
  })
}

export const articlesRssXml = (articles: RssItem[] | null) => {
  let rssItemsXml = ''
  articles?.forEach((item) => {
    rssItemsXml += `
      <item>
        <title><![CDATA[${item.title}]]></title>
        <link><![CDATA[${item.link}]]></link>
        <pubDate>${item.pubDate}</pubDate>
        <guid isPermaLink='false'>${item.guid}</guid>
        <description><![CDATA[${item.description}]]></description>
        <content:encoded>
          <![CDATA[<img src='${item.imageUrl}' />${item.content}]]>
        </content:encoded>
    </item>`
  })

  return { rssItemsXml }
}
