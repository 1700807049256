import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Container } from '@/components'
import MobileBlogHeader from './MobileBlogHeader/MobileBlogHeader'
import DesktopBlogHeader from './DesktopBlogHeader/DesktopBlogHeader'
import { HeaderItemInterface } from '@/interfaces/headerItem'
import { useWindowSize } from '@/hooks'
import {
  BlogHeaderNavigation,
  BlogHeaderWrapper,
  InsightsLogo 
} from './BlogHeader.styles'

export interface BlogHeaderProps {
  primaryNavigation: HeaderItemInterface
}

const BlogHeader: React.FC<BlogHeaderProps> = ({ primaryNavigation }) => {
  const { width } = useWindowSize()
  const { asPath } = useRouter()

  return (
    <BlogHeaderNavigation>
      <Container>
        <BlogHeaderWrapper>
          <Link
            href='/insights'
            passHref
            legacyBehavior
          >
            <InsightsLogo>
              <img
                src='/images/insights-logo.svg'
                alt='Insights logo'
              />
            </InsightsLogo>
          </Link>
          {
            primaryNavigation && (
              width < 600  
                ? <MobileBlogHeader data={primaryNavigation} activeTopicPath={asPath} /> 
                : <DesktopBlogHeader data={primaryNavigation} activeTopicPath={asPath} />
            )
          }
        </BlogHeaderWrapper>
      </Container>
    </BlogHeaderNavigation>
  )
}

export default BlogHeader
