/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useSpring } from 'react-spring'

export const useHeroImageSpring = (isAnimationStopped: boolean, isFirstItem?: boolean) => {
  const heroImageSpring = useSpring({
    from: {
      opacity: 0,
      translateY: '50px',
    },
    to: async (next) => {
      if (isFirstItem) {
        await new Promise((resolve) => {
          setTimeout(() => resolve(null), 290)
        })
      }
      
      await next({
        opacity: 1,
        translateY: '0px',
      })

      if (!isAnimationStopped) {
        await new Promise((resolve) => {
          setTimeout(() => resolve(null), 1500)
        })
      
        await next({
          opacity: 0,
          translateY: '50px',
        })
      }
    },
    reset: true,
  })

  return heroImageSpring
}

export const useHighlightedSubCopySpring = (isFirstItem: boolean) => useSpring({
  from: {
    translateY: '65px',
  },
  to: async (next) => {
    if (isFirstItem) {
      await new Promise((resolve) => {
        setTimeout(() => resolve(null), 290)
      })
    }
    await next({
      translateY: '0px',
    })
    await new Promise((resolve) => {
      setTimeout(() => resolve(null), 1500)
    })
  },
  reset: true,
})
