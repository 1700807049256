import styled, { css } from 'styled-components'

export const MobileHeaderWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
`

export const HamburgerButtonLine = styled.div`
  width: 30px;
  height: 3px;
  margin: 3px auto;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.white};
  transition: .4s;

  :first-child {
    transform-origin: 0% 0%;
  }
  
  :last-child {
    transform-origin: 0% 100%;
  }
`

export const HamburgerButton = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 21px;
  width: 30px;

  ${props => props.isExpanded && css`
    ${HamburgerButtonLine} {
      :first-child {
        transform: rotate(44deg) translate(3px,-2px);
        width: 23px;
      }
      
      :last-child {
        transform: rotate(-41deg) translate(4px,0px);
        width: 23px;
      }

      :nth-child(2) {
        opacity: 0;
        width: 23px;
      }
    }
  `}
`
