// TODO-Typo: DO NOT EDIT, belongs to Form font system (not mapped yet)
import styled from 'styled-components'

import { BaseInputWrapper } from '../..'

import { InputWidth } from '@/interfaces/form'

export const TextInputWrapper = styled(BaseInputWrapper)<{ width?: InputWidth }>`
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
`

export const TextInput = styled.input.attrs(
  ({ type }) => ({ type: type || 'text' }),
)`
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray2};
  }
`
