import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import { styledText } from '@/components/Typography/Typography.styles'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography/Typography'

export const HeaderWrapper = styled.div<{ isDesktop: boolean }>`
  display: ${({ isDesktop }) => isDesktop ? 'none' : 'block'};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => isDesktop ? 'block' : 'none'};
  }
`

export const Header = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})<{ isCenterAlign: boolean }>`
  margin-bottom: 30px;
  margin-top: 0;
  color: ${({ theme }) => theme.pageTheme ? theme.colors.darkBlue : theme.sectionTheme?.text};
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Large Display Font']]}

    ${({ isCenterAlign }) => !isCenterAlign && css`
      text-align: left;
    `}
  }
`

export const HighlightedHeader = styled.span`
  color: ${({ theme }) => theme.colors.orange};
  display: inline;

  span {
    display: inline-flex;
  }
`

export const HighlightedWord = styled.span`
  display: inline-block;
  height: 40px;
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 60px;
  }
`

export const AnimatedWord = styled(animated.span)``

export const MobileBr = styled.br`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`
