import { documentToReactComponentsWithBr } from '@/utils'
import { INLINES } from '@contentful/rich-text-types'
import { Options as RenderDocumentOptions } from '@contentful/rich-text-react-renderer'
import React from 'react'
import { NotFoundMessage } from './NotFound.styles'
import { DynamicLink } from '@/components'

interface NotFoundProps {
  message?: any
  isCarrier?: boolean
}

const NotFound: React.FC<NotFoundProps> = ({ message, isCarrier }) => {
  const options: RenderDocumentOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <DynamicLink
          href={data.uri}
          variant='hyperlink'
        >
          {children}
        </DynamicLink>
      )
    }
  }

  return (
    <NotFoundMessage isCarrier={isCarrier}>
      {documentToReactComponentsWithBr(message?.json, options)}
    </NotFoundMessage>
  )
}

export default NotFound
