import styled, { css } from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'

const imageShape = css`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 4px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 40px;
    height: 40px;
  }
`

export const ImageWrapper = styled.div`
  ${imageShape}
  position: relative;
  overflow: hidden;
  flex: none;
`

export const MenuItemTitle = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 5'],
})`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black01};
`

export const MenuItemDescription = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black02};
  margin-top: 4px;
`

export const MenuItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 23px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-height: 26px;
  }
`

export const MenuItemWrapper = styled.a`
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
`

export const ImagePlaceholder = styled.div`
  ${imageShape}
  background-color: ${({ theme }) => theme.colors.black05};
`
