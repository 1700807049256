// TODO-Typo: DO NOT EDIT, belongs to Form font system (not mapped yet)
import styled from 'styled-components'

const Label = styled.label<{ isMandatory?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[20]};
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.darkBlue};
  display: block;

  ${({ isMandatory }) => isMandatory && `
    &::after {
      content: ' *';
    }
  `}

  > *:first-child {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }
`

export default Label
