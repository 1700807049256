import { ALL_RESOURCE_PAGES } from '@/constants'
import { ISEOFields } from '@/interfaces/head'
import callContentful from './base.fetchers'

const createGetFullPageSlugsFetcher = (where: string) => async ({
  isPreviewMode = false,
}: {
  isPreviewMode?: boolean
}): Promise<string[]> => {
  const query = `
    {
      fullPageCollection(
        preview: ${isPreviewMode}
        where: ${where}
        limit: 2700
      ) {
        items {
          pageSlug
          seo {
            robots
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)

  return response.data?.fullPageCollection?.items
    ?.filter((fullPage: { seo: ISEOFields }) => !fullPage.seo?.robots?.includes('noindex'))
    ?.map((fullPage: { pageSlug: string }) => fullPage.pageSlug)
    ?? []
}

export const getAllProductsSlugs = createGetFullPageSlugsFetcher(`{
  AND: [
    { pageSlug_contains: "/products" }
    { pageSlug_not: "/" }
  ]
}`)

export const getAllIntegrationsSlugs = createGetFullPageSlugsFetcher(`{
  AND: [
    { pageSlug_contains: "/integrations" }
    { pageSlug_not: "/" }
  ]
}`)

export const getAllCompanySlugs = createGetFullPageSlugsFetcher(`{
  AND: [
    { pageSlug_contains: "/company" }
    { pageSlug_not: "/" }
  ]
}`)

export const getAllLeadsSlugs = createGetFullPageSlugsFetcher(`{
  AND: [
    { pageSlug_not_contains: "/products" }
    { pageSlug_not_contains: "/integrations" }
    { pageSlug_not_contains: "/company" }
    { pageSlug_not_contains: "/events" }
    { pageSlug_not_contains: "/teams" }
    { pageSlug_not_contains: "/glossary" }
    ${ALL_RESOURCE_PAGES.map(({ slug }) => `{ pageSlug_not_contains: "/${slug}" }`).join('\n')}
    { pageSlug_not: "/" }
  ]
}`)

export const getAllTeamSlugs = createGetFullPageSlugsFetcher(`{
  AND: [
    { pageSlug_contains: "/teams" }
  ]
}`)
