import React, { useEffect, useState } from 'react'
import {
  Container, Title, TitleWrapper 
} from './Collapse.styles'
import ExpandArrow from '../../public/images/expanse.svg'

interface CollapseProps {
  title: string
  isOpened?: boolean
  onChanged?: (isOpened: boolean) => void
}

const Collapse: React.FC<CollapseProps & React.HTMLAttributes<HTMLDivElement>> =
  ({
    title, isOpened, children, onChanged, ...rest 
  }) => {
    const [_isOpened, setIsOpened] = useState(Boolean(isOpened))

    const toggleCollapse = () => {
      const newValue = !_isOpened
      setIsOpened(newValue)

      if (onChanged) {
        onChanged(newValue)
      }
    }

    useEffect(() => {
      setIsOpened(Boolean(isOpened))
    }, [isOpened])

    return (
      <Container {...rest}>
        <TitleWrapper onClick={toggleCollapse} $isOpened={_isOpened}>
          <Title>{title}</Title>
          <ExpandArrow />
        </TitleWrapper>
        {_isOpened && <div>{children}</div>}
      </Container>
    )
  }

export default Collapse
