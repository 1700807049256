import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { defaultSectionTheme } from '@/constants'

const useSectionTheme = () => {
  const theme = useContext(ThemeContext)
  return theme.sectionTheme || defaultSectionTheme
}

export default useSectionTheme
