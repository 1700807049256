import getConfig from 'next/config'
import {
  entryPages,
  ContentTypeIds,
  REDIS_HOMEPAGE_KEY,
  ALL_RESOURCE_PAGES,
} from '@/constants'
import { CategoryTypeKeys } from '@/interfaces/category'

export const checkIsPreviewModeRequest = (query: NodeJS.Dict<string | string[]>): boolean => {
  const { serverRuntimeConfig } = getConfig()
  const isPreviewTokenCorrect = query?.previewToken === serverRuntimeConfig.previewModeAccessToken
  const isPreviewMode = query?.preview === 'true' && isPreviewTokenCorrect

  return isPreviewMode
}

export const getPageSlug = (querySlugs: undefined | string | string[], url?: undefined | string, query?: NodeJS.Dict<string | string[]>): string => {
  if (url && /\/insights.*/.test(url)) {
    switch (true) {
      case url === '/insights':
        return '/'
      case query?.lang && querySlugs && Array.isArray(querySlugs):
        return Array.isArray(querySlugs) ? `/${querySlugs?.join('/')}` : ''
      case querySlugs && Array.isArray(querySlugs):
        return url.split('/insights')[1].split('?slug=')[0]
      case query?.lang && Array.isArray(query?.lang):
        return `/${query?.lang}`
      default:
        return url.split('/insights')[1]
    }
  }
  if (Array.isArray(querySlugs))
    return `/${querySlugs?.join('/')}`
  return querySlugs ? `/${querySlugs}` : '/'
}

export const checkIsArticlePageApp = (pathname: string): boolean => {
  if (
    pathname !== '/insights/[lang]/[...slug]'
    && pathname !== '/insights/[...slug]'
  ) return false
  return true
}

export const checkIsCategoryPageApp = (pathname: string): boolean => {
  if (
    pathname !== '/insights'
    && pathname !== '/insights/topic/[...slug]'
    && pathname !== '/insights/tag/[...slug]'
  ) return false
  return true
}

export const checkIsEventPageApp = (pathname: string): boolean => {
  return !!pathname && /^\/(webinars|case-studies|ebooks|reports|events)\//.test(pathname)
}

export const checkIsResourcesPageApp = (pathname: string): string | null => {
  return ALL_RESOURCE_PAGES.find(({ slug }) => slug === pathname)?.type || null
}

export const checkIsIndividualGlossaryPage = (pathname: string): boolean => {
  return !!pathname && pathname === '/glossary/[...slug]'
}

export const getCategoryPageId = (pageSlug: string): CategoryTypeKeys => {
  if (pageSlug.split('/')[1] === CategoryTypeKeys.Topic.toLowerCase()) return CategoryTypeKeys.Topic
  return CategoryTypeKeys.Tag
}

export const getCachePageSlugFromUrl = (type: ContentTypeIds, pageData: Record<string, any>): string => {
  if (!entryPages.includes(type)) return ''
  const SLASH = '/'
  const INSIGHTS = '/insights'

  switch (type) {
    case ContentTypeIds.article:
      return INSIGHTS + pageData.articleSlug
    case ContentTypeIds.category:
      return INSIGHTS + (pageData.pageSlug !== SLASH ? pageData.pageSlug : '')
    default: //full page
      return pageData.pageSlug === SLASH ? REDIS_HOMEPAGE_KEY : pageData.pageSlug
  }
}

export const getFormattedPageSlug = (pageSlug: string) => {
  return pageSlug === '/' ? '' : (pageSlug || '')
}
