import React from 'react'
import Thumbnail from '@/components/Thumbnail'
import { Container } from '@/components'
import { ArticleProps } from '@/interfaces/article'
import {
  YMALList, 
  YMALTitle, 
  YMALWrapper 
} from './YouMightAlsoLike.styles'

interface YouMightAlsoLikeProps {
  relatedArticles?: ArticleProps[]
}

const YouMightAlsoLike: React.FC<YouMightAlsoLikeProps> = ({ relatedArticles }) => {
  if (!relatedArticles?.length) return null

  return <YMALWrapper>
    <Container>
      <YMALTitle>You might also like</YMALTitle>
      <YMALList>
        {relatedArticles?.map(
          (articleThumbnail: ArticleProps, index: number) => 
            <Thumbnail 
              {...articleThumbnail}
              key={index} 
            />
        )}
      </YMALList>
    </Container>
  </YMALWrapper>
}

export default YouMightAlsoLike
