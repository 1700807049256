import styled from 'styled-components'
import { ThumbnailsWrapper } from '../Category/Category.styles'
import { ThumbnailContentContainer, Title } from '../Thumbnail/Thumbnail.styles'

export const ContentFeedWrapper = styled.div`
  ${ThumbnailContentContainer} {
    height: 262px;

    ${Title} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }

  ${ThumbnailsWrapper} {
    padding: 30px 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${ThumbnailContentContainer} {
      height: 304px;
    }

    ${ThumbnailsWrapper} {
      padding: 60px 0;
    }
  }
`
