import { useState, useEffect } from 'react'
import { HeaderSectionType } from '@/interfaces/headerSectionType'
import {
  ItemList,
  Item,
} from './FooterItem.styles'

interface FooterItemInterface {
  itemList: Record<string, unknown>[]
}

interface FooterChildItemInterface {
  sys: { id: string }
  title: string
  sections: Record<string, unknown>[]
  link: string
}

const FooterItem: React.FC<FooterItemInterface> = ({ itemList }) => {
  const [footerItem, setFooterItem] = useState<any>([])

  useEffect(() => {
    itemList
      ?.filter((item: any) => item)
      .forEach((item: any) => {
        if (item.type === HeaderSectionType.Compose) {
          item.menuLinkItems?.forEach((item: any) => {
            setFooterItem((footerItem: any) => [...footerItem, item])
          })
        }
      })
  }, [])

  return (
    <ItemList>
      {footerItem
        ?.filter((item: any) => item)
        ?.map((item: FooterChildItemInterface) => {
          return (
            <Item key={item.sys?.id} href={item.link}>
              {item.title}
            </Item>
          )
        })}
    </ItemList>
  )
}

export default FooterItem
