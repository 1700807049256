// Hook
import { useMediaQuery } from '.'

// Constant
import { breakpoints } from '@/constants/theme/breakpoint'

// Type
import { BreakpointNames } from '@/interfaces/responsive'

const useMediaQueryUp = (breakpointName: BreakpointNames): boolean => {
  const [isMatched] = useMediaQuery([`(min-width: ${breakpoints[breakpointName]})`])

  return isMatched
}

export default useMediaQueryUp
