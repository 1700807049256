import { BsCheck } from 'react-icons/bs'
import { useContext, useMemo } from 'react'

import useSubscribeForm, { ERROR_MESSAGES } from './SubscribeForm.hook'

import { ReCaptchaScript } from '@/components'
import {
  SubscribeForm,
  EmailInput,
  CheckboxWrapper,
  CheckboxLabel,
  Checkbox,
  SubscribeButton,
  ErrorFeedback,
  SuccessMessage,
  SuccessMessageIcon,
} from './SubscribeForm.styles'
import { FooterContext } from '../Footer'

const SubscribeFormComponent: React.FC = () => {
  const footerContext = useContext(FooterContext)
  const {
    agreeToReceiveEmails,
    emailError,
    checkboxError,
    isSubmitted,
    isSubmitting,
    emailInputRef,
    handleToggleAgreement,
    handleSubmit,
  } = useSubscribeForm({ zapierHookId: footerContext?.zapierHookId, eventLabel: footerContext?.eventLabel })
  
  const errorMapping = useMemo(() => ({
    [ERROR_MESSAGES.EMAIL_EMPTY]: footerContext?.emailRequiredErrorMessage || ERROR_MESSAGES.EMAIL_EMPTY,
    [ERROR_MESSAGES.EMAIL_INVALID]: footerContext?.emailFormatErrorMessage || ERROR_MESSAGES.EMAIL_INVALID,
    [ERROR_MESSAGES.NOT_CHECKING_CHECKBOX]: footerContext?.checkboxRequiredErrorMessage || ERROR_MESSAGES.NOT_CHECKING_CHECKBOX,
  }), [])

  return (
    <>
      <ReCaptchaScript />
      <SubscribeForm onSubmit={handleSubmit}>
        {!isSubmitted ? (
          <>
            <EmailInput
              ref={emailInputRef}
              placeholder={footerContext?.emailPlaceholder}
              isInvalid={!!emailError}
            />
            <ErrorFeedback>{errorMapping[emailError]}</ErrorFeedback>
            <CheckboxWrapper onClick={handleToggleAgreement}>
              <Checkbox>
                {agreeToReceiveEmails && <BsCheck />}
              </Checkbox>
              <CheckboxLabel>{footerContext?.checkboxText}</CheckboxLabel>
            </CheckboxWrapper>
            {errorMapping[checkboxError] && <ErrorFeedback>{errorMapping[checkboxError]}</ErrorFeedback>}
            {footerContext?.buttonText && <SubscribeButton 
              type='submit'
              eventLabel={footerContext?.buttonEventLabel}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : footerContext?.buttonText}
            </SubscribeButton>}
          </>
        ) : (
          <SuccessMessage>
            <SuccessMessageIcon>
              <img src='/images/check.svg' alt='check icon' />
            </SuccessMessageIcon>
            You’re in! Check your inbox for our upcoming newsletter.
          </SuccessMessage>
        )}
      </SubscribeForm>
    </>
  )
}

export default SubscribeFormComponent
