import MenuItem from '../MenuItem'
import { MenuItemInterface, MenuItemListInterface } from '@/interfaces/headerItem'
import {
  Title,
  Item,
  Wrapper
} from './MenuItemList.styles'

const MenuItemList: React.FC<MenuItemListInterface> = ({
  title,
  menuItems,
}) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {menuItems
        ?.filter((item: any) => item)
        ?.map((item: MenuItemInterface) => {
          return (
            <Item key={item.sys?.id}>
              <MenuItem
                title={item.title}
                icon={item.icon}
                description={item.description}
                link={item.link}
                openInNewTab={item.openInNewTab}
              />
            </Item>
          )
        })}
    </Wrapper>
  )
}

export default MenuItemList
