import { countries, regions } from '@/constants/countries.constants'
import { ListingType } from '@/interfaces/carriers'
import { useClickOutsideMultiElement } from '@/hooks/useClickOutsideElement'
import { debounce } from '@/utils'
import { useRef, useState } from 'react'
import {
  LocationFilterWrapper,
  LocationSearchInput,
  LocationSelect,
  LocationOption,
  LocationList,
  Arrow,
} from './LocationFilter.styles'

interface LocationFilterProps {
  onFilter: (country: string) => void
  type?: ListingType
  allCountriesText?: string
  searchCountryPlaceholder?: string
}

enum Location {
  ALL_COUNTRIES = 'All countries',
  ALL_REGIONS = 'All regions',
}

const LocationFilter: React.FC<LocationFilterProps> = ({
  onFilter,
  type,
  allCountriesText,
  searchCountryPlaceholder,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(
    type === ListingType.CARRIER
      ? `${Location.ALL_COUNTRIES}`
      : `${Location.ALL_REGIONS}`
  )
  const allLocations =
    type === ListingType.CARRIER
      ? [Location.ALL_COUNTRIES, ...countries.sort()]
      : [Location.ALL_REGIONS, ...regions.sort()]
  const [locationList, setLocationList] = useState(allLocations)
  const locationFilterRef = useRef(null)
  const locationListRef = useRef(null)

  const handleClose = () => {
    setIsOpen(false)
    setLocationList(allLocations)
  }

  // To close the dropdown when clicking on outside of country selecter
  useClickOutsideMultiElement([locationFilterRef, locationListRef], handleClose)

  const handleClick = (location: string) => {
    setSelectedLocation(location)
    setIsOpen(false)
    onFilter(location)
    setLocationList(allLocations)
  }

  const handleSearchLocation = debounce(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const target = e.target as HTMLInputElement
      if (!target.value.trim()) setLocationList(allLocations)
      else {
        const newLocationList = allLocations?.slice(1).filter(
          (location) => location.toLowerCase()?.indexOf(target.value.toLowerCase()) > -1
        )
        setLocationList(newLocationList)
      }
    },
    400
  )

  return (
    <LocationFilterWrapper ref={locationFilterRef}>
      <LocationSelect onClick={() => setIsOpen(!isOpen)}>
        {Object.values(Location).includes(selectedLocation as Location) &&
        allCountriesText
          ? allCountriesText
          : selectedLocation}
        <Arrow />
      </LocationSelect>
      <LocationList isOpen={isOpen} ref={locationListRef}>
        <LocationSearchInput
          placeholder={
            searchCountryPlaceholder ||
            (type === ListingType.CARRIER ? 'Search country' : 'Search region')
          }
          onChange={(e) => handleSearchLocation(e)}
          key={isOpen ? 1 : 2}
        />
        {locationList?.map((location: string) => (
          <LocationOption
            key={location}
            isSelected={location === selectedLocation}
            onClick={() => handleClick(location)}
          >
            {location === allLocations[0] && allCountriesText
              ? allCountriesText
              : location}
          </LocationOption>
        ))}
      </LocationList>
    </LocationFilterWrapper>
  )
}

export default LocationFilter
