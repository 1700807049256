import {
  createContext, useEffect, useRef 
} from 'react'
import { Container } from '@/components'
import FooterItemSection from './FooterItemSection'
import ParcelPerformSection from './ParcelPerformSection'
import {
  Footer,
  FooterSection,
  Divider,
  FooterCopyRightWrapper,
  FooterCopyRight,
  FooterLink,
  Contact,
} from './Footer.styles'
import { FooterInfor } from '@/interfaces/footer'
import { pushEvent } from '@/utils'
import { CustomEventName } from '@/constants'

interface FooterComponentProps {
  footerItems: any[]
  footerInfor: FooterInfor
}

export const FooterContext = createContext<FooterInfor>({
  copyrightTitle: '',
  copyrightItemList: [],
})

const isElementXPercentInViewport = function (
  el: HTMLElement,
  percentVisible: number
) {
  const rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight

  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / -rect.height) * 100) <
      percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  )
}

const FooterComponent: React.FC<FooterComponentProps> = ({
  footerItems,
  footerInfor,
}) => {
  const footerWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const bindScrollEvent = (eventHandlerFn: () => void) =>
      window.addEventListener('scroll', eventHandlerFn)
    const unbindScrollEvent = (eventHandlerFn: () => void) =>
      window.removeEventListener('scroll', eventHandlerFn)

    const handleFooterOutOfViewPort = () => {
      if (!footerWrapperRef.current) return
      if (!isElementXPercentInViewport(footerWrapperRef.current, 1)) {
        bindScrollEvent(handleOnDocumentScroll)
        unbindScrollEvent(handleFooterOutOfViewPort)
      }
    }

    const handleOnDocumentScroll = () => {
      if (!footerWrapperRef.current) return
      if (isElementXPercentInViewport(footerWrapperRef.current, 1)) {
        pushEvent(CustomEventName.ViewFullPage)
        unbindScrollEvent(handleOnDocumentScroll)
        bindScrollEvent(handleFooterOutOfViewPort)
      } else {
        // reset
        unbindScrollEvent(handleOnDocumentScroll)
        bindScrollEvent(handleOnDocumentScroll)
      }
    }

    bindScrollEvent(handleOnDocumentScroll)

    return () => window.removeEventListener('scroll', handleOnDocumentScroll)
  }, [])

  return (
    <FooterContext.Provider value={footerInfor}>
      <Footer ref={footerWrapperRef}>
        <Container>
          <FooterSection>
            <ParcelPerformSection />
            <FooterItemSection itemList={footerItems} />
          </FooterSection>
          <Divider />
          <Contact />
          <FooterCopyRightWrapper>
            <FooterCopyRight>{footerInfor?.copyrightTitle}</FooterCopyRight>
            {footerInfor?.copyrightItemList?.map(
              (info) =>
                info && (
                  <FooterLink
                    key={info?.sys?.id}
                    href={info?.link}
                    target={info?.openInNewTab ? '_blank' : '_self'}
                    rel="noopener"
                  >
                    {info.title}
                  </FooterLink>
                )
            )}
          </FooterCopyRightWrapper>
        </Container>
      </Footer>
    </FooterContext.Provider>
  )
}

export default FooterComponent
