// Hook
import { useMediaQuery } from '.'

// Constant
import { downBreakpoints } from '@/constants/theme/breakpoint'

// Type
import { BreakpointNames } from '@/interfaces/responsive'

const useMediaQueryDown = (breakpointName: BreakpointNames): boolean => {
  const [isMatched] = useMediaQuery([`(max-width: ${downBreakpoints[breakpointName]})`])

  return isMatched
}

export default useMediaQueryDown
